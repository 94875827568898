<template>
    <app-modal class-name="dialog-success"
        @close-modal="hideSuccess"

        ref="app-modal"
    >
        <template v-if="has_header" #header>
            <slot name="header">
                <h3>{{$t('success')}}!</h3>
            </slot>
        </template>

        <div>
            <div class="success-message">
                <slot>
                    <p>{{ message }}</p>
                </slot>
            </div>
            
            <div class="actions">
                <slot name="actions">
                    <button class="btn" @click="hideModal">{{$t('ok').toUpperCase()}}</button>
                </slot>
            </div>
        </div>
    </app-modal>
</template>

<script>
import appModal from '@/components/app-modal'

export default {
    props: {
        message:    { type: String, default: null   },
        autoShow:   { type: Boolean, default: false },
        has_header: { type: Boolean, default: true  },
    },

    components: {
        appModal,
    },

    mounted() {
        if (this.autoShow) {
            this.showModal()
        }
    },

    methods: {
        hideSuccess() {
            this.$store.commit('hideSuccess')
        },

        showModal() {
            this.$refs['app-modal'].showModal()
        },

        hideModal() {
            this.$refs['app-modal'].hideModal()
            this.hideSuccess()
        },
    },
}
</script>

<style lang="scss">
.dialog-success {
    .app-modal__header {
        margin: 0 0 40px;

        h3 {
            margin: 0 0 16px;

            &:last-child { margin: 0; }
        }

        h5 {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .success-message {
        font-size: 24px;
        text-align: center;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 48px 0 0;

        .btn {
            flex-grow: 1;
            width: auto;
            flex-basis: auto;
            max-width: 220px;
            margin: 0 15px;

            &:first-child { margin-left: 0; }
            &:last-child { margin-right: 0; }
        }
    }
}

@media (max-width: $tablet-size) {
    .dialog-success {
        .app-modal__header {
            margin-bottom: 32px;

            h5 {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .success-message {
            font-size: 18px;
            text-align: center;
        }
    }
}

@media (max-width: $mobile-size) {
    .dialog-success {
        .app-modal__header {
            h5 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .success-message {
            font-size: 16px;
            text-align: center;
        }

        .actions {
            flex-direction: column;
            margin-top: 32px;

            .btn {
                width: 100%;
                max-width: 100%;
                margin: 24px 0 0;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>