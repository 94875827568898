import errMessage from '@/helpers/err-message'

export default {
    state: {
        success_modal: {
            show: false,
            message: null,
            has_header: true,
        },
    },
    
    getters: {
        success_modal_show: state => state.success_modal.show,
        success_modal_message: state => state.success_modal.message,
        success_modal_has_header: state => state.success_modal.has_header,
    },

    mutations: {
        showSuccessMessage(state, { success_message, has_header = true }) {
            state.success_modal = {
                has_header,
                show: true,
                message: success_message,
            }
        },

        hideSuccess(state) {
            state.success_modal = {
                show: false,
                message: null,
                has_header: true,
            }
        },
    },

    actions: {
        showSuccess({ commit }, success) {
            commit('showSuccessMessage', { success_message: errMessage(success) })
        },

        showSuccessMessage({ commit }, { success_message, has_header = true }) {
            commit('showSuccessMessage', { success_message, has_header })
        },

        hideSuccess({ commit }) {
            commit('hideSuccess')
        },
    },
}