export default {
    state: {},
    getters: {
        getEnvParam: () => param => process.env[param],
        getAppEnvParam: (state, getters) =>
            param => {
                const variable = param.split('-').join('_')
                return getters.getEnvParam(`VUE_APP_${variable}`)
            },

        app_environment: (state, getters) => getters.getAppEnvParam('ENVIRONMENT'),
        is_app_environment_prod: (state, getters) => getters.app_environment == 'prod',
        is_app_environment_email_verified: (state, getters) => getters.getAppEnvParam('IS_EMAIL_VERIFIED') == 'true',
        is_app_environment_enabled_i_already_have_sim_menu_item: (state, getters) => getters.getAppEnvParam('ENABLE_I_ALREADY_HAVE_SIM_MENU_ITEM') == 'true',
        is_app_environment_enabled_esim_menu_item: (state, getters) => getters.getAppEnvParam('ENABLE_ESIM_MENU_ITEM') == 'true',

        app_environment_group_mode: (state, getters) => getters.getAppEnvParam('GROUP_MODE'),
        is_app_environment_enabled_customer_groups_menu_item: (state, getters) => {
            return getters.app_environment_group_mode == 'ALL' || getters.app_environment_group_mode == 'CUSTOMER'
        },
        is_app_environment_enabled_mobile_groups_menu_item: (state, getters) => {
            return getters.app_environment_group_mode == 'ALL' || getters.app_environment_group_mode == 'MOBILE'
        },

        is_app_environment_allow_international_plans: (state, getters) => getters.getAppEnvParam('ALLOW_INTERNATIONAL_PLANS') == 'true',
        is_app_environment_enabled_voucher_menu_item: (state, getters) => getters.getAppEnvParam('ENABLE_VOUCHER_MENU_ITEM') == 'true',
        is_app_environment_enabled_onboarding_voucher_redeem: (state, getters) => getters.getAppEnvParam('ENABLE_ONBOARDING_VOUCHER_REDEEM') == 'true',
        is_app_environment_enabled_bank_transactions: (state, getters) => getters.getAppEnvParam('ENABLE_BANK_TRANSACTIONS') == 'true',

        is_app_environment_allow_auto_renew_toggle_on_addons: (state, getters) => getters.getAppEnvParam('ALLOW_AUTO_RENEW_TOGGLE_ON_ADDONS') == 'true',
        is_app_environment_allow_auto_renew_toggle_on_plans: (state, getters) => getters.getAppEnvParam('ALLOW_AUTO_RENEW_TOGGLE_ON_PLANS') == 'true',
        is_app_environment_allow_auto_renew_toggle_on_purchase: (state, getters) => getters.getAppEnvParam('ALLOW_AUTO_RENEW_TOGGLE_ON_PURCHASE') == 'true',

        is_app_environment_billing_routing_allowed: (state, getters) => getters.getAppEnvParam('IS_BILLING_ROUTING_ALLOWED') == 'true',
        is_app_environment_billing_cycle_allowed: (state, getters) => getters.getAppEnvParam('IS_BILLING_CYCLE_ALLOWED') == 'true',

        is_app_environment_invoices_allowed: (state, getters) => getters.getAppEnvParam('IS_INVOICES_ALLOWED') == 'true',
        is_app_environment_receipts_allowed: (state, getters) => getters.getAppEnvParam('IS_RECEIPTS_ALLOWED') == 'true',

        app_environment_sim_actions_allowed: (state, getters) => getters.getAppEnvParam('SIM_ACTIONS_ALLOWED')?.split(',') || [],
        is_sim_swap_allowed: (state, getters) => getters.app_environment_sim_actions_allowed.includes('SIM_SWAP'),
        is_sim_disable_enable_allowed: (state, getters) => getters.app_environment_sim_actions_allowed.includes('SIM_DISABLE_ENABLE'),
        is_sim_details_allowed: (state, getters) => getters.app_environment_sim_actions_allowed.includes('SIM_DETAILS'),
        is_product_type_transfer_allowed: (state, getters) => getters.app_environment_sim_actions_allowed.includes('PRODUCT_TYPE_TRANSFER') && getters.is_account_type_activated,
        is_number_porting_on_dashboard_allowed: (state, getters) => getters.app_environment_sim_actions_allowed.includes('NUMBER_PORTING_ON_DASHBOARD') && !getters.customer_current_plan_porting_state,
        is_sim_actions_allowed: (state, getters) => getters.is_sim_swap_allowed || getters.is_sim_disable_enable_allowed || getters.is_sim_details_allowed || getters.is_product_type_transfer_allowed || getters.is_number_porting_allowed,
        
        is_app_environment_show_plans_password_protected: (state, getters) => getters.getAppEnvParam('IS_SHOW_PLANS_PASSWORD_PROTECTED') == 'true',
        is_app_environment_show_switch_plan: (state, getters) => getters.getAppEnvParam('IS_SHOW_SWITCH_PLAN') == 'true',
        app_logo_outer_link: (state, getters) => getters.getAppEnvParam('LOGO_OUTER_LINK'),
        app_about_outer_link: (state, getters) => getters.getAppEnvParam('ABOUT_OUTER_LINK'),
        price_overview_outer_link: (state, getters) => getters.getAppEnvParam('PRICE_OVERVIEW_OUTER_LINK'),
        is_app_go_to_sim_purchase_on_plans: (state, getters) => getters.getAppEnvParam('IS_GO_TO_SIM_PURCHASE_ON_PLANS') == 'true',
        app_spid: (state, getters) => +getters.getAppEnvParam('SPID'),
        app_name: (state, getters) => getters.getAppEnvParam('NAME'),
        theme_name: (state, getters) => getters.getAppEnvParam('THEME'),
        layout_type: (state, getters) => getters.getAppEnvParam('LAYOUT') || 'first',

        available_profile_tabs: (state, getters) => getters.getAppEnvParam('PROFILE_TABS')?.split(',') || [],

        available_ufb_profile_tabs: (state, getters) => getters.getAppEnvParam('UFB_PROFILE_TABS')?.split(',') || [],

        footer_copyright: (state, getters) => getters.getAppEnvParam('FOOTER_COPYRIGHT').replace('YYYY', (new Date()).getFullYear()),

        has_app_environment_dry_activation: (state, getters) => getters.getAppEnvParam('HAS_DRY_ACTIVATION') == 'true',

        is_multiple_sim_allowed: (state, getters) => getters.getAppEnvParam('IS_MULTIPLE_SIM_ALLOWED') == 'true',
    },
    actions: {},
    mutations: {}
}
