const TODAY = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate())

export default {
    state: {},

    getters: {
        current_account_usage: (state, getters) => getters.current_account && getters.current_account.services
            && Object.values(getters.current_account.services).map( service => {
                const history = service.history && service.history.map( ({ date, usage }) => {
                    return {
                        date: new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate()),
                        usage,
                    }
                }) || []
                return {...service, history }
            }) || [],

        /* getUsageByTypeAndRange: (state, getters) => ({type, unit}, range, sort = 'asc') => {
            const usage = getters.current_account_usage.find( service => service.type == type && service.unit == unit)

            const { from, to = TODAY } = range instanceof Object ? range : {}

            if (usage) {
                const history = usage && usage.history && from
                    ? usage.history.filter(({date}) => from <= date && date < to)
                    : [...usage.history]

                history.sort((a, b) => sort == 'asc' ? a.date - b.date : b.date - a.date )

                return {...usage, history}
            }
        } */
    },

    actions: {
        /** @todo delete fake usage data */
        CreateFakeUsageData({ getters, dispatch }, start_date) {
            const yesterdayDay = date => { date.setDate(date.getDate() - 1) }

            const generateHistory = (limit, start_date) => {
                const result = []
                const date = new Date()
                do {
                    yesterdayDay(date)

                    const usage = Math.random() < 0.2 ? 0 : +(Math.random()*limit).toFixed(2)

                    result.push({date: date.toISOString(), usage})
                } while (date > start_date)

                return result
            }

            const default_start_date = new Date()
            default_start_date.setMonth(new Date().getMonth() - 6)

            start_date = start_date || default_start_date
            const services = getters.all_services

            const fake_usage = services.reduce( (acc, service) => {
                const id = service.id
                const dateExpire = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString()
                const limit = service.limit
                const maxLimit = service.limit * 4
                const title = service.title
                const type = service.type
                const unit = service.unit
                const history = limit ? generateHistory(limit, start_date) : undefined

                if (limit) {
                    acc[id] = { dateExpire, history, limit, maxLimit, title, type, unit }
                }

                return acc
            }, {})

            return dispatch('UpdateCurrentAccount', { services: fake_usage})
        }
    },

    mutations: {},
}