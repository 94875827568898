import store from '@/store';

export default (to, from, next) => {
    const is_receipts_allowed = store.getters.is_app_environment_receipts_allowed;

    return {
        allow: is_receipts_allowed,
        redirect: { name: 'not-found' },
    }
};
