import StoreCache from '@/utils/StoreCache.js'

const cached = {
    delivery_addresses: new StoreCache(30 * 60 * 1000)
}

const state = {
    delivery_addresses: [],
}

const getters = {
    delivery_addresses: state => state.delivery_addresses,
}

const mutations = {
    saveDeliveryAddresses(state, addresses) {
        state.delivery_addresses = addresses

        if (addresses?.length) {
            cached.delivery_addresses.fix()
        } else {
            cached.delivery_addresses.reset()
        }
    },

    deleteDeliveryAddresses(state, uuid) {
        const index = state.delivery_addresses.findIndex(address => address.AddressUUID == uuid)

        if (index > -1) {
            state.delivery_addresses.splice(index, 1)
        }
    },

    defaultDeliveryAddresses(state, uuid) {
        for (let i = 0, len = state.delivery_addresses.length; i < len; i++) {
            if (state.delivery_addresses[i].AddressUUID == uuid) {
                state.delivery_addresses.splice(i, 1, {...state.delivery_addresses[i], IsDefault: true})
            } else if (state.delivery_addresses[i].IsDefault) {
                state.delivery_addresses.splice(i, 1, {...state.delivery_addresses[i], IsDefault: false})
            }
        }

        state.delivery_addresses.sort((a, b) => b.IsDefault - a.IsDefault)
    },

    resetDeliveryAddresses(state) {
        state.delivery_addresses = []

        cached.delivery_addresses.reset()
    },
}

const actions = {
    GetCustomerDeliveryAddresses({getters, dispatch, commit}, force) {
        const req = {
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            AddressType: 'DELIVERY',
            'SearchOptions.SortBy': 'Rank',
            'SearchOptions.SortDesc': false,
        }

        if (force || cached.delivery_addresses.expired()) {
            return dispatch('api_location/GetCustomerAddressEntitiesPaginated', req).then(({CustomerAddressEntities}) => {
                const delivery_addresses = CustomerAddressEntities.map(entity => ({
                    LocationProvider: entity.LocationProvider,
                    LocationProviderIDX: entity.LocationProviderIDX,
                    BuildingName: entity.BuildingName,
                    AddressType: entity.AddressType,
                    UnitType: entity.UnitType,
                    UnitTypeExtra: entity.UnitTypeExtra,
                    Number: entity.Number,
                    NumberPre: entity.NumberPre,
                    NumberPost: entity.NumberPost,
                    StreetName: entity.StreetName,
                    StreetType: entity.StreetType,
                    State: entity.State,
                    Suburb: entity.Suburb,
                    Country: entity.Country,
                    Latitude: location.Latitude,
                    Longitude: location.Longitude,
                    City: entity.City,
                    Country: entity.Country,
                    PostCode: entity.PostCode,
                    Lines: [
                        [
                            `${entity.NumberPre}${entity.NumberPre ? '/' : ''}${entity.Number}${entity.NumberPost}`,
                            entity.StreetName,
                            entity.StreetType,
                        ].join(' ').trim(),
                        [
                            entity.BuildingName,
                            entity.Suburb,
                        ].join(' ').trim(),
                    ].filter(line => Boolean(line)),
                
                    AddressUUID: entity.UUID,
                    Description: entity.Formatted,
                    IsDefault: entity.Rank == 1,
                }))

                commit('saveDeliveryAddresses', delivery_addresses)

                return Promise.resolve(getters.delivery_addresses)
            }).catch(() => {
                commit('saveDeliveryAddresses', [])

                return Promise.resolve(getters.delivery_addresses)
            })
        } else {
            return Promise.resolve(getters.delivery_addresses)
        }
    },

    AddCustomerDeliveryAddress({getters, dispatch}, location) {
        if (!location) return Promise.reject('No Location')

        const req = {
            BuildingName: location.BuildingName,
            LocationProvider: location.LocationProvider,
            LocationProviderIDX: location.LocationProviderIDX,
            AddressType: 'DELIVERY',
            UnitType: location.UnitType,
            UnitTypeExtra: location.UnitTypeExtra,
            Latitude: location.Latitude,
            Longitude: location.Longitude,
            City: location.City,
            Country: location.Country,
            Formatted: location.Formatted,
            FormattedOriginal: location.FormattedOriginal,
            Number: location.Number,
            NumberPost: location.NumberPost,
            NumberPre: location.NumberPre,
            PostCode: location.PostCode,
            State: location.State,
            StreetName: location.StreetName,
            StreetType: location.StreetType,
            Suburb: location.Suburb,
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
        }

        return dispatch('api_location/AddCustomerAddressEntity', req).then(() => dispatch('GetCustomerDeliveryAddresses', true))
    },

    DeleteCustomerDeliveryAddress({getters, dispatch, commit}, AddressUUID) {
        const req = {
            UUID: AddressUUID,
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
        }

        return dispatch('api_location/DeleteCustomerAddressEntity', req).then(() => {
            commit('deleteDeliveryAddresses', AddressUUID)
        })
    },

    DefaultCustomerDeliveryAddress({getters, dispatch, commit}, AddressUUID) {
        const req = {
            UUID: AddressUUID,
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            Rank: 1,
        }

        const address = getters.delivery_addresses.find(address => address.AddressUUID == AddressUUID)

        return address
            ? dispatch('api_location/UpdateCustomerAddressEntity', req).then(() => {
                commit('defaultDeliveryAddresses', AddressUUID)
            })
            : Promise.resolve(true)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}