const APP_ID = process.env.VUE_APP_FACEBOOK_APP_ID
const VERSION = process.env.VUE_APP_FACEBOOK_VERSION

const state = {
    FB: null,
    facebook_initialized: null,

    me: null,
    facebook_session: null,
}

const getters = {
}

const mutations = {
    saveFB(state, FB) {
        state.FB = FB
        state.facebook_initialized = null
    },
    facebookInitialized(state, promise) {
        state.facebook_initialized = promise
    },

    saveMe(state, me) {
        state.me = me
    },
    resetMe(state) {
        state.me = null
    },

    saveFacebookSession(state, session) {
        state.facebook_session = session
    },
    resetFacebookSession(state) {
        state.facebook_session = null
    },
}

const actions = {
    facebookInit({state, commit, dispatch}, options) {
        if (state.facebook_initialized) {
            return state.facebook_initialized
        } else {
            if (state.FB) {
                return Promise.resolve(state.FB)
            } else {
                let facebook_initialized = new Promise(resolve => {
                    window.fbAsyncInit = function () {
                        FB.init({
                            appId   : APP_ID,
                            xfbml   : false,
                            version : VERSION,
                            cookie  : true,
                        })
                        FB.AppEvents.logPageView()

                        commit('saveFB', FB)
                        resolve(state.FB)

                        dispatch('facebookGetLoginStatus')
                    };
                    
                    (function(d, s, id){
                        let fjs = d.getElementsByTagName(s)[0]
                        if (d.getElementById(id)) { return }
                    
                        let js = d.createElement(s)
                            js.id = id
                            js.src = "//connect.facebook.net/en_US/sdk.js"
                    
                        fjs.parentNode.insertBefore(js, fjs)
                    }(document, 'script', 'facebook-jssdk'));
                })

                commit('facebookInitialized', facebook_initialized)

                return facebook_initialized
            }
        }
    },

    facebookSignIn({state, commit, dispatch}, options) {
        return new Promise((resolve, reject) => {
            dispatch('facebookInit').then(FB => {
                if (!state.facebook_session) {
                    FB.login(
                        response => {
                            if (response.authResponse) {
                                commit('saveFacebookSession', response)
                                console.log('response', response)
                                resolve(response)
                            } else {
                                reject(response)
                            }
                        },
                        { scope: 'email', ...options }
                    )
                } else {
                    resolve(state.facebook_session)
                }
            })
        })
    },

    facebookSignOut({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('facebookInit').then(FB => {
                dispatch('facebookGetLoginStatus')
                    .then(response => {
                        FB.logout(response => {
                            commit('resetFacebookSession')
                            resolve(response)
                        })
                    })
                    .catch(error => resolve(error))
            })
        })
    },

    facebookGetLoginStatus({state, commit, dispatch}) {
        if (state.facebook_session) {
            return Promise.resolve(state.facebook_session)
        } else {
            return new Promise((resolve, reject) => {
                dispatch('facebookInit').then(FB => {
                    FB.getLoginStatus(response => {
                        if (response.status === 'connected') {
                            // The user is logged in and has authenticated your
                            // app, and response.authResponse supplies
                            // the user's ID, a valid access token, a signed
                            // request, and the time the access token 
                            // and signed request each expire.

                            commit('saveFacebookSession', response)
                            resolve(response)
                        } else {
                            // The user isn't logged in to Facebook. You can launch a
                            // login dialog with a user gesture, but the user may have
                            // to log in to Facebook before authorizing your application.

                            reject(response)
                        }
                    })
                })
            })
        }
    },

    facebookMe({commit, dispatch}, params) {
        return new Promise((resolve, reject) => {
            dispatch('facebookInit').then(FB => {
                window.FB.api('/me', 'get', { fields: 'id,first_name,last_name,email', ...params }, response => {
                    if (!response || response.error) {
                        commit('resetMe')
                        commit('resetFacebookSession')
                        reject(response)
                    } else {
                        commit('saveMe', response)
                        resolve(response)
                    }
                })
            })
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}