import axiosIntents from '@/utils/axios-intents'

const state = {
    is_auto_roaming_charge_active: { value: false },
}

const getters = {
    is_auto_roaming_charge_active: state => state.is_auto_roaming_charge_active,
}

const actions = {
    GetIsAutoRoamingChargeActive({ getters, commit }) {
        const customerUUID = getters.current_account_uid
        const mobileUUID = getters.customer_current_product_uuid

        return axiosIntents.get(`/sim/daily-roaming/auto-active/${customerUUID}/${mobileUUID}`).then(resp=> {
            if (resp?.data?.AutoActive) {
                commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', true)
            } else {
                commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', false)
            }

            return Promise.resolve(getters.is_auto_roaming_charge_active)
        }).catch(error => {
            console.log('GetIsAutoRoamingChargeActive Error', error)

            commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', false)
        })
    },

    SetIsAutoRoamingChargeActive({ getters, commit }, is_auto_active = false) {
        const CustomerUUID = getters.current_account_uid
        const MobileUUID = getters.customer_current_product_uuid

        return axiosIntents.post('/sim/daily-roaming/auto-active', {
            CustomerUUID,
            MobileUUID,
            AutoActive: is_auto_active,
        }).then(()=> {
            commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', is_auto_active)

            return Promise.resolve(getters.is_auto_roaming_charge_active)
        }).catch(error => {
            console.log('SetIsAutoRoamingChargeActive Error', error)

            commit('SET_IS_AUTO_ROAMING_CHARGE_ACTIVE', !is_auto_active)

            return Promise.reject(error)
        })
    },
}

const mutations = {
    SET_IS_AUTO_ROAMING_CHARGE_ACTIVE(state, is_auto_roaming_charge_active) {
        state.is_auto_roaming_charge_active = { value: is_auto_roaming_charge_active }
    },
}

export default {
    state,
    getters,
    mutations,
	actions,
};