import store from '@/store'

const available_ufb_profile_tabs = store.getters.available_ufb_profile_tabs
const available_ufb_profile_route_names = available_ufb_profile_tabs.map( tab => `profile-${tab}`)

const is_allowed = to => to.matched.some( record => available_ufb_profile_tabs.includes(record.meta.profile) )

export default (to, from, next) => ({
    allow: available_ufb_profile_tabs.length === 0 || is_allowed(to),
    redirect: { name: available_ufb_profile_route_names[0] || 'profile-broadband' }
})
