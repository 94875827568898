import errMessage from '@/helpers/err-message'

export default {
    state: {
        error_modal: {
            show: false,
            message: null,
        },
    },
    
    getters: {
        error_modal_show: state => state.error_modal.show,
        error_modal_message: state => state.error_modal.message,
    },

    mutations: {
        showErrorMessage(state, message) {
            state.error_modal = {
                show: true,
                message,
            }
        },

        hideError(state) {
            state.error_modal = {
                show: false,
                message: null,
            }
        },
    },

    actions: {
        showError({ commit }, error) {
            commit('showErrorMessage', errMessage(error))
        },

        showErrorMessage({ commit }, error_message) {
            commit('showErrorMessage', error_message)
        },

        hideError({ commit }) {
            commit('hideError')
        },
    },
}