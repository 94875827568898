import store from '@/store'

import axiosIntents from '@/utils/axios-intents'

import dynStorage from '@/utils/storage-dynamic'

import {
    AVAILABLE_SIM_TYPE_PRODUCTS,
    AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID,

    eshopItem
} from '@/helpers/eshop'

const ESHOP_V2_CART = 'eshop-v2-cart'
const ESHOP_V2_PURCHASED = 'eshop-v2-purchased'

const eshopCartItemSim_v2 = (eshop_item, sim, plan) => {
    const item = eshop_item ? {
        UUID: eshop_item.UUID,
        PriceCents: eshop_item.PriceCents,
            Name: eshop_item.Name,
            Type: eshop_item.Type,
            VirtualType: eshop_item.VirtualType,
        ItemUUID: eshop_item.ItemUUID,
        SelfManaged: eshop_item.SelfManaged,
        Products: [
            eshopCartItemProduct_v2(eshop_item.Products[0], plan),
        ],
    } : sim ? {
        UUID: null,
        PriceCents: 0,
        ...(sim.UUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID ? {
            Name: 'ESIM',
            Type: 'TYPE_VIRTUAL',
            VirtualType: 'VT_ESIM',
        } : {
            Name: 'SIM Card',
            Type: 'TYPE_PRODUCT',
        }),
        ItemUUID: sim.UUID,
        SelfManaged: false,
        Products: [
            eshopCartItemProduct_v2(null, plan),
        ],
    } : null

    if (item.Products[0] == null) {
        item.Products.length = 0
    }

    // item.SPID = store.getters.app_spid
    // item.Quantity = 1
    item.Description = item.Name

    return item
}

const eshopCartItemProduct_v2 = (eshop_item, plan) => {
    const item = eshop_item ? {
        UUID: eshop_item.UUID,
        Name: eshop_item.Name,
        Description: eshop_item.Description,
        PriceCents: eshop_item.PriceCents,
        PriceCatalogCents: eshop_item.PriceCatalogCents,
        Type: eshop_item.Type,
        VirtualType: eshop_item.VirtualType,
        Attributes: {
            BillingReference: eshop_item.Attributes.BillingReference,
            MobileProductAvailabilityUUID: eshop_item.Attributes.MobileProductAvailabilityUUID,
            MobileProductCatalogUUID: eshop_item.Attributes.MobileProductCatalogUUID,
            AutoRenew: eshop_item.Attributes.AutoRenew,
            DiscountUUID: eshop_item.Attributes.DiscountUUID,
        },
    } : plan ? {
        UUID: null,
        Name: plan.merged_name
            || `${ plan.Availabilities[0].RetailName } (${
                plan.is_payg
                    ? 'Payg'
                    : plan.ProductType == 'postpaymobile'
                        ? 'Postpay'
                        : process.env.VUE_APP_ESHOP_CART_ITEM_PREPAY
            })`,
        Description: plan.Availabilities[0].RetailDescription,
        PriceCents: plan.Availabilities[0].RetailCents,
        PriceCatalogCents: plan.price_catalog_cents,
        Type: 'TYPE_VIRTUAL',
        VirtualType: 'VT_PLAN',
        Attributes: {
            BillingReference: plan.is_payg
                ? 'payg'
                : plan.ProductType == 'postpaymobile'
                    ? 'postpay'
                    : 'prepay',
            MobileProductAvailabilityUUID: plan.Availabilities[0].UUID,
            MobileProductCatalogUUID: plan.Availabilities[0].ProductCatalogEntryUUID,
            AutoRenew: Boolean(plan.is_autoRenew),
            DiscountUUID: plan.DiscountUUID,
        },
    } : null

    // item.SPID = store.getters.app_spid
    // item.Quantity = 1
    // item.Description = 

    return item
}

const logAnalyticsItems = (items) => Array.isArray(items)
    ? items.reduce((acc, item) => {
        const accPush = item => {
            const item_id = item.ItemUUID || ''

            const item_acc = item_id
                ? acc.find(item => item.item_id == item_id)
                : null

            if (item_acc) {
                item_acc.quantity+= item.Quantity || 0
            } else {
                acc.push({
                    item_id,
                    item_name: item.Name || '',
                    quantity: item.Quantity || 0,
                    price: (item.PriceCents || 0) / 100,
                })
            }
        }

        accPush(item)

        if (item.Products?.length) {
            accPush(item.Products[0])
        }

        return acc
    }, [])
    : null


export default {
    state: {
        eshop_v2_cart: {},
        eshop_v2_cart_loading: false,

        eshop_v2_purchased: null,
    },
    
    getters: {
        eshop_v2_cart: state => state.eshop_v2_cart,
        eshop_v2_cart_uuid: state => state.eshop_v2_cart.UUID,
        eshop_v2_cart_number: state => state.eshop_v2_cart.Number,

        eshop_v2_cart_sims: state => state.eshop_v2_cart.Items?.filter(item => item.ItemUUID in AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID) || [],

        eshop_v2_cart_loading: state => state.eshop_v2_cart_loading,

        eshop_v2_cart_items: state => state.eshop_v2_cart.Items?.length
            ? state.eshop_v2_cart.Items.reduce((acc, eshop_item) => {
                if (eshop_item.Products?.[0]) {
                    acc.push(eshopItem(eshop_item, { UUID: eshop_item.UUID, name_counted: `SIM Card ${ (acc.length / 2) + 1 }`, sim_type: eshop_item.VirtualType == 'VT_ESIM' ? 'ESIM' : 'Physical SIM' }))
                    acc.push(eshopItem(eshop_item.Products[0], { UUID: eshop_item.Products[0].UUID }))
                } else {
                    acc.push(eshopItem(eshop_item, { UUID: eshop_item.UUID, name_counted: 'SIM Card', sim_type: eshop_item.VirtualType == 'VT_ESIM' ? 'ESIM' : 'Physical SIM' }))
                }
                return acc
            }, [])
            : [],
        eshop_v2_cart_items_count: (state, getters) => getters.eshop_v2_cart_items.length,

        eshop_v2_cart_total: (state, getters) => {
            let price = 0
            let discount = 0

            const eshop_v2_cart_items = getters.eshop_v2_cart_items

            for (let i = 0, len = eshop_v2_cart_items.length; i < len; i++) {
                if (eshop_v2_cart_items[i]) {
                    price+= eshop_v2_cart_items[i].discount ? eshop_v2_cart_items[i].discount : eshop_v2_cart_items[i].price
                    discount+= eshop_v2_cart_items[i].discount ? eshop_v2_cart_items[i].price : 0
                }
            }

            return price && discount ? {
                price: discount,
                discount: price,
            } : {
                price,
                discount: null,
            }
        },
        eshop_v2_cart_items_quantity: (state, getters) => getters.eshop_v2_cart_items.reduce((quantity, item) => quantity+= item?.quantity || 0, 0),

        eshop_v2_purchased: state => state.eshop_v2_purchased,

        eshop_v2_has_e_sim: state => state.eshop_v2_cart.Items?.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID) > -1,
        eshop_v2_has_real_sim: state => state.eshop_v2_cart.Items?.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID) > -1,
    },

    mutations: {
        eshopCartSet_v2(state, eshop_cart) {
            state.eshop_v2_cart = eshop_cart

            if (eshop_cart.CustomerUUID) {
                dynStorage.remove(ESHOP_V2_CART)
            } else {
                dynStorage.set(ESHOP_V2_CART, eshop_cart.UUID, false, true)
            }
        },



        eshopCartLoading_v2(state, loading) {
            state.eshop_v2_cart_loading = loading
        },

        eshopCartReset_v2(state, force) {
            if (!state.eshop_v2_cart.UUID || force) {
                state.eshop_v2_cart = {}
            }
        },



        eshopPurchasedSave_v2(state, { payload, location, is_only_sim }) {
            const e_sim = payload.Items.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID) > -1
            const real_sim = payload.Items.findIndex(item => item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID) > -1

            let eshop_v2_purchased = {
                e_sim,
                number: payload.Number,
            }

            /**
             * @todo сделать проверку на наличие "физических" товаров в корзине,
             *       когда они появятся
             */
            if (real_sim) {
                eshop_v2_purchased.address = location.Description
                eshop_v2_purchased.is_only_sim = is_only_sim
            }

            state.eshop_v2_purchased = eshop_v2_purchased

            dynStorage.set(ESHOP_V2_PURCHASED, eshop_v2_purchased, false, true)
        },

        eshopPurchasedLoad_v2(state) {
            state.eshop_v2_purchased = dynStorage.get(ESHOP_V2_PURCHASED, true)
        },

        eshopPurchasedReset_v2(state) {
            state.eshop_v2_purchased = null

            dynStorage.remove(ESHOP_V2_PURCHASED)
        },
    },

    actions: {
        eshopCartLoad_v2({getters, commit, dispatch}) {
            commit('eshopCartLoading_v2', true)

            const cart_uuid = dynStorage.get(ESHOP_V2_CART, true)

            return dispatch('GetCatalogServices').then(() =>
                (cart_uuid
                    ? dispatch('eshopCartRestoreByUUID_v2', cart_uuid)
                    : getters.current_account_uid
                        ? dispatch('eshopCartRestoreByCustomer_v2')
                        : Promise.resolve(getters.eshop_v2_cart)
                ).finally(() => {
                    commit('eshopCartLoading_v2', false)
                })
            )
        },

        eshopCartSaveSIMs_v2({getters, commit, dispatch}, sims) {
            if (!sims) {
                const getters_eshop_v2_cart_sims_modified = [...getters.eshop_v2_cart_sims]

                getters_eshop_v2_cart_sims_modified.forEach(cart_sim => {
                    const cart_sim_item_product_catalog_uuid = cart_sim.Products?.[0]?.Attributes?.MobileProductCatalogUUID
                    const cart_sim_item_product_availability_uuid = cart_sim.Products?.[0]?.Attributes?.MobileProductAvailabilityUUID

                    const cart_sim_plan_product = cart_sim_item_product_catalog_uuid && cart_sim_item_product_availability_uuid
                        ? getters.all_services.find(item =>
                            item.UUID == cart_sim_item_product_catalog_uuid
                            && item.Availabilities?.[0]?.UUID == cart_sim_item_product_availability_uuid
                        )
                        : null

                    const cart_sim_plan_product_retailCents = cart_sim_plan_product?.Availabilities?.[0]?.RetailCents

                    if (cart_sim_plan_product_retailCents != undefined) {
                        cart_sim.Products[0].PriceCents = cart_sim_plan_product_retailCents
                    }
                })

                sims = getters_eshop_v2_cart_sims_modified
            }

            const payload = {
                SPID: getters.app_spid,
                UUID: getters.eshop_v2_cart_uuid,
                Number: getters.eshop_v2_cart_number,
                CustomerUUID: getters.current_account_uid,

                Items: sims.map(eshop_item => eshopCartItemSim_v2(eshop_item)),
            }

            return axiosIntents.post('/eshop/v2/cart', payload).then(({result}) => {
                commit('eshopCartSet_v2', result)

                if (result?.Items) {
                    const value = ((result.AmountCents || 0) + (result.AmountTaxCents || 0)) / 100

                    dispatch('logAnalyticsEventAddToCart', { parameters: {
                        currency: "NZD",
                        value,
                        items: logAnalyticsItems(result?.Items),
                    }})
                }

                return Promise.resolve(getters.eshop_v2_cart)
            })
        },

        eshopCartAddSIM_v2({getters, dispatch}, { type, plan, empty }) {
            if (type in AVAILABLE_SIM_TYPE_PRODUCTS) {
                const sims = getters.eshop_v2_cart_sims

                sims.push(eshopCartItemSim_v2(null, AVAILABLE_SIM_TYPE_PRODUCTS[type], plan?.product))

                if (empty) {
                    sims.push(eshopCartItemSim_v2(null, AVAILABLE_SIM_TYPE_PRODUCTS[type], null))
                }

                return dispatch('eshopCartSaveSIMs_v2', sims)
            } else {
                return Promise.reject('Unknown SIM type')
            }
        },

        eshopCartSetSIMPlan_v2({getters, dispatch}, { type, plan, sim_uuid }) {
            if (type in AVAILABLE_SIM_TYPE_PRODUCTS) {
                if (plan?.product?.Availabilities
                    && Array.isArray(plan.product.Availabilities)
                    && plan.product.Availabilities.length
                ) {
                    const sims = getters.eshop_v2_cart_sims

                    if (sim_uuid && sims.length) {
                        let is_changed = false

                        const updateSIMPlan = (sim) => {
                            if (sim
                                && (sim.Products[0]?.ProductCatalogUUID != plan.product.UUID
                                    || sim.Products[0]?.Attributes?.AutoRenew != plan.product.is_autoRenew
                                )
                            ) {
                                is_changed = true

                                sim.Products = [
                                    eshopCartItemProduct_v2(null, plan.product)
                                ]
                            }
                        }

                        if (sim_uuid == 'all') {
                            for (let i = 0, len = sims.length; i < len; i++) {
                                updateSIMPlan(sims[i])
                            }
                        } else {
                            const index = sims.findIndex(sim => sim.UUID == sim_uuid)

                            if (index > -1) {
                                updateSIMPlan(sims[index])
                            }
                        }

                        if (is_changed) {
                            return dispatch('eshopCartSaveSIMs_v2', sims)
                        } else {
                            return Promise.resolve(sims)
                        }
                    } else {
                        return dispatch('eshopCartAddSIM_v2', { type, plan })
                    }
                } else {
                    return Promise.reject(`Plan not specified`)
                }
            } else {
                return Promise.reject('Unknown SIM type')
            }
        },

        eshopCartRemoveSIM_v2({getters, dispatch}, uuid) {
            const sims = [...getters.eshop_v2_cart_sims]
            const index = sims.findIndex(sim => sim.UUID == uuid)

            if (index > -1) {
                sims.splice(index, 1)

                return dispatch('eshopCartSaveSIMs_v2', sims)
            } else {
                return Promise.resolve(uuid)
            }
        },

        eshopCartRemoveAllSIMs_v2({dispatch}) {
            return dispatch('eshopCartSaveSIMs_v2', [])
        },

        eshopCartRestoreByUUID_v2({getters, commit}, uuid) {
            return axiosIntents.get(`/eshop/v2/cart/${ uuid }`).then(({result}) => {
                commit('eshopCartSet_v2', result)

                return Promise.resolve(getters.eshop_v2_cart)
            })
        },

        eshopCartRestoreByCustomer_v2({getters, commit}) {
            const uuid = getters.current_account_uid

            return axiosIntents.get('/eshop/v2/customer/:uuid/cart').then(({result}) => {
                if (uuid == getters.current_account_uid) {
                    commit('eshopCartSet_v2', result)
                }

                return Promise.resolve(getters.eshop_v2_cart)
            })
        },

        eshopPurchase_v2({getters, commit, dispatch}, { location, is_only_sim, payment }) {
            const sims = getters.eshop_v2_cart_sims

            const auth_user_profile_creds = getters.auth_user_profile_creds

            const payload = {
                SPID: getters.app_spid,
                UUID: getters.eshop_v2_cart_uuid,
                Number: getters.eshop_v2_cart_number,
                CustomerUUID: getters.current_account_uid,

                Address: {
                    City: location.City,
                    Country: location.Country,
                    Postcode: location.PostCode,
                    Lines: location.Lines,
                    LocationProvider: location.LocationProvider,
                    LocationProviderIDX: location.LocationProviderIDX,
                    Email: auth_user_profile_creds.email,
                    Name: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
                    Phone: auth_user_profile_creds.phone,
                },

                Items: sims.map(eshop_item => eshopCartItemSim_v2(eshop_item)),
            }

            return axiosIntents.post('/eshop/v2/checkout', payload).then(({result}) => {
                commit('eshopPurchasedSave_v2', { payload, location, is_only_sim })

                if (payload.Items.length) {
                    const items = logAnalyticsItems(payload.Items)
                    const value = items.reduce((value, item) => value+= (item.price || 0) * (item.quantity || 0), 0)
    
                    dispatch('logAnalyticsEventPurchase', { parameters: {
                        currency: "NZD",
                        value,
                        items,
                    }})
                }

                return Promise.resolve(result)
            })
        },



        eshopGetCustomerOrders_v2() {
            return axiosIntents.get('/eshop/v2/customer/:uuid/orders')
                .then(({result}) => Promise.resolve(result))
        },

        eshopGetCustomerOrder_v2({}, OrderNumber) {
            return axiosIntents.get(`/eshop/v2/customer/:uuid/order/${OrderNumber}`)
                .then(({result}) => Promise.resolve(result))
        },

        eshopGetCustomerOrderItem_v2({ dispatch }, { OrderNumber, UUID }) {
            return dispatch('eshopGetCustomerOrder_v2', OrderNumber).then(({OrderItems, Status}) => {
                if (!OrderItems) return Promise.reject('No Order Items')

                const spreded_order_items = OrderItems.reduce((acc, orderItem) => {
                    const order_item_products = orderItem.Products || []

                    return [...acc, orderItem, ...order_item_products]
                }, [])

                const order_item = spreded_order_items.find(item => item.UUID == UUID)

                if (!order_item) return Promise.reject('No Order Item')

                return Promise.resolve({...order_item, Status})
            })
        },
    },
}