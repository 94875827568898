export default {
    env_home_topper: {
        name: 'Tello',
        title: 'Get connected easily',
        headline: '',
        lead: 'We are here to make it easier for you to get online and get the best value for your money!',
    },

    env_home_profits: {
        name: '',
        title: '',
        headline: 'What you will get',
        lead: '',
    },

    env_home_botter: {
        name: '',
        title: '',
        headline: 'Let’s start right now',
        lead: 'Come join the team that delivers the best value and the fastest speeds on the best network',
    },

    env_welcome: {
        name: '',
        title: 'Welcome to Tello!',
        headline: 'Sign in or create an account using the options below.',
        sub_headline: 'Its great to have you as a part of the team',
        lead: 'With just a few simple clicks, we’ll have you setup and ready to go in no time',
    },

    env_help_center: {
        name: 'FAQs',
        title: '',
        headline: '',
        lead: 'Welcome to our help center, please select what application you want help using or type in your question below.',
        faq_section_name: 'FAQs',
    },

    env_about: {
        name: 'Tello About Us',
        title: '',
        headline: '',
        lead: '',
        content: '<p>Tello is a Kiwi Owned and Operated Brand that was developed by a small group of individuals with a large range of telco knowledge and expertise.</p> <p>Tello is a brand designed and created to be simple and self serving. Founders of the business have 40 + years of experience together in the telco industry and have experienced all its highs and lows. Working with many of the largest providers in the world, the team was able to learn what customers enjoyed and what created a great experience for them.</p> <p>Fast forward 20 odd years and the Founders decided to put together a telco business that was going to remove the barriers and expenses that resulted in larger charges for service and poor customer experience.</p> <p>Tello was born. Tello is a local brand that represents ease, affordability and independence. Tello gives customers more tools to manage their mobile services, a variety of product options and flexible top up methods.</p> <p>Tello is built on the latest technology in the industry giving it lightning fast connection speeds, responsive designs and clean user interface to ensure customers get the best possible experience.</p> <p>Try Tello Now!</p>'
    },

    env_international_plans: {
        name: '',
        title: '',
        headline: 'Get great calling and SMS rates to selected destinations',
        lead: '',
        description: 'International call charges are not included in plans or addons, please review the out of bundle charges here:',
    },

    env_privacy: {
        name: 'TELLO LIMITED',
        title: 'PRIVACY POLICY',
        headline: '',
        lead: '',
    },

    env_terms: {
        name: 'TELLO TERMS AND CONDITIONS OF SERVICE',
        title: '',
        headline: '',
        lead: '',
    },

    env_monthly_top_up_terms: {
        name: 'Monthly Top Up',
        title: '',
        headline: '',
        lead: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
    },

    env_prepay_terms: {
        name: 'Prepay',
        title: '',
        headline: '',
        lead: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
    },

    env_packs_and_combos_terms: {
        name: 'Packs and Combos',
        title: '',
        headline: '',
        lead: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
    },

    env_select_account: {
        name: '',
        title: '',
        headline: 'Multiple customer accounts',
        lead: 'Select which account you want to sign in with.',
    },

    env_package: {
        name: 'Package',
        title: '',
        headline: '',
        lead: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    },

    // common data if no data for specific plans
    env_plans: {
        name: 'Tello plans',
        title: 'Find a plan to suit you',
        headline: '',
        lead: 'Check out the plans below and see what amazing deals we have on offer',
    },

    // data for Postpay plans
    env_plans_postpay: {
        name: '',
        title: '',
        headline: '',
        lead: '',
    },

    // data for Prepay plans
    env_plans_prepay: {
        name: '',
        title: '',
        headline: '',
        lead: '',
    },

    // data for PAYG plans
    env_plans_payg: {
        name: '',
        title: '',
        headline: '',
        lead: '',
    },

    env_sign_in: {
        name: 'Sign in',
        title: '',
        welcome_title: 'Welcome to Tello!',
        headline: '',
        lead: 'Welcome back',
    },

    env_sign_up: {
        name: '',
        title: '',
        headline: 'Join Tello right now',
        lead: '',
    },

    env_sign_up_with_identity_link: {
        name: '',
        title: '',
        headline: 'Identity Link',
        lead: 'Create a login for your account using any of the options below. You can use a login you already have or create a new one to associate your login credentials with this account.',
    },

    env_forgot_email: {
        name: '',
        title: '',
        headline: 'Forgot e-mail?',
        lead: 'Please enter your current phone number, we’ll send you a SMS with your login details.',
    },

    env_forgot_pass: {
        name: '',
        title: '',
        headline: 'Forgotten your password?',
        lead: 'Please enter the email address used for sign up and we’ll send you a link to change your password.',
    },

    env_one_time_code: {
        name: '',
        title: '',
        headline: 'Enter One-Time Password',
        lead: 'Enter the code you received via e-mail.',
    },

    env_new_pass: {
        name: '',
        title: '',
        headline: 'Create new password',
        lead: '',
    },

    env_profit: {
        '1': {
            title: 'Great Service',
            text: 'Our local customer care team are here to help you have the best mobile experience possible!',
        },
        '2': {
            title: 'Fast and Reliable Coverage',
            text: 'Tello uses the OneNZ 4G and 5G networks to keep you reliably connected at blazing fast speeds!',
        },
        '3': {
            title: 'Best Value!',
            text: 'Tello offers the best possible value for your money. Take a look at our plans to see for yourself!',
        },
    },

    env_theme: {
        prepay_help_me_choose_message: 'Purchase addons and packs with Prepay! Simply top up your account with credit/debit card or voucher and use your credit as you wish.',

        // Adopt by Mobile
        sim_id_text: 'SIM ID',
        adopt_subtitle: 'To complete activation, we need your new SIMID - This is printed on the back of your SIM card. Alternatively, you can also use the NEW phone number of your new SIM to proceed',

        // Checkout Note
        checkout_note: 'Payment details entered during sign up will also be used for recurring billing charges. You will not be charged until your SIM has arrived and you activate your plan.',
        checkout_note_esim: 'Payment details entered during sign up will also be used for recurring billing charges. You will not be charged until you activate your plan.',
        checkout_note_only_sim: 'You won’t be charged until your SIM arrives and you activate your plan.',

        // PAYMENT METHODS
        payment_methods_title: 'Payment Methods',

        // Home page - Plans
        home_plans_headline: '',

        // FOOTER LINKS: title => link | title => link 
        footer_links: 'Terms and Conditions => terms | Privacy Policy => privacy',
    },

    my_app_home: 'My Tello',

    receive_newsletter:                 `I want to receive newsletter from ${process.env.VUE_APP_NAME ?? ''}`,
    receive_offers:                     `I want to receive special offers notifications from ${process.env.VUE_APP_NAME ?? ''}`,
    we_successfully_placed_your_order:  `We have successfully placed your order{order}. To check your order status, login to the ${process.env.VUE_APP_NAME?.split(' ')[0] || ''} app and go to “order history” to manage your orders`,

    Welcome_to_family:                  `Welcome to the ${process.env.VUE_APP_NAME ?? ''} family!`,
    head_to_your_profile:               'Head to your profile to track your SIM delivery and keep an eye out in your inbox for your order confirmation.',
    you_can_go_to_the_sim_swap:         'After receiving your new SIM card, you can go back to the SIM swap flow and complete it.',

    sim: {
        thank_you_step: {
            title: 'Good choice!',
            only_sim_title: 'Thank you for the purchase',
            subtitle: '',
        },
    },

    customer_account_ID:                "External Account ID",
    external_ID_promt: {
        title: 'Do you have External Account ID?',
        subtitle: 'Please Enter your External Account ID below',
        input_placeholder: 'External Account ID',
        note: 'You can also enter your External Account ID in <b>Personal data</b> section at any time.'
    },

    payment_details_lead:               `Thank you for using ${process.env.VUE_APP_NAME?.split(' ')[0] || 'our service'}, we hope you have enjoyed your services. We will deduct the invoice amount from your nominated default credit card on the invoice due date. You can also go into the application and pay this manually yourself.`,

    env_purchase_or_switch_confirmation: 'Purchase confirmation',
    confirm_change_plan_now:            'We will change your plan to <strong>"{name}"</strong> immediately. No pro ration and you will be charged accordingly <strong>now</strong>.',
    confirm_change_plan_later:          'We will change your plan to the <strong>"{name}"</strong> when your current plan is due to renew. Charges for new plan start when it is activated.',

    sim_transfer_product_types: {
        postpay: {
            title: 'Postpay / On Account',
            features: [
                'Each time you make a purchase, you are invoiced for your purchase',
                'You are charged up to 15 days after your purchase',
                'It is up to you to maintain your spending within your budget, and to ensure there are funds on your chosen payment method when payment is due, otherwise your account may enter into arrears and may be subject to suspension',
            ],
        },
        prepay: {
            title: 'Prepay',
            features: [
                'Each time you make a purchase, you are invoiced and charged immediately',
                'This allows you to budget your spending as you go, and don’t need to worry about accumulating amounts owing on invoices',
                'To switch to PAYG/Subscription billing, you must pay any outstanding amounts owing',
            ],
        },
        payg: {
            title: 'Pay as you go / Subscription',
            features: [
                'Each time you make a purchase, you are invoiced and charged immediately',
                'This allows you to budget your spending as you go, and don’t need to worry about accumulating amounts owing on invoices',
                'To switch to PAYG/Subscription billing, you must pay any outstanding amounts owing',
            ],
        },
    },
}
