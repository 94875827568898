import { analytics } from '@/utils/firebase.js';

const EVENTS = {
    ADD_TO_CART: 'add_to_cart',
    BEGIN_CHECKOUT: 'begin_checkout',
    HELP: 'help',
    LOGIN: 'login',
    PAGE_VIEW: 'page_view',
    PURCHASE: 'purchase',
    SIGN_UP: 'sign_up',
    VIEW_ITEM: 'view_item',
}

export default {
    state: {
        events: EVENTS,
    },

    getters: {
        analytics_events: (state) => state.events,
    },

    actions: {
        logAnalyticsEvent({}, { event, parameters = {} }) {
            console.log('logAnalyticsEvent', event, parameters)

            analytics.logEvent(event, parameters);
        },
        
        logAnalyticsEventPageView({ dispatch }, { path, name }) {
            let locationHref = window.location.href

            if (locationHref && locationHref.indexOf('token=') !== -1) {
                locationHref = locationHref.replace(/token=[^\&]*/g, 'token=xxx')
            }

            const parameters = {
                app_name: "web",
                page_location: locationHref,
                page_path: path,
                page_title: name,
            }

            dispatch('logAnalyticsEvent', { event: EVENTS.PAGE_VIEW, parameters })
        },

        logAnalyticsEventSignUp({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.SIGN_UP, parameters })
        },

        logAnalyticsEventLogin({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.LOGIN, parameters })
        },

        logAnalyticsEventHelp({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.HELP, parameters })
        },

        logAnalyticsEventPurchase({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.PURCHASE, parameters })
        },

        logAnalyticsEventViewItem({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.VIEW_ITEM, parameters })
        },

        logAnalyticsEventAddToCart({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.ADD_TO_CART, parameters })
        },

        logAnalyticsEventBeginCheckout({ dispatch }, { parameters }) {
            dispatch('logAnalyticsEvent', { event: EVENTS.BEGIN_CHECKOUT, parameters })
        },
    },

    mutations: {},
};
