/**
 * Перехватчик запросов для axios endpoint zendesk:
 *
 * @param {*} Vue
 * @param {*} axios
 * @param {*} store
 * @param {*} router
 */
const axios_config = {
    timeout: 10000,
    maxRedirects: 1,
    withCredentials: false,
}

function axiosHelpCenterMiddleware(Vue, axios, store, router) {
    const zd_customer_api_host = process.env.VUE_APP_ZENDESK_CUSTOMER_API_HOST

    /** @todo direct zendesk api call is only for test cases, remove later */
    axios.defaults.baseURL = zd_customer_api_host || process.env.VUE_APP_ZENDESK_API_HOST

    axios.interceptors.request.use(
        config => {
            /** @todo authentification by direct zendesk api call is only for test cases, remove later */
            if (process.env.VUE_APP_ZENDESK_API_HOST) {
                // config.auth = {
                //     username: `${process.env.VUE_APP_ZENDESK_API_LOGIN}/token`,
                //     password: process.env.VUE_APP_ZENDESK_API_TOKEN
                // }
                config.withCredentials = false
            }

            return {...axios_config, ...config}
        },
        error => Promise.reject(error)
    )

    axios.interceptors.response.use(
        response => {
            response.result = response.data
            response.apidata = response.data

            return Promise.resolve(response)
        },

        error => {
            return Promise.reject(error)
        }
    )
}

export default axiosHelpCenterMiddleware
