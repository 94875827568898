import store from '@/store';

export default (to, from, next) => {
    const is_sim_actions_allowed = store.getters.is_sim_actions_allowed;

    return {
        allow: is_sim_actions_allowed,
        redirect: { name: 'not-found' },
    }
};
