import store from '@/store';

export default (to, from, next) => {
    const is_international_plans_allowed = store.getters.is_app_environment_allow_international_plans;

    return {
        allow: is_international_plans_allowed,
        redirect: { name: 'not-found' },
    }
};
