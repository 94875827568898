import theme_locales from '@theme/locales/mi_NZ'

export default {
    ...theme_locales,
    
    language: {
        en:                             "EN",
        'mi-NZ':                        "MI",
    },

    // common
    sign_in:                            "Takiuru",
    sign_up:                            "Waitohu",
    log_out:                            "Takiputa atu",
    help:                               "Awhina",

    personal_data:                      "Raraunga Whaiaro",
    account:                            "Pūkete",
    shipping_details:                   "Nga Taipitopito Tukunga",
    companions:                         "Nga hoa",
    order_history:                      "Tuhinga History",
    payment_methods:                    "Nga Tikanga Utu",
    payment_history:                    "Hītori Utu",
    invoices:                           "Nga nama",
    redeem_voucher:                     "Hokona Voucher",
    international_plans:                "Nga Mahere o te Ao",

    mobile:                             "Pukoro",
    bonuses:                            "Takoha",
    billing:                            "Pire",
    ufb:                                "UFB",
    power:                              "Mana",
    about_Us:                           "Mo Tatou",
    payment:                            "Utu",
    delivery:                           "Tukunga",
    our_Services:                       "To Tatou Ratonga",
    information:                        "Nga korero",
    confidentiality:                    "Te muna",

    dashboard:                          "Papatohu",
    my_plan_and_addons:                 "Ko aku mahere me aku taapiri",
    current_plans:                      "Nga Mahere o naianei",
    current_plans_and_addons:           "Mahere o naianei me nga taapiri",
    current_addons:                     "Nga taapiri o naianei",
    purchase_plans_and_addons:          "Mahere hoko me nga taapiri",
    purchase_plans:                     "Mahere Hokonga",
    usage:                              "Whakamahinga",
    sim_settings:                       "Tautuhinga SIM",
    cellular_features:                  "Ngā āhuatanga pūtau",
    sim_card_actions:                   "Nga mahi kaari SIM",
    security:                           "Haumarutanga",

    pay_a_bill:                         "Utua he pire",
    pay_bill:                           "Utua he pire",

    sim: {
        ...theme_locales.sim,
    },
}
