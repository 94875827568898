import StoreCache from '@/utils/StoreCache.js'

import plan_types_list from '@theme/data/plan-types-list.js'
import products_by_types_test from '@theme/data/products-by-types.js'

import { KILO_BASE_2 } from '@/consts'

const PLANS = {
    users_plan: 'f22aaab6-a5d6-4c7b-82b0-75d650a12002',
    next_updating: '30 Jul 2020',
    all_plans: [
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12000',
            title: 'Plan title1',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunti labore et dolore magna aliqua',
            more_details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Ut porttitor leo a diam sollicitudin tempor. Odio tempor orci dapibus ultrices in iaculis nunc sed. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Elementum eu facilisis sed odio morbi quis commodo odio. Faucibus scelerisque eleifend donec pretium. Dolor purus non enim praesent elementum. Cursus in hac habitasse platea dictumst quisque sagittis purus. Tellus cras adipiscing enim eu turpis egestas pretium. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Senectus et netus et malesuada fames ac turpis egestas. At lectus urna duis convallis convallis tellus id interdum velit. Amet aliquam id diam maecenas ultricies mi. Fermentum posuere urna nec tincidunt. Venenatis cras sed felis eget. Habitasse platea dictumst vestibulum rhoncus. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae.',
            info: {
                'Cost for a month': '$ 75.00',
                'NZ Carryover Data': '25 GB',
                'Internet speed': '100 MBPS',
                'Calls to NZ & Aussie': 'Unlimited',
                'Texts to NZ & Aussie': 'Unlimited',
                'Free Data Hour everyday': 'Yes',
                'Hotspot your data': 'Yes',
                'Share your data': 'Yes',
            },
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12001',
            title: 'Plan title2',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunti labore et dolore magna aliqua',
            more_details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Ut porttitor leo a diam sollicitudin tempor. Odio tempor orci dapibus ultrices in iaculis nunc sed. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Elementum eu facilisis sed odio morbi quis commodo odio. Faucibus scelerisque eleifend donec pretium. Dolor purus non enim praesent elementum. Cursus in hac habitasse platea dictumst quisque sagittis purus. Tellus cras adipiscing enim eu turpis egestas pretium. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Senectus et netus et malesuada fames ac turpis egestas. At lectus urna duis convallis convallis tellus id interdum velit. Amet aliquam id diam maecenas ultricies mi. Fermentum posuere urna nec tincidunt. Venenatis cras sed felis eget. Habitasse platea dictumst vestibulum rhoncus. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae.',
            info: {
                'Cost for a month': '$ 76.00',
                'NZ Carryover Data': '26 GB',
                'Internet speed': '101 MBPS',
                'Calls to NZ & Aussie': 'Unlimited',
                'Texts to NZ & Aussie': 'Unlimited',
                'Free Data Hour everyday': 'Yes',
                'Hotspot your data': 'Yes',
                'Share your data': 'Yes',
            },
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12002',
            title: 'Plan title3',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunti labore et dolore magna aliqua',
            more_details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Ut porttitor leo a diam sollicitudin tempor. Odio tempor orci dapibus ultrices in iaculis nunc sed. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Elementum eu facilisis sed odio morbi quis commodo odio. Faucibus scelerisque eleifend donec pretium. Dolor purus non enim praesent elementum. Cursus in hac habitasse platea dictumst quisque sagittis purus. Tellus cras adipiscing enim eu turpis egestas pretium. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Senectus et netus et malesuada fames ac turpis egestas. At lectus urna duis convallis convallis tellus id interdum velit. Amet aliquam id diam maecenas ultricies mi. Fermentum posuere urna nec tincidunt. Venenatis cras sed felis eget. Habitasse platea dictumst vestibulum rhoncus. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae.',
            info: {
                'Cost for a month': '$ 77.00',
                'NZ Carryover Data': '27 GB',
                'Internet speed': '107 MBPS',
                'Calls to NZ & Aussie': 'Unlimited',
                'Texts to NZ & Aussie': 'Unlimited',
                'Free Data Hour everyday': 'Yes',
                'Hotspot your data': 'Yes',
                'Share your data': 'Yes',
            },
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12003',
            title: 'Plan title4',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunti labore et dolore magna aliqua',
            more_details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Ut porttitor leo a diam sollicitudin tempor. Odio tempor orci dapibus ultrices in iaculis nunc sed. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Elementum eu facilisis sed odio morbi quis commodo odio. Faucibus scelerisque eleifend donec pretium. Dolor purus non enim praesent elementum. Cursus in hac habitasse platea dictumst quisque sagittis purus. Tellus cras adipiscing enim eu turpis egestas pretium. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Senectus et netus et malesuada fames ac turpis egestas. At lectus urna duis convallis convallis tellus id interdum velit. Amet aliquam id diam maecenas ultricies mi. Fermentum posuere urna nec tincidunt. Venenatis cras sed felis eget. Habitasse platea dictumst vestibulum rhoncus. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae.',
            info: {
                'Cost for a month': '$ 78.00',
                'NZ Carryover Data': '28 GB',
                'Internet speed': '108 MBPS',
                'Calls to NZ & Aussie': 'Unlimited',
                'Texts to NZ & Aussie': 'Unlimited',
                'Free Data Hour everyday': 'Yes',
                'Hotspot your data': 'Yes',
                'Share your data': 'Yes',
            },
        },
    ]
}

const DEFAULT_LIFETIME_USAGE = 1 * 60 * 60 * 1000 // 1 hour

export default {
    state: {
        plan_types_list,
        products_by_types: {},
        plan_processing: {}, // which plans are in processing now

        users_plan: new StoreCache(10 * 60 * 1000), // 10 minutes
        users_plan_next_updating: new StoreCache(10 * 60 * 1000), // 10 minutes
        users_plan_usage: {},
        plans: new StoreCache(1 * 60 * 1000), // 1 minute
    },

    getters: {
        available_plans: (state, getters) =>
            (getters.getAppEnvParam('AVAILABLE_PLANS') || []).split(','),

        productsByPlanType: state => plan_type => state.products_by_types[plan_type],
    },

    actions: {
        fillProductsAllTypes({getters, dispatch}) {

            getters.available_plans.forEach(plan_type => {
                dispatch('fillProductsByType', plan_type)
            })

        },

        fillProductsByType({dispatch, commit}, plan_type) {

            const plan_data = { name: plan_type }

            commit('PLAN_PROCESSING_START', plan_type)

            dispatch('getProductsByType', plan_type).then(response => {
                plan_data.products = response.map( product => ({
                        uuid: product.UUID,
                        title: product.Name,
                        desc: product.Description,
                        price: product.Cents / 100,
                        currency: '$',
                        per: 'month',
                    })
                )

                commit('FILL_PRODUCTS_BY_TYPE', {[plan_type]: plan_data})

                commit('PLAN_PROCESSING_FINISH', plan_type)

            }).catch(error => {
                if (error) {
                    // if API for plan_type exist
                    plan_data.error = error
                    commit('FILL_PRODUCTS_BY_TYPE', {[plan_type]: plan_data})
                } else {
                    // if API for plan_type doesn't exist, we take test data
                    commit('FILL_PRODUCTS_BY_TYPE', {[plan_type]: products_by_types_test[plan_type]})
                }

                commit('PLAN_PROCESSING_FINISH', plan_type)
            })

        },

        GetPlans({state, commit}) {
            const plans = state.plans
            if (!plans.expired()) {
                return Promise.resolve(plans.cachedData())
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const plans = JSON.parse(JSON.stringify(PLANS.all_plans))

                    commit('SAVE_PLANS', plans)
                    resolve(plans)
                }, 500)
            })
        },

        GetUsersPlan({state, dispatch, commit}) {
            const users_plan = state.users_plan
            if (!users_plan.expired()) {
                return Promise.resolve(users_plan.cachedData())
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const users_plan = PLANS.users_plan

                    resolve(users_plan)
                }, 500)
            }).then( users_plan_uuid => {
                return dispatch('GetPlans').then(plans => {
                    const users_plan = plans.find( plan => plan.uuid === users_plan_uuid)

                    commit('SAVE_USERS_PLAN', users_plan)

                    return users_plan
                })
            } )
        },

        GetUsersPlanNextUpdating({state, commit}) {
            const next_updating = state.users_plan_next_updating
            if (!next_updating.expired()) {
                return Promise.resolve(next_updating.cachedData())
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const next_updating = PLANS.next_updating

                    commit('SAVE_USERS_PLAN_NEXT_UPDATING', next_updating)
                    resolve(next_updating)
                }, 500)
            })
        },

        GetUsersPlanUsage({ state, commit }, { from_date, to_date } ) {
            const usage = []
            let current_date = new Date(to_date)

            while ( state.users_plan_usage[+current_date] && !state.users_plan_usage[+current_date].expired() && current_date >= from_date) {
                usage.push( state.users_plan_usage[+current_date].cachedData() )
                current_date.setDate(current_date.getDate() - 1)
            }

            if (current_date < from_date) {
                return Promise.resolve(usage)
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    while (current_date >= from_date) {
                        const random_usage_for_test = Math.round( Math.random() * 50000 ) / KILO_BASE_2
                        const new_usage = {
                            date: new Date(current_date),
                            usage_gb: random_usage_for_test,
                            details: {
                                'Audio': random_usage_for_test / 4,
                                'Video': random_usage_for_test / 2,
                                'Rest': random_usage_for_test / 4,
                            },
                        }

                        commit('SAVE_USERS_USAGE', new_usage)

                        usage.push( new_usage )

                        current_date.setDate(current_date.getDate() - 1)
                    }

                    resolve(usage)
                }, 500)
            })
        }

    },

    mutations: {
        PLAN_PROCESSING_START(state, plan_type) {
            state.plan_processing = {...state.plan_processing, [plan_type]: true}
        },

        PLAN_PROCESSING_FINISH(state, plan_type) {
            state.plan_processing = {...state.plan_processing, [plan_type]: false}
        },

        FILL_PRODUCTS_BY_TYPE(state, plan_data) {
            state.products_by_types = {...state.products_by_types, ...plan_data }
        },

        SAVE_PLANS(state, plans) {
            state.plans.fix(plans)
        },

        DELETE_PLANS(state) {
            state.plans.expireNow()
        },

        SAVE_USERS_PLAN(state, users_plan) {
            state.users_plan.fix(users_plan)
        },

        DELETE_USERS_PLAN(state) {
            state.users_plan.expireNow()
        },

        SAVE_USERS_PLAN_NEXT_UPDATING(state, next_updating) {
            state.users_plan_next_updating.fix(next_updating)
        },

        DELETE_USERS_PLAN_NEXT_UPDATING(state) {
            state.users_plan_next_updating.expireNow()
        },

        SAVE_USERS_USAGE(state, new_usage) {
            if (!state.users_plan_usage[new_usage.date]) {
                state.users_plan_usage[+new_usage.date] = new StoreCache(DEFAULT_LIFETIME_USAGE)
            }

            state.users_plan_usage[+new_usage.date].fix(new_usage)
        },

        DELETE_USERS_USAGE_BY_DATE(state, date) {
            if (state.users_plan_next_updating[date]) {
                state.users_plan_next_updating[date].expireNow()
            }
        },
    }
}