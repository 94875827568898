import { firestoreAction } from 'vuexfire'

import { db } from '@/utils/firebase.js'

const COLLECTION_PLANS = 'plans'

const MORE_DETAILS = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius duis at consectetur lorem donec. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Ut porttitor leo a diam sollicitudin tempor. Odio tempor orci dapibus ultrices in iaculis nunc sed. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Elementum eu facilisis sed odio morbi quis commodo odio. Faucibus scelerisque eleifend donec pretium. Dolor purus non enim praesent elementum. Cursus in hac habitasse platea dictumst quisque sagittis purus. Tellus cras adipiscing enim eu turpis egestas pretium. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Senectus et netus et malesuada fames ac turpis egestas. At lectus urna duis convallis convallis tellus id interdum velit. Amet aliquam id diam maecenas ultricies mi. Fermentum posuere urna nec tincidunt. Venenatis cras sed felis eget. Habitasse platea dictumst vestibulum rhoncus. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae.'

const planDetails = (plan, plan_calculated_services) => {
    return {
        // 'Cost for a month': `$ ${plan.cost.toFixed(2)}`,
        ...plan_calculated_services,
        'Free Data Hour everyday': 'Yes',
        'Hotspot your data': 'Yes',
        'Share your data': 'Yes',
    }
}

export default {
    state: {
        plans_loaded: false,
        plans: [],
    },

    getters: {
        is_plans_loaded: state => state.plans_loaded,

        all_plans: (state, getters) => state.plans.map( plan => {
            const services = plan.services
                ? getters.all_services.filter( service => plan.services.includes(service.id))
                : []
            return {...plan, id: plan.id, services, details: planDetails(plan, getters.calculate_services(services)), more_details: MORE_DETAILS}
        }),

        getPlanById: (state, getters) => id => getters.all_plans.find( plan => plan.id == id ),
    },

    actions: {
        BindPlans: firestoreAction(({ bindFirestoreRef, commit }) => {
            return bindFirestoreRef('plans', db.collection(COLLECTION_PLANS))
                .then( () => {commit('PLANS_LOADED')} )
        }),

        UnbindPlans: firestoreAction(({ unbindFirestoreRef }) => {
            return unbindFirestoreRef('plans')
        }),

        /* BindFirebaseData({dispatch}) {
            dispatch('BindPlans')
        }, */
    },

    mutations: {
        PLANS_LOADED(state) {
            state.plans_loaded = true
        },
    },
}