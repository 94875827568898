import photo_mobile_pink_1_xl from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-1-xl.webp'
import photo_mobile_pink_2_xl from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-2-xl.webp'
import photo_mobile_pink_3_xl from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-3-xl.webp'
import photo_mobile_pink_4_xl from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-4-xl.webp'
import photo_mobile_pink_5_xl from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-5-xl.webp'
import photo_mobile_pink_1_s from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-1-s.webp'
import photo_mobile_pink_2_s from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-2-s.webp'
import photo_mobile_pink_3_s from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-3-s.webp'
import photo_mobile_pink_4_s from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-4-s.webp'
import photo_mobile_pink_5_s from '@/views/eshop/photos/product/preview/pink/mobile-phone-pink-5-s.webp'

import photo_mobile_blue_1_xl from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-1-xl.webp'
import photo_mobile_blue_2_xl from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-2-xl.webp'
import photo_mobile_blue_3_xl from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-3-xl.webp'
import photo_mobile_blue_4_xl from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-4-xl.webp'
import photo_mobile_blue_5_xl from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-5-xl.webp'
import photo_mobile_blue_1_s from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-1-s.webp'
import photo_mobile_blue_2_s from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-2-s.webp'
import photo_mobile_blue_3_s from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-3-s.webp'
import photo_mobile_blue_4_s from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-4-s.webp'
import photo_mobile_blue_5_s from '@/views/eshop/photos/product/preview/blue/mobile-phone-blue-5-s.webp'

import photo_mobile_green_1_xl from '@/views/eshop/photos/product/preview/green/mobile-phone-green-1-xl.webp'
import photo_mobile_green_2_xl from '@/views/eshop/photos/product/preview/green/mobile-phone-green-2-xl.webp'
import photo_mobile_green_3_xl from '@/views/eshop/photos/product/preview/green/mobile-phone-green-3-xl.webp'
import photo_mobile_green_4_xl from '@/views/eshop/photos/product/preview/green/mobile-phone-green-4-xl.webp'
import photo_mobile_green_5_xl from '@/views/eshop/photos/product/preview/green/mobile-phone-green-5-xl.webp'
import photo_mobile_green_1_s from '@/views/eshop/photos/product/preview/green/mobile-phone-green-1-s.webp'
import photo_mobile_green_2_s from '@/views/eshop/photos/product/preview/green/mobile-phone-green-2-s.webp'
import photo_mobile_green_3_s from '@/views/eshop/photos/product/preview/green/mobile-phone-green-3-s.webp'
import photo_mobile_green_4_s from '@/views/eshop/photos/product/preview/green/mobile-phone-green-4-s.webp'
import photo_mobile_green_5_s from '@/views/eshop/photos/product/preview/green/mobile-phone-green-5-s.webp'

import photo_mobile_midnight_1_xl from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-1-xl.webp'
import photo_mobile_midnight_2_xl from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-2-xl.webp'
import photo_mobile_midnight_3_xl from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-3-xl.webp'
import photo_mobile_midnight_4_xl from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-4-xl.webp'
import photo_mobile_midnight_5_xl from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-5-xl.webp'
import photo_mobile_midnight_1_s from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-1-s.webp'
import photo_mobile_midnight_2_s from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-2-s.webp'
import photo_mobile_midnight_3_s from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-3-s.webp'
import photo_mobile_midnight_4_s from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-4-s.webp'
import photo_mobile_midnight_5_s from '@/views/eshop/photos/product/preview/midnight/mobile-phone-midnight-5-s.webp'

import photo_mobile_red_1_xl from '@/views/eshop/photos/product/preview/red/mobile-phone-red-1-xl.webp'
import photo_mobile_red_2_xl from '@/views/eshop/photos/product/preview/red/mobile-phone-red-2-xl.webp'
import photo_mobile_red_3_xl from '@/views/eshop/photos/product/preview/red/mobile-phone-red-3-xl.webp'
import photo_mobile_red_4_xl from '@/views/eshop/photos/product/preview/red/mobile-phone-red-4-xl.webp'
import photo_mobile_red_5_xl from '@/views/eshop/photos/product/preview/red/mobile-phone-red-5-xl.webp'
import photo_mobile_red_1_s from '@/views/eshop/photos/product/preview/red/mobile-phone-red-1-s.webp'
import photo_mobile_red_2_s from '@/views/eshop/photos/product/preview/red/mobile-phone-red-2-s.webp'
import photo_mobile_red_3_s from '@/views/eshop/photos/product/preview/red/mobile-phone-red-3-s.webp'
import photo_mobile_red_4_s from '@/views/eshop/photos/product/preview/red/mobile-phone-red-4-s.webp'
import photo_mobile_red_5_s from '@/views/eshop/photos/product/preview/red/mobile-phone-red-5-s.webp'

import photo_mobile_starlight_1_xl from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-1-xl.webp'
import photo_mobile_starlight_2_xl from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-2-xl.webp'
import photo_mobile_starlight_3_xl from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-3-xl.webp'
import photo_mobile_starlight_4_xl from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-4-xl.webp'
import photo_mobile_starlight_5_xl from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-5-xl.webp'
import photo_mobile_starlight_1_s from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-1-s.webp'
import photo_mobile_starlight_2_s from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-2-s.webp'
import photo_mobile_starlight_3_s from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-3-s.webp'
import photo_mobile_starlight_4_s from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-4-s.webp'
import photo_mobile_starlight_5_s from '@/views/eshop/photos/product/preview/starlight/mobile-phone-starlight-5-s.webp'

const AVAILABLE_PREVIEW_PHOTOS = {
    pink: [
        {
            s: photo_mobile_pink_1_s,
            xl: photo_mobile_pink_1_xl,
        }, {
            s: photo_mobile_pink_2_s,
            xl: photo_mobile_pink_2_xl,
        }, {
            s: photo_mobile_pink_3_s,
            xl: photo_mobile_pink_3_xl,
        }, {
            s: photo_mobile_pink_4_s,
            xl: photo_mobile_pink_4_xl,
        }, {
            s: photo_mobile_pink_5_s,
            xl: photo_mobile_pink_5_xl,
        },
    ],
    blue: [
        {
            s: photo_mobile_blue_1_s,
            xl: photo_mobile_blue_1_xl,
        }, {
            s: photo_mobile_blue_2_s,
            xl: photo_mobile_blue_2_xl,
        }, {
            s: photo_mobile_blue_3_s,
            xl: photo_mobile_blue_3_xl,
        }, {
            s: photo_mobile_blue_4_s,
            xl: photo_mobile_blue_4_xl,
        }, {
            s: photo_mobile_blue_5_s,
            xl: photo_mobile_blue_5_xl,
        },
    ],
    green: [
        {
            s: photo_mobile_green_1_s,
            xl: photo_mobile_green_1_xl,
        }, {
            s: photo_mobile_green_2_s,
            xl: photo_mobile_green_2_xl,
        }, {
            s: photo_mobile_green_3_s,
            xl: photo_mobile_green_3_xl,
        }, {
            s: photo_mobile_green_4_s,
            xl: photo_mobile_green_4_xl,
        }, {
            s: photo_mobile_green_5_s,
            xl: photo_mobile_green_5_xl,
        },
    ],
    midnight: [
        {
            s: photo_mobile_midnight_1_s,
            xl: photo_mobile_midnight_1_xl,
        }, {
            s: photo_mobile_midnight_2_s,
            xl: photo_mobile_midnight_2_xl,
        }, {
            s: photo_mobile_midnight_3_s,
            xl: photo_mobile_midnight_3_xl,
        }, {
            s: photo_mobile_midnight_4_s,
            xl: photo_mobile_midnight_4_xl,
        }, {
            s: photo_mobile_midnight_5_s,
            xl: photo_mobile_midnight_5_xl,
        },
    ],
    red: [
        {
            s: photo_mobile_red_1_s,
            xl: photo_mobile_red_1_xl,
        }, {
            s: photo_mobile_red_2_s,
            xl: photo_mobile_red_2_xl,
        }, {
            s: photo_mobile_red_3_s,
            xl: photo_mobile_red_3_xl,
        }, {
            s: photo_mobile_red_4_s,
            xl: photo_mobile_red_4_xl,
        }, {
            s: photo_mobile_red_5_s,
            xl: photo_mobile_red_5_xl,
        },
    ],
    starlight: [
        {
            s: photo_mobile_starlight_1_s,
            xl: photo_mobile_starlight_1_xl,
        }, {
            s: photo_mobile_starlight_2_s,
            xl: photo_mobile_starlight_2_xl,
        }, {
            s: photo_mobile_starlight_3_s,
            xl: photo_mobile_starlight_3_xl,
        }, {
            s: photo_mobile_starlight_4_s,
            xl: photo_mobile_starlight_4_xl,
        }, {
            s: photo_mobile_starlight_5_s,
            xl: photo_mobile_starlight_5_xl,
        },
    ],
}

export default AVAILABLE_PREVIEW_PHOTOS