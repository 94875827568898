<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="translate(80,50)" style="animation-play-state: running; animation-delay: 0s;">
      <g transform="rotate(0)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="1"
          transform="scale(1.37801 1.37801)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.875s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.875s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g
      transform="translate(71.21320343559643,71.21320343559643)"
      style="animation-play-state: running; animation-delay: 0s;"
    >
      <g transform="rotate(45)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.875"
          transform="scale(1.44051 1.44051)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.75s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.75s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(50,80)" style="animation-play-state: running; animation-delay: 0s;">
      <g transform="rotate(90)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.75"
          transform="scale(1.00301 1.00301)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.625s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.625s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g
      transform="translate(28.786796564403577,71.21320343559643)"
      style="animation-play-state: running; animation-delay: 0s;"
    >
      <g transform="rotate(135)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.625"
          transform="scale(1.06551 1.06551)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.5s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.5s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g
      transform="translate(20,50.00000000000001)"
      style="animation-play-state: running; animation-delay: 0s;"
    >
      <g transform="rotate(180)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.5"
          transform="scale(1.12801 1.12801)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.375s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.375s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g
      transform="translate(28.78679656440357,28.786796564403577)"
      style="animation-play-state: running; animation-delay: 0s;"
    >
      <g transform="rotate(225)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.375"
          transform="scale(1.19051 1.19051)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.25s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.25s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g
      transform="translate(49.99999999999999,20)"
      style="animation-play-state: running; animation-delay: 0s;"
    >
      <g transform="rotate(270)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.25"
          transform="scale(1.25301 1.25301)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.125s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.125s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
    <g
      transform="translate(71.21320343559643,28.78679656440357)"
      style="animation-play-state: running; animation-delay: 0s;"
    >
      <g transform="rotate(315)" style="animation-play-state: running; animation-delay: 0s;">
        <circle
          cx="0"
          cy="0"
          r="6"
          fill="#000"
          fill-opacity="0.125"
          transform="scale(1.31551 1.31551)"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            values="1;0"
            begin="0s"
            style="animation-play-state: running; animation-delay: 0s;"
          />
        </circle>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>