import dynStorage from '@/utils/storage-dynamic';
import axios from 'axios';
import firebase from 'firebase/app';

import i18n from '@/i18n'

const state = {
  auth_data: null,
  is_processing_auth_check: false,
};

const getters = {
  jwt: (state) => (state.auth_data && state.auth_data.JWT) || '',
  jwt_expiry: (state) => (state.auth_data && state.auth_data.JWTExpiry && state.auth_data.JWTExpiry) || 0,

  jwt_is_valid: (state, getters) => (now = Date.now()) => getters.jwt_expiry > Math.round((now + (5 * 60_000)) / 1_000), // five minutes reserve
  jwt_is_expired: (state, getters) => !getters.jwt_is_valid(),
  is_processing_auth_check: (state) => state.is_processing_auth_check,

  // Current identity information
  id_uuid: (state) => (state.auth_data && state.auth_data.UUID) || '',
  id_spid: (state) => (state.auth_data && state.auth_data.SPID) || '',
  id_first_name: (state) => (state.auth_data && state.auth_data.FirstName) || '',
  id_last_name: (state) => (state.auth_data && state.auth_data.LastName) || '',
  id_email: (state) => (state.auth_data && (state.auth_data.Email || state.auth_data.AuthID)) || '',
  id_phone: (state) => (state.auth_data && state.auth_data.MobileNumber) || '',
  id_provider: (state) => (state.auth_data && state.auth_data.IdentityProvider) || '',
  id_auth_id: (state) => (state.auth_data && state.auth_data.AuthID) || '',

  id_display_name: (state, getters) => [getters.id_first_name, getters.id_last_name].filter((x) => x).join(' ') || getters.id_email || '',
};

const mutations = {
  mut_is_processing_auth_check (state, is_processing_auth_check) {
    state.is_processing_auth_check = is_processing_auth_check;
  },

  // mut_auth_data stores the raw Authentication response
  mut_auth_data(state, newAuthData) {
    if (newAuthData && newAuthData.JWT && newAuthData.JWTExpiry && newAuthData.JWTExpiry > Math.round(new Date().getTime() / 1000)) {
      state.auth_data = newAuthData;
      state.jwt = newAuthData.JWT;
      state.jwt_expiry = newAuthData.JWTExpiry;
    //   dynStorage.set('jwt', state.jwt);
    //   dynStorage.set('jwt_expiry', state.jwt_expiry);
    } else {
      state.auth_data = null;
      state.jwt = null;
      state.jwt_expiry = 0;
    //   dynStorage.remove('jwt');
    //   dynStorage.remove('jwt_expiry');
    }
  },

  // mut_update_jwt is desgined to be called from the Axios handler. When the
  // axios middleware recieves the X-Auth-Token + X-Auth-Token-Expiry headers
  // in the HTTP(S) response, then the backend has auto-renewed the token,
  // and it should call this to update the locally cached JWT and JWTExpiry
  mut_update_jwt(state, { JWT, JWTExpiry }) {
    if (JWT && JWTExpiry && JWTExpiry > Math.round(new Date().getTime() / 1000)) {
      state.jwt = JWT;
      state.jwt_expiry = JWTExpiry;
    //   dynStorage.set('jwt', state.jwt);
    //   dynStorage.set('jwt_expiry', state.jwt_expiry);
    } else {
      state.auth_data = null;
      state.jwt = null;
      state.jwt_expiry = 0;
    //   dynStorage.remove('jwt');
    //   dynStorage.remove('jwt_expiry');
    }
  },

  // mut_logout is designed to set the auth state back to the original
  // (logged out) state
  mut_logout(state) {
    // dynStorage.remove('jwt');
    // dynStorage.remove('jwt_expiry');

    state.auth_data = null;
    state.jwt = null;
    state.jwt_expiry = 0;
  },
};

const actions = {
  // LoginEmailPassword is a wrapped for AuthCheck
  // It is intended to be used for LOCAL Email and Password authentication
  LoginEmailPassword({ dispatch }, { login, pass }) {
    return dispatch('AuthCheck', {
      IdentityProvider: 'LOCAL',
      AuthID: login,
      AuthCode: pass,
    });
  },

  RegisterUser({ dispatch }, { email, passnew, firstname, lastname, phone }) {
    return dispatch('Auth', {
      IdentityProvider: 'LOCAL',
      AuthID: email,
      AuthCode: passnew,
      Email: email,
      MobileNumber: phone,
      FirstName: firstname,
      LastName: lastname,
    });
  },

  registerCustomer({ getters, dispatch }, sign) {
    return dispatch('Auth', {
      IdentityProvider: 'FIREBASE',
      AuthID: getters.auth_user_uid,
      AuthCode: getters.auth_token,
      Email: sign?.email || getters.auth_user_email,
      MobileNumber: sign?.phone || getters.account_phone,
      FirstName: sign?.firstname || getters.auth_user_first_name,
      LastName: sign?.lastname || getters.auth_user_last_name,
      NotifySpecialOffers: sign?.offers ? 2 : getters.mockup_user?.NotifySpecialOffers ? 2 : 0,
      NotifyNewsletters: sign?.newsletters ? 2 : getters.mockup_user?.NotifyNewsletters ? 2 : 0,
    })
  },

  ChangePass({ dispatch }, { pass, passnew }) {
    return dispatch('UpdateLocalUser', {
      Password: passnew,
    });
  },

  // Auth makes the API call to add a new local user
  Auth({ dispatch, getters, commit }, payload) {
    console.log(getters);
    payload.SPID = getters.app_spid;
    payload.IncludeRoles = true;

    return axios
      .post(`/v2/:spid/auth`, payload, { refresh: false })
      .then((response) => {
        if (!response.data) {
          return Promise.reject(Error(i18n.t('no_payload')));
        }

        commit('mut_auth_data', response.data);

        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (error) {
          console.log('Auth ERROR:', error);
          return Promise.reject(error);
        }
        return Promise.reject(Error(`${i18n.t('unknown_error_in')} Auth`));
      });
  },

  AuthCheckViaFirebaseToken({ dispatch, commit }) {
    const user = firebase.auth().currentUser;

    if (!user) {
      commit('mut_is_processing_auth_check', false);

      return Promise.reject(Error(i18n.t('no_user')));
    };

    const firebaseId = user.uid;

    return user.getIdToken().then(firebaseToken => {
      return dispatch('AuthCheck', {
        IdentityProvider: 'FIREBASE',
        AuthID: firebaseId,
        AuthCode: firebaseToken,
      });
    });
  },

  // AuthCheck makes the API call to check authentication
  AuthCheck({ dispatch, getters, commit }, payload) {
    commit('mut_is_processing_auth_check', true);

    payload.SPID = getters.app_spid;
    payload.IncludeRoles = true;

    return axios
      .post(`/v2/:spid/auth_check`, payload, { refresh: false, is_auth_check: true  })
      .then((response) => {
        if (!response.data) {
          commit('mut_is_processing_auth_check', false);

          return Promise.reject(Error(i18n.t('no_payload')));
        }

        commit('mut_auth_data', response.data);
        commit('mut_is_processing_auth_check', false);

        return Promise.resolve();
      })
      .catch((error) => {
        commit('mut_is_processing_auth_check', false);

        if (error) {
          console.log('AuthCheck ERROR:', error);
          return Promise.reject(error);
        }
        return Promise.reject(Error(`${i18n.t('unknown_error_in')} AuthCheck`));
      });
  },

  // AutoLogin is designed to be run when the application loads
  // to resume/recover the application authentication state
  // It will re-authenticate with the API to ensure the JWT is still valid
  AutoLogin({ dispatch, commit }) {
    const storedJWT = dynStorage.get('jwt');
    if (!storedJWT) {
      commit('mut_logout');
      // AutoLogin must always return a resolved Promise
      return Promise.resolve();
    }

    return dispatch('AuthCheck', {
      IdentityProvider: 'JWT_REFRESH',
      AuthID: 'jwt',
      AuthCode: storedJWT,
    })
      .then(() => {
        // AutoLogin must always return a resolved Promise
        return Promise.resolve();
      })
      .catch(() => {
        // AutoLogin must always return a resolved Promise
        return Promise.resolve();
      });
  },

  // Logout logs the user out, and removes all authentication state
  Logout({ dispatch, commit }) {
    commit('mut_logout');

    commit('resetPaymentMethods')
    commit('resetPrepayProduct')
    commit('resetPostpayProduct')
    commit('resetUFBProduct')
  },

  // setJWT is called from axios middleware to update the local cache
  // of the JWT and JWT Expirty
  setJWT({ getters, commit }, { JWT, JWTExpiry }) {
    if (!JWT || JWT == '-' || JWTExpiry == -1) {
      commit('mut_logout');
      return;
    }
    if (!JWTExpiry) {
      commit('mut_update_jwt', { JWT, JWTExpiry: getters.jwt_expiry });
      return;
    }
    commit('mut_update_jwt', { JWT, JWTExpiry });
  },

  // UpdateLocalUser makes the API call to update user's data
  UpdateLocalUser({ getters, commit }, payload) {
    payload.SPID = getters.app_spid;

    return axios
      .put(`/v2/:spid/auth/LOCAL/${getters.id_auth_id}`, payload, { refresh: false })
      .then((response) => {
        if (!response.data) {
          return Promise.reject(Error(i18n.t('no_payload')));
        }

        commit('mut_auth_data', response.data);

        return Promise.resolve();
      })
      .catch((error) => {
        if (error) {
          console.log('UpdateLocalUser ERROR:', error);
          return Promise.reject(error);
        }
        return Promise.reject(Error(`${i18n.t('unknown_error_in')} UpdateLocalUser`));
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
