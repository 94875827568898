import store from '@/store';

export default (to, from, next) => {
    const has_about_outer_link = Boolean(store.getters.app_about_outer_link)

    return {
        allow: has_about_outer_link,
        redirect: { name: 'home' },
    }
};
