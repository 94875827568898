import Vue from 'vue'
import store from '@/store'

import axiosIntents from '@/utils/axios-intents'

import dynStorage from '@/utils/storage-dynamic'

const PRODUCT_COLORS = {
    starlight:  { value: 'starlight',  title: 'StarLight',   hex: '#e4e1da' },
    silver:     { value: 'silver',     title: 'Silver',      hex: '#ccc'    },
    graphite:   { value: 'graphite',   title: 'Graphite',    hex: '#61605c' },
    midnight:   { value: 'midnight',   title: 'Midnight',    hex: '#151e30' },
    sierrablue: { value: 'sierrablue', title: 'Sierra Blue', hex: '#a8c0d7' },
    blue:       { value: 'blue',       title: 'Blue',        hex: '#4faee2' },
    pink:       { value: 'pink',       title: 'Pink',        hex: '#f3b0b3' },
    red:        { value: 'red',        title: 'Red',         hex: '#bf2d2f' },
    green:      { value: 'green',      title: 'Green',       hex: '#005f56' },
    violet:     { value: 'violet',     title: 'Violet',      hex: '#8c89b4' },
    copper:     { value: 'copper',     title: 'Copper',      hex: '#907c4d' },
    black:      { value: 'black',      title: 'Black',       hex: '#474747' },
    coral:      { value: 'coral',      title: 'Coral',       hex: '#ffa6db' },
}

const PRODUCT_STORAGES = {
      '1_tb': { value: '1_tb',   title: '1 TB',   count: 11 },
    '512_gb': { value: '512_gb', title: '512 Gb', count: 19 },
    '256_gb': { value: '256_gb', title: '256 Gb', count: 12 },
    '128_gb': { value: '128_gb', title: '128 Gb', count:  8 },
}

const PRODUCT_FILTERS = {
    brands: {
        title: 'Brands',
        options: [
            { value: 'iphone',         title: 'iPhone'         },
            { value: 'samsung',        title: 'Samsung'        },
            { value: 'oppo',           title: 'OPPO'           },
            { value: 'vodafone_smart', title: 'Vodafone Smart' },
        ],
    },

    models: {
        title: 'Models',
        options: [
            { value: 'iPhone 12',         title: 'iPhone 12',         count:  6 },
            { value: 'iPhone 12 Pro',     title: 'iPhone 12 Pro',     count:  3 },
            { value: 'iPhone 12 Pro Max', title: 'iPhone 12 Pro Max', count:  7 },
            { value: 'iPhone 13',         title: 'iPhone 13',         count:  9 },
            { value: 'iPhone 13 Pro',     title: 'iPhone 13 Pro',     count: 12 },
            { value: 'iPhone 13 Pro Max', title: 'iPhone 13 Pro Max', count:  5 },
            { value: 'iPhone 13 Mini',    title: 'iPhone 13 Mini',    count:  3 },
            { value: 'iPhone 11',         title: 'iPhone 11',         count:  6 },
            { value: 'iPhone 10',         title: 'iPhone 10',         count:  3 },
            { value: 'iPhone 9',          title: 'iPhone 9',          count:  3 },
        ],
    },

    colors: {
        title: 'Color',
        options: Object.values(PRODUCT_COLORS),
    },

    storage: {
        title: 'Storage capacity',
        options: [
            { value: '1 TB',   title: '1 TB',   count: 11 },
            { value: '512 Gb', title: '512 Gb', count: 19 },
            { value: '256 Gb', title: '256 Gb', count: 12 },
            { value: '128 Gb', title: '128 Gb', count:  8 },
        ],
    },

    sims: {
        title: 'Number of SIM cards',
        options: [
            { value: '1', title: '1', count: 22 },
            { value: '2', title: '2', count: 34 },
        ],
    },
}

import {
    AVAILABLE_SIM_TYPE_PRODUCTS,

    eshopItem,
    eshopCartItem,
    eshopCartItemSIM,
    eshopCartItemPlan,

    ORDER_STATUSES
} from '@/helpers/eshop'

const ESHOP_CART = 'eshop-cart'
const ESHOP_PURCHASED = 'eshop-purchased'

import AVAILABLE_PREVIEW_PHOTOS from '@/views/eshop/components/eshop-product/AVAILABLE_PREVIEW_PHOTOS'

export default {
    state: {
        eshop_cart: {},
        eshop_cart_uuid: null,
        eshop_cart_number: null,

        eshop_cart_modal: false,
        eshop_cart_loading: false,

        eshop_purchased: null,
    },
    
    getters: {
        eshop_cart: state => state.eshop_cart,
        eshop_cart_uuid: state => state.eshop_cart_uuid,
        eshop_cart_number: state => state.eshop_cart_number,

        /**
         * @used common
         */
        eshop_cart_modal: state => state.eshop_cart_modal,
        eshop_cart_loading: state => state.eshop_cart_loading,

        eshop_cart_total: state => {
            let price = 0
            let discount = 0

            for (const uuid in state.eshop_cart) {
                price+= state.eshop_cart[uuid].discount ? state.eshop_cart[uuid].discount : state.eshop_cart[uuid].price
                discount+= state.eshop_cart[uuid].discount ? state.eshop_cart[uuid].price : 0
            }

            return price && discount ? {
                price: discount,
                discount: price,
            } : {
                price,
                discount: null,
            }
        },
        eshop_cart_items_count: state => Object.keys(state.eshop_cart).length,
        eshop_cart_items_quantity: state => {
            let quantity = 0

            for (const uuid in state.eshop_cart) {
                quantity+= state.eshop_cart[uuid].quantity || 1
            }

            return quantity
        },

        eshop_product_colors: () => PRODUCT_COLORS,
        eshop_product_filters: () => PRODUCT_FILTERS,
        eshop_order_statuses: () => ORDER_STATUSES,

        eshop_purchased: state => state.eshop_purchased,

        eshop_has_e_sim: state => Object.keys(state.eshop_cart).indexOf(AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID) > -1,
        eshop_has_real_sim: state => Object.keys(state.eshop_cart).indexOf(AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID) > -1,
    },

    mutations: {
        eshopCartSave(state, cart) {
            state.eshop_cart = cart

            // dynStorage.set(ESHOP_CART, state.eshop_cart, false, true)
        },

        eshopCartLoading(state, loading) {
            state.eshop_cart_loading = loading
        },

        // обьединение информаици по сохраннной в сторе корзины с полученной из АПИ
        eshopCartMerge(state, eshop_cart) {
            if (eshop_cart.Items) {
                for (let i = 0, len = eshop_cart.Items.length; i < len; i++) {
                    const eshop_item = eshop_cart.Items[i]

                    if (!(eshop_item.ItemUUID in state.eshop_cart)) {
                        const item = eshopItem(eshop_item)

                        if (item) {
                            Vue.set(state.eshop_cart, eshop_item.ItemUUID, item)
                        }
                    }

                    if (eshop_cart.Items.length) {
                        dynStorage.remove(ESHOP_PURCHASED)
                    }
                }
            }

            for (const uuid in state.eshop_cart) {
                /**
                 * @todo расширить на работу со всеми типами товаров, когда они появятся в АПИ
                 */
                switch (state.eshop_cart[uuid].type) {
                    case 'sim':
                    case 'esim':
                    case 'plan': {
                        if (eshop_cart.Items) {
                            const item = eshop_cart.Items.find(item => item.ItemUUID == uuid)

                            if (item) {
                                state.eshop_cart[uuid].product.price = item.PriceCents / 100

                                state.eshop_cart[uuid].price = state.eshop_cart[uuid].product.price
                                state.eshop_cart[uuid].quantity = item.Quantity

                                for (const key in state.eshop_cart[uuid].product.item) {
                                    state.eshop_cart[uuid].product.item[key] = item[key]
                                }
                            } else {
                                Vue.delete(state.eshop_cart, uuid)
                            }
                        } else {
                            Vue.delete(state.eshop_cart, uuid)
                        }
                    } break
                }
            }

            state.eshop_cart_uuid = eshop_cart.UUID
            state.eshop_cart_number = eshop_cart.Number

            if (!eshop_cart.CustomerUUID) {
                dynStorage.set(ESHOP_CART, eshop_cart.UUID, false, true)
            } else {
                dynStorage.remove(ESHOP_CART)
            }
        },

        eshopCartReset(state, force) {
            if (!state.eshop_cart_uuid || force) {
                state.eshop_cart = {}
                state.eshop_cart_uuid = null
                state.eshop_cart_number = null
            }
        },

        eshopPurchasedSave(state, { items, location }) {
            let eshop_purchased = {
                number: state.eshop_cart_number,
            }

            const sim_product = items.find(item =>
                item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID
                || item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID
            )

            if (sim_product) {
                if (sim_product.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID) {
                    eshop_purchased.e_sim = true
                }

                const product = store.getters.all_services.find(item =>
                    item.UUID == sim_product.ProductCatalogUUID
                    && item.Availabilities?.[0]?.UUID == sim_product.ProductAvailabilityUUID
                )

                if (product) {
                    eshop_purchased.plan = {
                        name: product.Name,
                        price: (sim_product.PriceCents / 100).toFixed(2)
                    }
                }
            }

            /**
             * @todo сделать проверку на наличие "физических" товаров в корзине,
             *       когда они появятся
             */
            if (!eshop_purchased.e_sim) {
                eshop_purchased.address = location.Formatted
            }

            state.eshop_purchased = eshop_purchased

            dynStorage.set(ESHOP_PURCHASED, eshop_purchased, false, true)
        },

        eshopPurchasedLoad(state) {
            state.eshop_purchased = dynStorage.get(ESHOP_PURCHASED, true)
        },

        eshopPurchasedReset(state) {
            state.eshop_purchased = null

            dynStorage.remove(ESHOP_PURCHASED)
        },

        /**
         * @used common
         */
        eshopCartModal(state, show) {
            state.eshop_cart_modal = show
        },
        eshopCartModalShow(state) {
            state.eshop_cart_modal = true
        },
        eshopCartModalHide(state) {
            state.eshop_cart_modal = false
        },
    },

    actions: {
        eshopCartLoad({getters, commit, dispatch}) {
            commit('eshopCartLoading', true)

            const cart_uuid = dynStorage.get(ESHOP_CART, true)

            return dispatch('GetCatalogServices').then(() =>
                (cart_uuid
                    ? dispatch('eshopCartRestoreByUUID', cart_uuid)
                    : getters.current_account_uid
                        ? dispatch('eshopCartRestoreByCustomer')
                        : Promise.resolve(getters.eshop_cart)
                ).finally(() => {
                    commit('eshopCartLoading', false)
                })
            )
        },

        /**
         * @used /eshop
         */
        eshopCartPush({getters, commit, dispatch}, product) {
            const uuid = product.uuid || product.UUID

            return uuid in getters.eshop_cart
                ? dispatch('eshopCartItemQuantityIncrease', { uuid })
                : new Promise((resolve, reject) => {
                    setTimeout(() => {
                        const cart = {...getters.eshop_cart}

                        try {
                            let cart_item = {}

                            if (product.type == 'mobile') {
                                const color_value = product.color
                                    ? typeof product.color == 'string'
                                        ? product.color
                                        : product.color.value
                                    : Object.keys(PRODUCT_COLORS)[0]

                                const color = color_value in PRODUCT_COLORS
                                    ? PRODUCT_COLORS[color_value]
                                    : {
                                        value: color_value,
                                        name: color_value[0].toUpperCase() + color_value.slice(1),
                                        hex: color_value,
                                    }
                                const photos = color_value in AVAILABLE_PREVIEW_PHOTOS
                                    ? AVAILABLE_PREVIEW_PHOTOS[color_value]
                                    : AVAILABLE_PREVIEW_PHOTOS[Object.keys(AVAILABLE_PREVIEW_PHOTOS)[0]]

                                const storage_value = product.storage
                                    ? typeof product.storage == 'string'
                                        ? product.storage
                                        : product.storage.value
                                    : Object.keys(PRODUCT_STORAGES)[0]
                                
                                const storage = storage_value in PRODUCT_STORAGES
                                    ? PRODUCT_STORAGES[storage_value]
                                    : PRODUCT_STORAGES[Object.keys(PRODUCT_STORAGES)[0]]

                                cart_item = eshopCartItem(product, {
                                    model: product.model,
                                    discount: product.discount,

                                    photo: photos[0].s,
                                    specifications: [
                                        storage.title,
                                        color.title,
                                    ],

                                    quantifiable: true,
                                })
                            } else {
                                cart_item = eshopCartItem(product, {
                                    quantifiable: false,
                                })
                            }

                            cart[cart_item.uuid] = cart_item

                            // полная замена eshop_cart, так как ее содержимое будет присылаться из API
                            commit('eshopCartSave', cart)
                            
                            resolve(getters.eshop_cart)
                        } catch (error) {
                            reject(error)
                        }
                    }, (Math.random() + 1) * 150)
                })
        },

        eshopCartPushSIM({getters, commit, dispatch}, { plan, type }) {
            if (type in AVAILABLE_SIM_TYPE_PRODUCTS) {
                if (plan?.product?.Availabilities
                    && Array.isArray(plan.product.Availabilities)
                    && plan.product.Availabilities.length
                ) {
                    /**
                     * @todo в корзине не может быть более 1 SIM одновременно?
                     *       удалить, при необходимости, когда будет больше информации по flow
                     */
                    const cart = {...getters.eshop_cart}
                    let removed_sims = false

                    for (const uuid in cart) {
                        switch (cart[uuid].type) {
                            case 'sim':
                            case 'esim':
                            case 'plan': {
                                delete cart[uuid]

                                removed_sims = true
                            } break
                        }
                    }

                    const eshopCartPushSIM = () => new Promise((resolve, reject) => {
                        try {
                            const cart_item_sim = eshopCartItem(eshopCartItemSIM(AVAILABLE_SIM_TYPE_PRODUCTS[type], plan.product), {
                                quantifiable: false,
                            })

                            const cart_item_plan = eshopCartItem(eshopCartItemPlan(plan.product), {
                                removable: false,
                                quantifiable: false,
                            })

                            cart[cart_item_sim.uuid] = cart_item_sim
                            cart[cart_item_plan.uuid] = cart_item_plan

                            // полная замена eshop_cart, так как ее содержимое будет присылаться из API
                            commit('eshopCartSave', cart)

                            dispatch('eshopCartSave').then(resolve).catch(reject)
                        } catch (error) {
                            reject(error)
                        }
                    })

                    return removed_sims // обновляем корзину целиком, так как в АПИ нет возможности удаления item-ов в случае отсутствия CustomerUUID
                        ? new Promise((resolve, reject) => {
                            commit('eshopCartSave', cart)

                            dispatch('eshopCartSave').then(resolve).catch(reject)
                        }).then(eshopCartPushSIM)
                        : eshopCartPushSIM()
                } else {
                    return Promise.reject(`Plan not specified`)
                }
            } else {
                return Promise.reject('Unknown SIM type')
            }
        },

        eshopCartSave({getters, commit, dispatch}, cart) {
            if (!cart) {
                cart = {...getters.eshop_cart}
            }
            
            const items = []
            for (const uuid in cart) {
                switch (cart[uuid].type) {
                    case 'sim':
                    case 'esim':
                    case 'plan': {
                        items.push({
                            ...cart[uuid].product.item,
                            Quantity: cart[uuid].quantity,
                        })
                    } break
                }
            }

            const payload = {
                // Address: {
                //     City: "string",
                //     Country: "string",
                //     Email: "string",
                //     Lines: [
                //         "string",
                //         "string",
                //         "string"
                //     ],
                //     Name: "string",
                //     Phone: "string",
                //     Postcode: "string"
                // },
                // AmountCents: 0,
                // AmountTaxCents: 0,
                CustomerUUID: getters.current_account_uid,
                Items: items,
                SPID: getters.app_spid,

                UUID: getters.eshop_cart_uuid,
                Number: getters.eshop_cart_number,
            }

            return axiosIntents.post('/eshop/cart', payload).then(({result}) => {
                commit('eshopCartSave', cart)
                commit('eshopCartMerge', {...result, CustomerUUID: payload.CustomerUUID})

                if (result?.Items) {
                    const value = ((result.AmountCents || 0) + (result.AmountTaxCents || 0)) / 100

                    const items = result?.Items.reduce((acc, item) => {
                        return [...acc, {
                            item_id: item.ItemUUID || '',
                            item_name: item.Name || '',
                            quantity: item.Quantity || 0,
                            price: (item.PriceCents || 0) / 100,
                        }]
                    }, [])

                    dispatch('logAnalyticsEventAddToCart', { parameters: {
                            currency: "NZD",
                            value,
                            items,
                        }})
                }

                return Promise.resolve(getters.eshop_cart)
            })
        },

        eshopCartRestoreByUUID({getters, commit}, uuid) {
            return axiosIntents.get(`/eshop/cart/${ uuid }`).then(({result}) => {
                commit('eshopCartMerge', result)

                return Promise.resolve(getters.eshop_cart)
            })
        },

        eshopCartRestoreByCustomer({getters, commit}) {
            const uuid = getters.current_account_uid

            return axiosIntents.get('/eshop/customer/:uuid/cart').then(({result}) => {
                if (uuid == getters.current_account_uid) {
                    commit('eshopCartMerge', result)
                }

                return Promise.resolve(getters.eshop_cart)
            })
        },

        eshopCartRemove({getters, commit, dispatch}, { uuid }) {
            if (uuid in getters.eshop_cart) {
                const cart = {...getters.eshop_cart}
                const item = cart[uuid]

                delete cart[uuid]

                let is_need_save = false

                switch (item.type) {
                    case 'sim':
                    case 'esim':
                    case 'plan': {
                        if (item.ProductCatalogUUID && item.ProductAvailabilityUUID) {
                            for (const uuid in cart) {
                                if (cart[uuid].ProductCatalogUUID == item.ProductCatalogUUID
                                    && cart[uuid].ProductAvailabilityUUID == item.ProductAvailabilityUUID
                                ) {
                                    delete cart[uuid]
                                }
                            }
                        } else {
                            for (const uuid in cart) {
                                if (cart[uuid].type == 'sim' || cart[uuid].type == 'esim' || cart[uuid].type == 'plan') {
                                    delete cart[uuid]
                                }
                            }
                        }

                        is_need_save = true
                    } break
                }

                return is_need_save // @todo сделать для других типов продуктов, когда они появятся в АПИ
                    ? dispatch('eshopCartSave', cart)
                    : new Promise(resolve => {
                        setTimeout(() => {
                            commit('eshopCartSave', cart)
                            resolve(getters.eshop_cart)
                        }, (Math.random() + 1) * 150)
                    })
            } else {
                return Promise.resolve(getters.eshop_cart)
            }
        },

        eshopCartItemQuantitySet({getters, commit, dispatch}, { uuid, quantity }) {
            return uuid in getters.eshop_cart
                ? quantity
                    ? new Promise((resolve, reject) => {
                        setTimeout(() => {
                            try {
                                const cart = {...getters.eshop_cart}
                                
                                cart[uuid].quantity = quantity
                                cart[uuid].price = cart[uuid].product.price * quantity
                                cart[uuid].discount = cart[uuid].product.discount
                                    ? cart[uuid].product.discount * quantity
                                    : null

                                // полная замена eshop_cart, так как ее содержимое будет присылаться из API
                                commit('eshopCartSave', cart)

                                resolve(getters.eshop_cart)
                            } catch (error) {
                                reject(error)
                            }
                        }, (Math.random() + 1) * 150)
                    })
                    : dispatch('eshopCartRemove', { uuid })
                : Promise.resolve(getters.eshop_cart)
        },
        eshopCartItemQuantityIncrease({getters, dispatch}, { uuid }) {
            return uuid in getters.eshop_cart
                ? dispatch('eshopCartItemQuantitySet', { uuid, quantity: getters.eshop_cart[uuid].quantity + 1 })
                : Promise.resolve(getters.eshop_cart)
        },
        eshopCartItemQuantityDecrease({getters, dispatch}, { uuid }) {
            return uuid in getters.eshop_cart
                ? getters.eshop_cart[uuid].quantity > 1
                    ? dispatch('eshopCartItemQuantitySet', { uuid, quantity: getters.eshop_cart[uuid].quantity - 1 })
                    : dispatch('eshopCartRemove', { uuid })
                : Promise.resolve(getters.eshop_cart)
        },



        eshopPurchase({getters, commit, dispatch}, { location, payment }) {
            const cart = {...getters.eshop_cart}

            const items = []
            for (const uuid in cart) {
                switch (cart[uuid].type) {
                    case 'sim':
                    case 'esim':
                    case 'plan': {
                        items.push(cart[uuid].product.item)
                    } break
                }
            }

            const auth_user_profile_creds = getters.auth_user_profile_creds

            const payload = {
                Address: {
                    City: location.City,
                    Country: location.Country,
                    Postcode: location.PostCode,
                    Lines: location.Lines,
                    LocationProvider: location.LocationProvider,
                    LocationProviderIDX: location.LocationProviderIDX,
                    Email: auth_user_profile_creds.email,
                    Name: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
                    Phone: auth_user_profile_creds.phone,
                },
                // AmountCents: 0,
                // AmountTaxCents: 0,
                CustomerUUID: getters.current_account_uid,
                Items: items,
                SPID: getters.app_spid,

                UUID: getters.eshop_cart_uuid,
                Number: getters.eshop_cart_number,
            }

            return axiosIntents.post('/eshop/checkout', payload).then(({result}) => {
                commit('eshopPurchasedSave', { items, location })

                if (items.length) {
                    let value = 0
    
                    const analytics_items = items.reduce((acc, item) => {
                        value += (item.PriceCents || 0) / 100
    
                        return [...acc, {
                            item_id: item.ItemUUID || '',
                            item_name: item.Name || '',
                            quantity: item.Quantity || 0,
                            price: (item.PriceCents || 0) / 100,
                        }]
                    }, [])
    
                    dispatch('logAnalyticsEventPurchase', { parameters: {
                            currency: "NZD",
                            value,
                            items: analytics_items,
                        }})
                }


                return Promise.resolve(result)
            })
        },


        /**
         * @used common
         */
        eshopCartModal({commit}, show) {
            commit('eshopCartModal', show)
        },
        eshopCartModalShow({commit}) {
            commit('eshopCartModal', true)
        },
        eshopCartModalHide({commit}) {
            commit('eshopCartHide', false)
        },

        eshopGetCustomerOrders() {
            return axiosIntents.get('/eshop/customer/:uuid/orders')
                .then(({result}) => Promise.resolve(result))
        },

        eshopGetCustomerOrder({}, OrderNumber) {
            return axiosIntents.get(`/eshop/customer/:uuid/order/${OrderNumber}`)
                .then(({result}) => Promise.resolve(result))
        },

        eshopGetCustomerOrderItem({ dispatch }, { OrderNumber, ItemUUID }) {
            return dispatch('eshopGetCustomerOrder', OrderNumber).then(({OrderItems, Status}) => {
                if (!OrderItems) return Promise.reject('No Order Items')

                const spreded_order_items = OrderItems.reduce((acc, orderItem) => {
                    const order_item_products = orderItem.Products || []

                    return [...acc, orderItem, ...order_item_products]
                }, [])

                const order_item = spreded_order_items.find(item => item.ItemUUID == ItemUUID)

                if (!order_item) return Promise.reject('No Order Item')

                return Promise.resolve({...order_item, Status})
            })
        },

        /**
         * @used /eshop/thanks
         */
        checkOrderESIMStatus({}, OrderNumber) {
            return axiosIntents.get(`/eshop/orders-esim-status/:uuid/order/${OrderNumber}`)
                .then(({result}) => Promise.resolve(result))
        },
    },
}