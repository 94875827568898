export default class StoreHash {
    /**
     * @param {int} lifetime - время жизни хешированных данных в микросекундах (1 sec == 1000)
     */
    constructor(lifetime) {
        this.lifetime = lifetime
        this.reset()
    }

    reset() {
        this.loaded = null
        this.data = null
    }

    resetWithDefaultData(data) {
        this.loaded = null
        this.data = data
    }

    expireNow() {
        this.loaded = null
    }

    expired() {
        let expired = true

        if (this.loaded) {
            if ((new Date() - this.loaded) < this.lifetime) {
                expired = false
            } else {
                this.data = null
            }
        }

        return expired
    }

    fix(data) {
        this.data = data
        this.loaded = new Date()

        return true
    }

    hashedData() {
        return this.data
    }
}