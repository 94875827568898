<template>
    <div class="card" :class="{'card--hoverable': hoverable}">
        <slot></slot>

        <div v-if="disabled" class="card--disabled"></div>
    </div>
</template>

<script>
export default {
    props: {
        hoverable: { type: Boolean, default: false },
        disabled:  { type: Boolean, default: false },
    },
}
</script>

<style>
</style>