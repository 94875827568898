import store from '@/store'

export default async (to, from, next) => {
    const getPaymentMethods = () => {
        const promises = [store.dispatch('subscribePaymentMethods')]

        if (store.getters.is_app_environment_billing_routing_allowed) {
            promises.push(store.dispatch('BindBillingRoutes', store.getters.current_account_uid))
        }

        return Promise.all(promises) 
    }

    if (!store.getters.has_payment_cards_or_parent_billing_routes) {
        await getPaymentMethods()
    }

    if (!store.getters.has_payment_cards_or_parent_billing_routes) {
        throw {
            allow: false,
            redirect: { name: 'no-payment-methods' },
        }
    }

    return {
        allow: store.getters.has_payment_cards_or_parent_billing_routes,
        redirect: { name: 'home' },
    }
}
