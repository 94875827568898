import i18n from '@/i18n';

export default {
    state: {
        account_balance_min: 0,
    },

    getters: {
        account_balance:        (state, getters) => getters.current_account?.info?.balance || 0,
        account_balance_min:    state => state.account_balance_min,

        account_balance_expire: (state, getters) => getters.current_account
            && getters.current_account?.info?.expireAt
            && !isNaN(new Date(getters.current_account?.info?.expireAt))
            && new Date(getters.current_account?.info?.expireAt)
            || null,

        payments_history_OLD:      (state, getters) => getters.current_account?.['payment-history'] || [],

        payment_methods_OLD:       (state, getters) => getters.is_processing_autologin ? [] : getters.current_account?.tokens || [],
        payment_methods_cards_OLD: (state, getters) => getters.payment_methods
            .filter(({token}) => token?.card)
            .map(payment_method => {
                const is_default = payment_method.default
                const cardId = payment_method.token?.card?.cardId || payment_method.token?.card?.id
                const { brand, last4 } = payment_method.token?.card
                return { is_default, cardId, brand, last4 }
            }),

        top_up_auto_OLD:            (state, getters) => getters.current_account?.['top-up-auto'],
    },

    actions: {
        RemovePaymentMethod({ getters, dispatch }, cardId) {
            const payment_methods = [...getters.payment_methods]
            const index_removal = payment_methods.findIndex(payment_method => payment_method.token.card && payment_method.token.card.cardId == cardId)
            if (index_removal == -1) {
                return Promise.reject(i18n.t('card_is_not_found'))
            }

            const removed_payment_method = payment_methods.splice(index_removal, 1)

            if (removed_payment_method.default && payment_methods.length) {
                payment_methods[0].default = true
            }

            return dispatch('UpdateCurrentAccount', { tokens: payment_methods })
        },

        AddPaymentMethod({ getters, dispatch }, payment_method) {
            const new_payment_method = {token: {card: {}}}
            if (getters.payment_methods.length == 0) {
                new_payment_method.default = true
            }

            new_payment_method.token.card.addressLine1  = payment_method.token.card.address_line1
            new_payment_method.token.card.addressLine2  = payment_method.token.card.address_line2
            new_payment_method.token.card.brand         = payment_method.token.card.brand
            new_payment_method.token.card.cardId        = payment_method.token.card.id
            new_payment_method.token.card.country       = payment_method.token.card.country
            new_payment_method.token.card.expMonth      = payment_method.token.card.exp_month
            new_payment_method.token.card.expYear       = payment_method.token.card.exp_year
            new_payment_method.token.card.funding       = payment_method.token.card.funding
            new_payment_method.token.card.last4         = payment_method.token.card.last4

            new_payment_method.token.created    = payment_method.token.created
            new_payment_method.token.livemode   = payment_method.token.livemode
            new_payment_method.token.tokenId    = payment_method.token.id

            const payment_methods = [ ...getters.payment_methods, new_payment_method ]

            return dispatch('UpdateCurrentAccount', { tokens: payment_methods })
        },

        UpdateDefaultPaymentMethod({ getters, dispatch }, new_default_card_id){
            const payment_methods = getters.payment_methods.map(payment_method => {
                if (payment_method?.token?.card?.cardId === new_default_card_id) {
                    return {
                        ...payment_method, 
                        default: true,
                    }
                } else {
                    return {
                        ...payment_method, 
                        default: false,
                    }
                }
            });

            return dispatch('UpdateCurrentAccount', { tokens: payment_methods })
        },

        ProcessPayment({}, { payment_method, amount }) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    return Math.random() < 0.05 ? reject(i18n.t('payment_is_failed')) : resolve(amount)
                }, 2000)
            })
        },

        ProcessTopUpPayment({ getters, dispatch }, { payment_method, amount }) {
            return dispatch('ProcessPayment', {payment_method, amount}).then( amount => {
                return dispatch('ProcessBalanceCharging', amount).then( payload => {
                    dispatch('UpdateExpireAt')
                    return Promise.resolve(payload)
                })
            })
        },

        ProcessBalanceCharging({ getters, dispatch }, amount) {
            const new_balance = getters.account_balance + amount

            if (new_balance < getters.account_balance_min) {
                return Promise.reject(i18n.t('not_enough_balance'))
            }

            dispatch('AddPaymentToHistory', { amount })

            return dispatch('UpdateBalance', new_balance).then(() => {
                return { new_balance: getters.account_balance }
            })
        },

        ProcessBalanceWithdraw({ dispatch }, amount) {
            return dispatch('ProcessBalanceCharging', -amount)
        },

        AddPaymentToHistory({ getters, dispatch }, { amount, date = new Date().toISOString()}) {
            const new_payment = { amount, date }
            const payments_history = [ ...getters.payments_history, new_payment ]

            return dispatch('UpdateCurrentAccount', { 'payment-history': payments_history })
        },

        UpdateBalance({ dispatch }, new_balance) {
            return dispatch('UpdateCurrentAccountInfo', { balance: +new_balance.toFixed(2) })
        },

        UpdateExpireAt({ dispatch }) {
            const new_expire = new Date()
            new_expire.setFullYear(new_expire.getFullYear() + 1)

            return dispatch('UpdateCurrentAccountInfo', { expireAt: new_expire.toISOString() })
        },

        SaveAutoTopUp({ dispatch }, auto_top_up) {
            return dispatch('UpdateCurrentAccount', auto_top_up)
        }
    },

    mutations: {}
}