// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: mobilegroup.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	MobileGroups: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	MobileGroupMembers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getMobileGroupByUUID: state => (UUID) => (state.MobileGroups.UUIDs[ UUID ] && !state.MobileGroups.UUIDs[ UUID ].expired()) ?  state.MobileGroups.UUIDs[ UUID ].hashedData() : null,
	getMobileGroupMemberByUUID: state => (UUID) => (state.MobileGroupMembers.UUIDs[ UUID ] && !state.MobileGroupMembers.UUIDs[ UUID ].expired()) ?  state.MobileGroupMembers.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveMobileGroup saves a single MobileGroup object
	// into both the StoreHash cache and the VueX store
	mutSaveMobileGroup(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.MobileGroups.UUIDs)) {
			state.MobileGroups.UUIDs[ obj.UUID ] = new StoreHash(state.MobileGroups.lifetime)
		}
		state.MobileGroups.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMobileGroupByUUID removes a MobileGroup object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMobileGroupByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.MobileGroups.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMobileGroups saves a paginated response of MobileGroups
	mutSaveMobileGroups(state, { Response }) {
		if (!Response || !Array.isArray(Response.MobileGroups)) { return } // Must have Response object with results
		for (let i = 0; i < Response.MobileGroups.length; i++) {
			if (!(Response.MobileGroups[i].UUID in state.MobileGroups.UUIDs)) {
				state.MobileGroups.UUIDs[ Response.MobileGroups[i].UUID ] = new StoreHash(state.MobileGroups.lifetime)
			}
			state.MobileGroups.UUIDs[ Response.MobileGroups[i].UUID ].fix(Response.MobileGroups[i])  // Add single MobileGroup to StoreHash
		}
	},
	// mutSaveMobileGroupMember saves a single MobileGroupMember object
	// into both the StoreHash cache and the VueX store
	mutSaveMobileGroupMember(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.MobileGroupMembers.UUIDs)) {
			state.MobileGroupMembers.UUIDs[ obj.UUID ] = new StoreHash(state.MobileGroupMembers.lifetime)
		}
		state.MobileGroupMembers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMobileGroupMemberByUUID removes a MobileGroupMember object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMobileGroupMemberByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.MobileGroupMembers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMobileGroupMembers saves a paginated response of MobileGroupMembers
	mutSaveMobileGroupMembers(state, { Response }) {
		if (!Response || !Array.isArray(Response.MobileGroupMembers)) { return } // Must have Response object with results
		for (let i = 0; i < Response.MobileGroupMembers.length; i++) {
			if (!(Response.MobileGroupMembers[i].UUID in state.MobileGroupMembers.UUIDs)) {
				state.MobileGroupMembers.UUIDs[ Response.MobileGroupMembers[i].UUID ] = new StoreHash(state.MobileGroupMembers.lifetime)
			}
			state.MobileGroupMembers.UUIDs[ Response.MobileGroupMembers[i].UUID ].fix(Response.MobileGroupMembers[i])  // Add single MobileGroupMember to StoreHash
		}
	},
};

const actions = {
	// getCachedMobileGroupByUUID fetches a MobileGroup from the cache
	// and if not in the cache, fetches it from the API
	getCachedMobileGroupByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.MobileGroups.UUIDs[ UUID ] && !state.MobileGroups.UUIDs[ UUID ].expired()) {			
			return state.MobileGroups.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetMobileGroupByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMobileGroup', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMobileGroupByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedMobileGroupMemberByUUID fetches a MobileGroupMember from the cache
	// and if not in the cache, fetches it from the API
	getCachedMobileGroupMemberByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.MobileGroupMembers.UUIDs[ UUID ] && !state.MobileGroupMembers.UUIDs[ UUID ].expired()) {			
			return state.MobileGroupMembers.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetMobileGroupMemberByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMobileGroupMember', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMobileGroupMemberByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: MobileGroupRPC
	// 
	/**
	* RPC Method: AddMobileGroup
	* Description: Add a new mobile group
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/mobile_group
	* @param    {MobileGroup} req The API payload object (MobileGroup)
	* @return   {MobileGroup} The API response object (MobileGroup)
	*
	* @typedef  {Object}    MobileGroup
	* @property {Number}    SPID              CRUD: mobile_group
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    CustomerUUID      The customer that this mobile group belongs to
	* @property {String}    MobileProductUUID UUID of the Mobile product that is the leader of the group
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    CRUD: mobile_group_member
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    GroupCode         
	* @property {ENUM}      Type              The type of the group
	* @property {Number}    MaxGroupSize      Maximum size this group can grow to
	* @property {String}    Name              Name of this mobile group
	*
	*/
	AddMobileGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/mobile_group`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddMobileGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddMobileGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddMobileGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddMobileGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddMobileGroup'))
			})
	},

	/**
	* RPC Method: AddMobileGroupMember
	* Description: Add a new mobile group member
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/mobile_group_member
	* @param    {MobileGroupMember} req The API payload object (MobileGroupMember)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	AddMobileGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/mobile_group_member`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddMobileGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddMobileGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddMobileGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddMobileGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddMobileGroupMember'))
			})
	},

	/**
	* RPC Method: CheckAddMobileGroupMember
	* Description: Check if we can add a new mobile group member
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/mobile_group_member_check
	* @param    {MobileGroupMember} req The API payload object (MobileGroupMember)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	CheckAddMobileGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v2/${req.SPID}/mobile_group_member_check`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('CheckAddMobileGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('CheckAddMobileGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('CheckAddMobileGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('CheckAddMobileGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in CheckAddMobileGroupMember'))
			})
	},

	/**
	* RPC Method: DeleteMobileGroup
	* Description: Delete a mobile group
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/mobile_group/${req.UUID}
	* @param    {DeleteMobileGroupRequest} req The API payload object (DeleteMobileGroupRequest)
	* @return   {MobileGroup} The API response object (MobileGroup)
	*
	* @typedef  {Object}    DeleteMobileGroupRequest
	* @property {Number}    SPID The Service Provider ID
	* @property {String}    UUID UUID of the mobile group
	*
	* @typedef  {Object}    MobileGroup
	* @property {Number}    SPID              CRUD: mobile_group
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    CustomerUUID      The customer that this mobile group belongs to
	* @property {String}    MobileProductUUID UUID of the Mobile product that is the leader of the group
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    CRUD: mobile_group_member
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    GroupCode         
	* @property {ENUM}      Type              The type of the group
	* @property {Number}    MaxGroupSize      Maximum size this group can grow to
	* @property {String}    Name              Name of this mobile group
	*
	*/
	DeleteMobileGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/mobile_group/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteMobileGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteMobileGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteMobileGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteMobileGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteMobileGroup'))
			})
	},

	/**
	* RPC Method: DeleteMobileGroupMember
	* Description: Delete a mobile group member
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/mobile_group_member/${req.UUID}
	* @param    {DeleteMobileGroupMemberRequest} req The API payload object (DeleteMobileGroupMemberRequest)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    DeleteMobileGroupMemberRequest
	* @property {Number}    SPID The Service Provider ID
	* @property {String}    UUID UUID of the mobile group
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	DeleteMobileGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/mobile_group_member/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileGroupMember', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteMobileGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteMobileGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteMobileGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteMobileGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteMobileGroupMember'))
			})
	},

	/**
	* RPC Method: FindMobileGroupMembersPaginated
	* Description: Search through mobile group members
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/mobile_group_member
	* @param    {MobileGroupMemberSearchRequest} req The API payload object (MobileGroupMemberSearchRequest)
	* @return   {MobileGroupMemberSearchResponse} The API response object (MobileGroupMemberSearchResponse)
	*
	* @typedef  {Object}    MobileGroupMemberSearchRequest
	* @property {SearchOptions[]}   SearchOptions     Standard search options
	* @property {String}    UUID              The mobile group
	* @property {Number}    SPID              The Service Provider ID
	* @property {Boolean}   IncludeDeleted    
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member)
	* @property {String}    MobileProductUUID The mobile that is a member of the group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}    MobileGroupMemberSearchResponse
	* @property {PageInfo}   PageInfo           PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {MobileGroupMember[]} MobileGroupMembers Repeated list of mobile groups
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindMobileGroupMembersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/mobile_group_member`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveMobileGroupMembers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobileGroupMembersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobileGroupMembersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobileGroupMembersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobileGroupMembersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobileGroupMembersPaginated'))
			})
	},

	/**
	* RPC Method: FindMobileGroupsPaginated
	* Description: Search through mobile groups
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/mobile_group
	* @param    {MobileGroupSearchRequest} req The API payload object (MobileGroupSearchRequest)
	* @return   {MobileGroupSearchResponse} The API response object (MobileGroupSearchResponse)
	*
	* @typedef  {Object}    MobileGroupSearchRequest
	* @property {SearchOptions[]}   SearchOptions     Standard search options
	* @property {String}    UUID              UUID
	* @property {Number}    SPID              The Service Provider ID
	* @property {Boolean}   IncludeDeleted    
	* @property {String}    GroupCode         
	* @property {String}    CustomerUUID      The customer that this mobile group belongs to
	* @property {String}    MobileProductUUID The UUID of mobile service that is the leader of the group
	* @property {String}    Name              Name of this mobile group
	* @property {ENUM}      Type              The type of the group
	* @property {Number}    MaxGroupSize      Maximum size this group can grow to
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}    MobileGroupSearchResponse
	* @property {PageInfo}   PageInfo     PageInfo only needs the values ThisPageNumber and PageSize set
	* @property {MobileGroup[]} MobileGroups Repeated list of mobile groups
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindMobileGroupsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v2/${paramSPID}/mobile_group`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveMobileGroups', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindMobileGroupsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindMobileGroupsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindMobileGroupsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindMobileGroupsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindMobileGroupsPaginated'))
			})
	},

	/**
	* RPC Method: GetMobileGroupByUUID
	* Description: Get one mobile group by UUID
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/mobile_group/${req.UUID}
	* @param    {MobileGroupUUIDSearch} req The API payload object (MobileGroupUUIDSearch)
	* @return   {MobileGroup} The API response object (MobileGroup)
	*
	* @typedef  {Object}    MobileGroupUUIDSearch
	* @property {Number}    SPID The Service Provider ID
	* @property {String}    UUID UUID of the mobile group
	*
	* @typedef  {Object}    MobileGroup
	* @property {Number}    SPID              CRUD: mobile_group
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    CustomerUUID      The customer that this mobile group belongs to
	* @property {String}    MobileProductUUID UUID of the Mobile product that is the leader of the group
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    CRUD: mobile_group_member
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    GroupCode         
	* @property {ENUM}      Type              The type of the group
	* @property {Number}    MaxGroupSize      Maximum size this group can grow to
	* @property {String}    Name              Name of this mobile group
	*
	*/
	GetMobileGroupByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v2/${paramSPID}/mobile_group/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileGroupByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileGroupByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileGroupByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileGroupByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileGroupByUUID'))
			})
	},

	/**
	* RPC Method: GetMobileGroupInfo
	* Description: Get all mobile group memberships for a given Customer
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/mobile_group_info/${req.CustomerUUID}
	* @param    {MobileGroupInfoRequest} req The API payload object (MobileGroupInfoRequest)
	* @return   {MobileGroupInfoResponse} The API response object (MobileGroupInfoResponse)
	*
	* @typedef  {Object}    MobileGroupInfoRequest
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    CustomerUUID      The customer (member)
	* @property {String}    MobileProductUUID The mobile product UUID to get info for
	*
	* @typedef  {Object}    MobileGroupInfoResponse
	* @property {MobileGroupInfo[]} MobileGroupInfos Repeated list of mobile groups
	*
	*/
	GetMobileGroupInfo({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		return axios.get(`/v2/${paramSPID}/mobile_group_info/${paramCustomerUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileGroupInfo ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileGroupInfo ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileGroupInfo ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileGroupInfo UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileGroupInfo'))
			})
	},

	/**
	* RPC Method: GetMobileGroupMemberByUUID
	* Description: Get one mobile group member by UUID
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/mobile_group_member/${req.UUID}
	* @param    {MobileGroupMemberUUIDSearch} req The API payload object (MobileGroupMemberUUIDSearch)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    MobileGroupMemberUUIDSearch
	* @property {Number}    SPID The Service Provider ID
	* @property {String}    UUID UUID of the mobile group
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	GetMobileGroupMemberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v2/${paramSPID}/mobile_group_member/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileGroupMember', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileGroupMemberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileGroupMemberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileGroupMemberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileGroupMemberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileGroupMemberByUUID'))
			})
	},

	/**
	* RPC Method: LeaveMobileGroup
	* Description: Leave a mobile group
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/mobile_group_leave/${req.CustomerUUID}/${req.MobileGroupUUID}
	* @param    {CustomerLeaveGroupRequest} req The API payload object (CustomerLeaveGroupRequest)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    CustomerLeaveGroupRequest
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    CustomerUUID      UUID of the customer to be removed from the group
	* @property {String}    MobileProductUUID UUID of the mobile product to be removed from the group
	* @property {String}    MobileGroupUUID   UUID of the mobile group that the customer will be removed from
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	LeaveMobileGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v2/${req.SPID}/mobile_group_leave/${req.CustomerUUID}/${req.MobileGroupUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('LeaveMobileGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('LeaveMobileGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('LeaveMobileGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('LeaveMobileGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in LeaveMobileGroup'))
			})
	},

	/**
	* RPC Method: RemoveOtherMobileFromGroup
	* Description: Delete another customer from the group
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/mobile_group_remove_other/${req.CustomerUUID}/${req.MobileGroupUUID}/${req.OtherCustomerUUID}
	* @param    {RemoveOtherMobileFromGroupRequest} req The API payload object (RemoveOtherMobileFromGroupRequest)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    RemoveOtherMobileFromGroupRequest
	* @property {Number}    SPID                   The Service Provider ID
	* @property {String}    CustomerUUID           CustomerUUID of the customer sending the request
	* @property {String}    OtherCustomerUUID      UUID of the customer to be removed from the group
	* @property {String}    OtherMobileProductUUID UUID of the mobile product to be removed from the group
	* @property {String}    MobileGroupUUID        UUID of the mobile group that the customer will be removed from
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	RemoveOtherMobileFromGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v2/${req.SPID}/mobile_group_remove_other/${req.CustomerUUID}/${req.MobileGroupUUID}/${req.OtherCustomerUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('RemoveOtherMobileFromGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('RemoveOtherMobileFromGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('RemoveOtherMobileFromGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('RemoveOtherMobileFromGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in RemoveOtherMobileFromGroup'))
			})
	},

	/**
	* RPC Method: UpdateMobileGroup
	* Description: Update a mobile group
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/mobile_group/${req.UUID}
	* @param    {MobileGroup} req The API payload object (MobileGroup)
	* @return   {MobileGroup} The API response object (MobileGroup)
	*
	* @typedef  {Object}    MobileGroup
	* @property {Number}    SPID              CRUD: mobile_group
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    CustomerUUID      The customer that this mobile group belongs to
	* @property {String}    MobileProductUUID UUID of the Mobile product that is the leader of the group
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    CRUD: mobile_group_member
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    GroupCode         
	* @property {ENUM}      Type              The type of the group
	* @property {Number}    MaxGroupSize      Maximum size this group can grow to
	* @property {String}    Name              Name of this mobile group
	*
	*/
	UpdateMobileGroup({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/mobile_group/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileGroup', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateMobileGroup ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateMobileGroup ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateMobileGroup ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateMobileGroup UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateMobileGroup'))
			})
	},

	/**
	* RPC Method: UpdateMobileGroupMember
	* Description: Update a mobile group member
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/mobile_group_member/${req.UUID}
	* @param    {MobileGroupMember} req The API payload object (MobileGroupMember)
	* @return   {MobileGroupMember} The API response object (MobileGroupMember)
	*
	* @typedef  {Object}    MobileGroupMember
	* @property {Number}    SPID              The Service Provider ID
	* @property {String}    UUID              The unique index for this mobile group
	* @property {String}    MobileGroupUUID   The mobile group
	* @property {String}    CustomerUUID      The customer (member) that owns the MobileProductUUID
	* @property {String}    MobileProductUUID The UUID of the mobile product that is a member
	* @property {Number}    CreatedAtNanos    Unixnano when this mobile group was created,  NB: When updating a mobile group, the old one is,  deleted and a new one is created
	* @property {Number}    DeletedAtNanos    Unixnano when this mobile group was deleted
	* @property {String}    UpdatedBy         Who updated the mobile group
	* @property {String}    FriendlyName      FriendlyName of this mobile group
	* @property {ENUM}      Type              The type of the group
	*
	*/
	UpdateMobileGroupMember({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/mobile_group_member/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileGroupMember', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateMobileGroupMember ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateMobileGroupMember ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateMobileGroupMember ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateMobileGroupMember UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateMobileGroupMember'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};