export function objectType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
}

export function isString(obj) {
    return objectType(obj) === 'string';
}

export function isNumber(obj) {
    return objectType(obj) === 'number';
}

export function isBoolean(obj) {
    return objectType(obj) === 'boolean';
}

export function isNull(obj) {
    return objectType(obj) === 'null';
}

export function isUndefined(obj) {
    return objectType(obj) === 'undefined';
}

export function isArray(obj) {
    return objectType(obj) === 'array';
}

export function isObject(obj) {
    return objectType(obj) === 'object';
}

export function isSymbol(obj) {
    return objectType(obj) === 'symbol';
}
