import { db } from '@/utils/firebase.js';
import axios from 'axios';

import i18n from '@/i18n'

// _old_
// const COLLECTION_ACCOUNTS = 'users';
const DOC_USERS = '/access/FIREBASE/users';

export default {
  state: {},

  getters: {
    // collection_users_name: (state) => COLLECTION_ACCOUNTS,
	doc_users_name: (state) => DOC_USERS,
  },

  actions: {
    /* _old_
	CreateAccount({}, { user_account, uid }) {
      if (uid) {
        return db
          .collection(COLLECTION_ACCOUNTS)
          .doc(uid)
          .set(user_account)
          .then(() => {
            console.info('Document successfully written!');
            Promise.resolve();
          })
          .catch((error) => {
            console.error('Error writing document: ', error);
            Promise.reject();
          });
      } else {
        return db.collection(COLLECTION_ACCOUNTS).add(user_account);
      }
    }, 
	*/

	CreateAccount({}, { user_data, uid }) {
		if (uid) {
		  return db
		    .doc(`${DOC_USERS}/${uid}`)
			.set(user_data)
			.then(() => {
			  console.info('Document successfully written!');
			  return Promise.resolve();
			})
			.catch((error) => {
			  console.error('Error writing document: ', error);
			  return Promise.reject(error);
			});
		} else {
		//   return db.doc(`${DOC_USERS}/${uid}`).add(user_data);
			return Promise.reject(i18n.t('no_uid'));
		}
	},
  
  DeleteAccount({}, uid) {
		if (uid) {
		  return db
		    .doc(`${DOC_USERS}/${uid}`)
        .delete()
		}
	},

  UpdateFirebaseAccess({}, { data } = {}) {
    // console.log('UpdateFirebaseAccess')
    console.log('UpdateFirebaseAccess', data)
    let promises = []
    if (data) {
        let custuuids = []
        if (data.AdoptionRequest && data.AdoptionRequest.CustomerUUID) {
            custuuids.push(data.AdoptionRequest.CustomerUUID)
        } else if (data.AdoptionMetadataEntry && data.AdoptionMetadataEntry.CustomerUUID) {
            custuuids.push(data.AdoptionMetadataEntry.CustomerUUID)
        } else if (data.AuthResponse && data.AuthResponse.Roles) {
            // loop through data.AuthResponse.Roles
            for (var i = 0; i < data.AuthResponse.Roles.length; i++) {
                if (data.AuthResponse.Roles[i].CustomerUUID && data.AuthResponse.Roles[i].CustomerUUID != '') {
                   custuuids.push(data.AuthResponse.Roles[i].CustomerUUID)
                } 
            }
            console.log('custuuids', custuuids)
        }

        // loop through all custuuids
        let uniqueUUIDs = Array.from(new Set(custuuids));
        // console.log('uniqueUUIDs', uniqueUUIDs)

        if (uniqueUUIDs.length == 0) {
           return Promise.resolve(data)
        }
        const apiPrefix = process.env.VUE_APP_RESELLER_API_HOST
        for (var i = 0; i < uniqueUUIDs.length; i++) {
            promises.push(axios.post(`${apiPrefix}/customers/`+uniqueUUIDs[i]+`/auth_roles`, data, {
            }))                            
        }

        return Promise.all(promises)
          .then(updateresp => {
              return Promise.resolve(data)
          })
    }
    
    return Promise.resolve({})
  },

    /* _old_
	UpdateAccountByUid({}, { payload, uid }) {
      return db
        .collection(COLLECTION_ACCOUNTS)
        .doc(uid)
        .update(payload);
    }, 
	*/

    /* _old_
	GetAccountByUid({}, uid) {
      if (!uid) {
        return Promise.reject('no user ID');
      } else {
        // TODO customers data COLLECTION_CUSTOMERS
        return db
          .collection(COLLECTION_ACCOUNTS)
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              return Promise.resolve(doc.data());
            } else {
              // doc.data() will be undefined in this case
              console.info('No such document!');

              return Promise.resolve(null);
            }
          })
          .catch((error) => {
            console.error('Error getting document:', error);
            return Promise.reject(error);
          });
      }
    }, 
	*/

	GetAccountByUid({}, uid) {
		if (!uid) {
		  return Promise.reject(i18n.t('no_user_id'));
		} else {
		  return db
		    .doc(`${DOC_USERS}/${uid}`)
            .get()
			.then((doc) => {
			  if (doc.exists) {
				return Promise.resolve(doc.data());
			  } else {
				// doc.data() will be undefined in this case
				console.info('No such document!');
  
				return Promise.resolve(null);
			  }
			})
			.catch((error) => {
			  console.error('Error getting document:', error);
			  return Promise.reject(error);
			});
		}
	}, 

    /* _old_
	DeleteAccountByUid({}, uid) {
      return db
        .collection(COLLECTION_ACCOUNTS)
        .doc(uid)
        .delete();
    }, 
	*/
  },
  mutations: {},
};
