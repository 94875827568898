const appStyle = window.getComputedStyle(document.querySelector('#app'))
const tablet_size = +appStyle.getPropertyValue('--tablet-size').trim().slice(0, -2)
const mobile_size = +appStyle.getPropertyValue('--mobile-size').trim().slice(0, -2)

const state = {
    viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    breakpoints: {
        phone: mobile_size,
        tablet: tablet_size,
    },
};

const actions = {
	setViewport({ commit }) {
		commit('SET_VIEWPORT');
	},
};

const mutations = {
    SET_VIEWPORT(state) {
        state.viewport.width = window.innerWidth;
        state.viewport.height = window.innerHeight;
    },
};

const getters = {
    is_mobile: (state, getters) => state.viewport.width <= state.breakpoints.phone,
    is_tablet: state => (state.viewport.width > state.breakpoints.phone) && (state.viewport.width <= state.breakpoints.tablet),
    is_desktop: state => state.viewport.width > state.breakpoints.tablet,
};

export default {
    state,
    getters,
    mutations,
	actions,
};
