import Vue from 'vue'
import App from './App.vue'

import axiosMiddleware from '@/middlewares/axios-middleware'
import axiosHelpCenterMiddleware from '@/middlewares/axios-zendesk-middleware'
import axiosIntentsMiddleware from '@/middlewares/axios-intents-middleware'

import axios from 'axios'
import axios_zendesk from './utils/axios-zendesk'
import axios_intents from './utils/axios-intents'

import { analytics } from './utils/firebase'

import router from './router'
import store from './store'

import PortalVue  from 'portal-vue'

import * as Sentry from "@sentry/vue";

import styles from './assets/scss/main.scss'
import i18n from './i18n'

// directives
import './directives/click-outside'
import './directives/tooltip'

Vue.use(PortalVue)

Vue.use(axiosMiddleware, axios, store, router)
Vue.prototype.$axios = axios

Vue.use(axiosHelpCenterMiddleware, axios_zendesk, store, router)
Vue.prototype.$axios_zendesk = axios_zendesk

Vue.use(axiosIntentsMiddleware, axios_intents, store, router)
Vue.prototype.$axios_intents = axios_intents

Vue.prototype.$analytics = analytics;

const sentryInit = {
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    tracePropagationTargets: [...process.env.VUE_APP_SENTRY_TRACING_ORIGINS.split(','), /^\//],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: +process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
}

if (process.env.VUE_APP_HAS_SENTRY_SESSION_REPLAY == 'true') {
    sentryInit.integrations.push(new Sentry.Replay())

    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    sentryInit.replaysSessionSampleRate = 0.05
    sentryInit.replaysOnErrorSampleRate = 1.0
}

Sentry.init(sentryInit);

const THEME = process.env.VUE_APP_THEME

store.dispatch('scanImages')
// store.dispatch('fillProductsAllTypes')

const load = async () => {
    
    const fonts = await import(/* webpackChunkName: "Fonts" */ '@theme/fonts.scss')

    Vue.config.productionTip = false

    // store.dispatch('AutoLogin').then(() => {
    store.dispatch('FirebaseAutoLogin').then(() => {
        new Vue({
            router,
            store,
            fonts,
            styles,
            THEME,
            i18n,
            render: h => h(App)
        }).$mount('#app')
    })
    // .catch(() => {
    //     console.log('AutoLogin promise error')
    // })
    .finally(() => {
      store.dispatch('eshopCartLoad_v2').catch(() => {})
    })
}

load()
