// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: ufbprod.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const JWT = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpIjp7InMiOjEsInAiOjQsImkiOiIxOTQyYmQwNS00MzFiLTQwNzEtOWIwNi0xYjUxM2QyMjFlODAiLCJ4IjowLCJmbiI6IlN1cGVyIiwibG4iOiJVc2VyIiwiZSI6InN1cGVyYWRtaW5AaXFoaXZlLmNvbSIsImV2IjpmYWxzZSwibSI6Iis2NDIxMDAwMDIyIiwibXYiOmZhbHNlfSwiciI6W3sicyI6MSwicm4iOiJTVVBFUl9BRE1JTiJ9XSwiaWF0IjoxNjUzNTY0MTU1LCJleHAiOjE2NTM1OTI5NTV9.dFLSnKKVKNfDuzS9SFHYyDMcBfaAKmHeqDydxbcZtWU'

const initialState = {
	LFCProducts: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	UFBPlans: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getLFCProductByUUID: state => (UUID) => (state.LFCProducts.UUIDs[ UUID ] && !state.LFCProducts.UUIDs[ UUID ].expired()) ?  state.LFCProducts.UUIDs[ UUID ].hashedData() : null,
	getUFBPlanByUUID: state => (UUID) => (state.UFBPlans.UUIDs[ UUID ] && !state.UFBPlans.UUIDs[ UUID ].expired()) ?  state.UFBPlans.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveLFCProduct saves a single LFCProduct object
	// into both the StoreHash cache and the VueX store
	mutSaveLFCProduct(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.LFCProducts.UUIDs)) {
			state.LFCProducts.UUIDs[ obj.UUID ] = new StoreHash(state.LFCProducts.lifetime)
		}
		state.LFCProducts.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveLFCProductByUUID removes a LFCProduct object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveLFCProductByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.LFCProducts.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveLFCProducts saves a paginated response of LFCProducts
	mutSaveLFCProducts(state, { Response }) {
		if (!Response || !Array.isArray(Response.LFCProducts)) { return } // Must have Response object with results
		for (let i = 0; i < Response.LFCProducts.length; i++) {
			if (!(Response.LFCProducts[i].UUID in state.LFCProducts.UUIDs)) {
				state.LFCProducts.UUIDs[ Response.LFCProducts[i].UUID ] = new StoreHash(state.LFCProducts.lifetime)
			}
			state.LFCProducts.UUIDs[ Response.LFCProducts[i].UUID ].fix(Response.LFCProducts[i])  // Add single LFCProduct to StoreHash
		}
	},
	// mutSaveUFBPlan saves a single UFBPlan object
	// into both the StoreHash cache and the VueX store
	mutSaveUFBPlan(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.UFBPlans.UUIDs)) {
			state.UFBPlans.UUIDs[ obj.UUID ] = new StoreHash(state.UFBPlans.lifetime)
		}
		state.UFBPlans.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveUFBPlanByUUID removes a UFBPlan object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveUFBPlanByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.UFBPlans.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveUFBPlans saves a paginated response of UFBPlans
	mutSaveUFBPlans(state, { Response }) {
		if (!Response || !Array.isArray(Response.UFBPlans)) { return } // Must have Response object with results
		for (let i = 0; i < Response.UFBPlans.length; i++) {
			if (!(Response.UFBPlans[i].UUID in state.UFBPlans.UUIDs)) {
				state.UFBPlans.UUIDs[ Response.UFBPlans[i].UUID ] = new StoreHash(state.UFBPlans.lifetime)
			}
			state.UFBPlans.UUIDs[ Response.UFBPlans[i].UUID ].fix(Response.UFBPlans[i])  // Add single UFBPlan to StoreHash
		}
	},
};

const actions = {
	// getCachedLFCProductByUUID fetches a LFCProduct from the cache
	// and if not in the cache, fetches it from the API
	getCachedLFCProductByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.LFCProducts.UUIDs[ UUID ] && !state.LFCProducts.UUIDs[ UUID ].expired()) {
			return state.LFCProducts.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetLFCProductByUUID', { UUID }).then(respPayload => {
			commit('mutSaveLFCProduct', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveLFCProductByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedUFBPlanByUUID fetches a UFBPlan from the cache
	// and if not in the cache, fetches it from the API
	getCachedUFBPlanByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.UFBPlans.UUIDs[ UUID ] && !state.UFBPlans.UUIDs[ UUID ].expired()) {
			return state.UFBPlans.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetUFBPlanByUUID', { UUID }).then(respPayload => {
			commit('mutSaveUFBPlan', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveUFBPlanByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: UFBProductRPC
	//
	/**
	* RPC Method: AddLFCProduct
	* Description: Add a LFCProduct object
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/lfc_product
	* @param    {LFCProduct} req The API payload object (LFCProduct)
	* @return   {LFCProduct} The API response object (LFCProduct)
	*
	* @typedef  {Object}  LFCProduct
	* @property {String}  UUID                    UUID is the unique ID for this plan
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {ENUM}    LFC                     LFC is the Local Fiber Company
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {String}  Name                    Human readable name of the plan
	* @property {String}  LFCPlanName             LFC PlanName for the plan
	* @property {String}  LFCProductOfferID       LFC ProductOfferID for the plan
	* @property {String}  LFCProductOfferSpecID   LFC ProductOfferSpecID for the plan
	* @property {String}  LFCProductOfferCompatID LFC ProductOfferCompatID for the plan
	* @property {String}  LFCProfileID            LFC ProfileID for the plan
	* @property {Boolean} PrimaryOffer            Is the offer a primary offer
	* @property {String}  MarketSegment           Market segment for the plan
	* @property {String}  UNITagMode              Is the service tagged?
	* @property {String}  CircuitIDInsertion      Does the service have circuit ID insertion?
	*
	*/
	AddLFCProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/${req.SPID}/lfc_product`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddLFCProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddLFCProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddLFCProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddLFCProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddLFCProduct'))
			})
	},

	/**
	* RPC Method: AddUFBOrder
	* Description: Order a UFB Service
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/UFB_order
	* @param    {UFBProduct} req The API payload object (UFBProduct)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	AddUFBOrder({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/UFB_order`, req, {
			headers: {
				'x-auth-token': JWT,
			},
		}).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddUFBOrder ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddUFBOrder ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddUFBOrder ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddUFBOrder UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddUFBOrder'))
			})
	},

	/**
	* RPC Method: AddUFBOrderNote
	* Description: Adds a note to a UFB Order
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/ufb_order/${req.UUID}/note
	* @param    {UFBNote} req The API payload object (UFBNote)
	* @return   {UFBNote} The API response object (UFBNote)
	*
	* @typedef  {Object}  UFBNote
	* @property {Number}  SPID
	* @property {String}  UUID
	* @property {String}  ProductInstanceUUID
	* @property {String}  ProductOrderUUID
	* @property {Number}  TimestampNanos
	* @property {String}  AddedBy
	* @property {String}  NoteText
	*
	*/
	AddUFBOrderNote({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/ufb_order/${req.UUID}/note`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddUFBOrderNote ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddUFBOrderNote ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddUFBOrderNote ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddUFBOrderNote UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddUFBOrderNote'))
			})
	},

	/**
	* RPC Method: AddUFBProductNote
	* Description: Adds a note to a UDB Product
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/ufb/${req.UUID}/note
	* @param    {UFBNote} req The API payload object (UFBNote)
	* @return   {UFBNote} The API response object (UFBNote)
	*
	* @typedef  {Object}  UFBNote
	* @property {Number}  SPID
	* @property {String}  UUID
	* @property {String}  ProductInstanceUUID
	* @property {String}  ProductOrderUUID
	* @property {Number}  TimestampNanos
	* @property {String}  AddedBy
	* @property {String}  NoteText
	*
	*/
	AddUFBProductNote({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/ufb/${req.UUID}/note`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddUFBProductNote ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddUFBProductNote ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddUFBProductNote ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddUFBProductNote UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddUFBProductNote'))
			})
	},

	/**
	* RPC Method: BookAppointment
	* Description: Query available times for a visit/service
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/UFB_order/${req.UUID}/book
	* @param    {BookAppointmentRequest} req The API payload object (BookAppointmentRequest)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  BookAppointmentRequest
	* @property {Number}  SPID
	* @property {String}  UUID
	* @property {ENUM}    Mode
	* @property {Number}  StartTimeNanos
	* @property {Number}  EndTimeNanos
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	BookAppointment({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/UFB_order/${req.UUID}/book`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('BookAppointment ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('BookAppointment ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('BookAppointment ERROR:', error)
					return Promise.reject(error)
				}
				console.log('BookAppointment UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in BookAppointment'))
			})
	},

	/**
	* RPC Method: DeleteLFCProductByUUID
	* Description: Deletes a LFCProduct object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/lfc_product/${req.UUID}
	* @param    {LFCProductUUIDSearch} req The API payload object (LFCProductUUIDSearch)
	* @return   {LFCProduct} The API response object (LFCProduct)
	*
	* @typedef  {Object}  LFCProductUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  LFCProduct
	* @property {String}  UUID                    UUID is the unique ID for this plan
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {ENUM}    LFC                     LFC is the Local Fiber Company
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {String}  Name                    Human readable name of the plan
	* @property {String}  LFCPlanName             LFC PlanName for the plan
	* @property {String}  LFCProductOfferID       LFC ProductOfferID for the plan
	* @property {String}  LFCProductOfferSpecID   LFC ProductOfferSpecID for the plan
	* @property {String}  LFCProductOfferCompatID LFC ProductOfferCompatID for the plan
	* @property {String}  LFCProfileID            LFC ProfileID for the plan
	* @property {Boolean} PrimaryOffer            Is the offer a primary offer
	* @property {String}  MarketSegment           Market segment for the plan
	* @property {String}  UNITagMode              Is the service tagged?
	* @property {String}  CircuitIDInsertion      Does the service have circuit ID insertion?
	*
	*/
	DeleteLFCProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/${req.SPID}/lfc_product/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveLFCProduct', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteLFCProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteLFCProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteLFCProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteLFCProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteLFCProductByUUID'))
			})
	},

	/**
	* RPC Method: DeleteUFBOrderByUUID
	* Description: Cancels a UFB order
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/UFB_order/${req.UUID}
	* @param    {UFBProductUUIDSearch} req The API payload object (UFBProductUUIDSearch)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProductUUIDSearch
	* @property {String}  UUID Unknown type
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	DeleteUFBOrderByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v3/${req.SPID}/UFB_order/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteUFBOrderByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteUFBOrderByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteUFBOrderByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteUFBOrderByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteUFBOrderByUUID'))
			})
	},

	/**
	* RPC Method: DeleteUFBProductByUUID
	* Description: Terminates a UFB product
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/UFB/${req.UUID}
	* @param    {DeleteUFBProductByUUIDRequest} req The API payload object (DeleteUFBProductByUUIDRequest)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  DeleteUFBProductByUUIDRequest
	* @property {String}  UUID
	* @property {Number}  SPID                Service Provider ID - always required
	* @property {Number}  TerminateAtNanos
	* @property {String}  LocationOfEquipment
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	DeleteUFBProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v3/${req.SPID}/UFB/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteUFBProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteUFBProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteUFBProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteUFBProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteUFBProductByUUID'))
			})
	},

	/**
	* RPC Method: FindLFCProductsPaginated
	* Description: Searches for multiple LFCProduct objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/lfc_product
	* @param    {LFCProductSearchRequest} req The API payload object (LFCProductSearchRequest)
	* @return   {LFCProductPageResponse} The API response object (LFCProductPageResponse)
	*
	* @typedef  {Object}  LFCProductSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           SPID
	* @property {String}  Name           (optional) Name
	* @property {ENUM}    LFC            (optional) LFC
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  LFCProductPageResponse
	* @property {PageInfo} PageInfo    PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {LFCProduct[]} LFCProducts
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindLFCProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/lfc_product`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveLFCProducts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindLFCProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindLFCProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindLFCProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindLFCProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindLFCProductsPaginated'))
			})
	},

	/**
	* RPC Method: GetLFCProductByUUID
	* Description: Get a single LFCProduct object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/lfc_product/${req.UUID}
	* @param    {LFCProductUUIDSearch} req The API payload object (LFCProductUUIDSearch)
	* @return   {LFCProduct} The API response object (LFCProduct)
	*
	* @typedef  {Object}  LFCProductUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  LFCProduct
	* @property {String}  UUID                    UUID is the unique ID for this plan
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {ENUM}    LFC                     LFC is the Local Fiber Company
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {String}  Name                    Human readable name of the plan
	* @property {String}  LFCPlanName             LFC PlanName for the plan
	* @property {String}  LFCProductOfferID       LFC ProductOfferID for the plan
	* @property {String}  LFCProductOfferSpecID   LFC ProductOfferSpecID for the plan
	* @property {String}  LFCProductOfferCompatID LFC ProductOfferCompatID for the plan
	* @property {String}  LFCProfileID            LFC ProfileID for the plan
	* @property {Boolean} PrimaryOffer            Is the offer a primary offer
	* @property {String}  MarketSegment           Market segment for the plan
	* @property {String}  UNITagMode              Is the service tagged?
	* @property {String}  CircuitIDInsertion      Does the service have circuit ID insertion?
	*
	*/
	GetLFCProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/lfc_product/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveLFCProduct', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetLFCProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetLFCProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetLFCProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetLFCProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetLFCProductByUUID'))
			})
	},

	/**
	* RPC Method: GetPossibleTimes
	* Description: Query available times for a visit/service
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/UFB_order/${req.UUID}/get_available_times
	* @param    {QueryTimesMessageRequest} req The API payload object (QueryTimesMessageRequest)
	* @return   {QueryTimesMessageResponse} The API response object (QueryTimesMessageResponse)
	*
	* @typedef  {Object}  QueryTimesMessageRequest
	* @property {Number}  SPID
	* @property {String}  UUID
	* @property {ENUM}    Mode
	*
	* @typedef  {Object}  QueryTimesMessageResponse
	* @property {Number}  SPID
	* @property {String}  UUID
	* @property {QueryTimesStartEnd[]} StartEnd
	*
	*/
	GetPossibleTimes({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.post(`/v3/${req.SPID}/UFB_order/${req.UUID}/get_available_times`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPossibleTimes ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPossibleTimes ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPossibleTimes ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPossibleTimes UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPossibleTimes'))
			})
	},

	/**
	* RPC Method: GetUFBOrderByUUID
	* Description: Get a single UFB order object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/UFB_order/${req.UUID}
	* @param    {UFBProductUUIDSearch} req The API payload object (UFBProductUUIDSearch)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProductUUIDSearch
	* @property {String}  UUID Unknown type
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	GetUFBOrderByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/UFB_order/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBOrderByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBOrderByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBOrderByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBOrderByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBOrderByUUID'))
			})
	},

	/**
	* RPC Method: GetUFBOrderNotes
	* Description: Gets notes from a UFB Order
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ufb_order/${req.UUID}/note
	* @param    {GetUFBNotesByOrderUUID} req The API payload object (GetUFBNotesByOrderUUID)
	* @return   {UFBNotes} The API response object (UFBNotes)
	*
	* @typedef  {Object}  GetUFBNotesByOrderUUID
	* @property {SearchOptions[]} SearchOptions
	* @property {Number}  SPID
	* @property {String}  UUID
	*
	* @typedef  {Object}  UFBNotes
	* @property {PageInfo} PageInfo
	* @property {String}  UUID
	* @property {UFBNote[]} Notes
	*
	*/
	GetUFBOrderNotes({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/ufb_order/${paramUUID}/note`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBOrderNotes ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBOrderNotes ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBOrderNotes ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBOrderNotes UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBOrderNotes'))
			})
	},

	/**
	* RPC Method: GetUFBOrdersPaginated
	* Description: Searches for multiple UFB orders using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/UFB_order
	* @param    {UFBProductSearchRequest} req The API payload object (UFBProductSearchRequest)
	* @return   {UFBOrderPageResponse} The API response object (UFBOrderPageResponse)
	*
	* @typedef  {Object}  UFBProductSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  CustomerUUID   (optional) Description
	*
	* @typedef  {Object}  UFBOrderPageResponse
	* @property {PageInfo} PageInfo  Pagination information
	* @property {UFBProduct[]} UFBOrders The list of matching plans
	*
	*/
	GetUFBOrdersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/UFB_order`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBOrdersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBOrdersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBOrdersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBOrdersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBOrdersPaginated'))
			})
	},

	/**
	* RPC Method: GetUFBPlans
	* Description: Search available UFB plans
	* HTTP Method: POST
	* API Path: /api/v3/${req.SPID}/UFB_plan
	* @param    {UFBPlanSearchRequest} req The API payload object (UFBPlanSearchRequest)
	* @return   {UFBPlanPageResponse} The API response object (UFBPlanPageResponse)
	*
	* @typedef  {Object}  UFBPlanSearchRequest
	* @property {SearchOptions[]} SearchOptions           Search options to use (for sorting and/or pagination)
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {String}  ServiceAvailabilityUUID ServiceAvailabilityUUID is required unless GetAllPlans is set
	* @property {Boolean} GetAllPlans             If GetAllPlans is set, all (stored) plans are returned, and no prequalification checks are performed
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  UFBPlanPageResponse
	* @property {PageInfo} PageInfo Pagination information
	* @property {UFBPlan[]} UFBPlans The list of matching plans
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetUFBPlans({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.post(`/v3/${req.SPID}/UFB_plan`, req, {
			headers: {
				'x-auth-token': JWT,
			},
		}).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveUFBPlans', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBPlans ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBPlans ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBPlans ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBPlans UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBPlans'))
			})
	},

	/**
	* RPC Method: GetUFBProductByUUID
	* Description: Get a single UFBProduct object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/UFB/${req.UUID}
	* @param    {UFBProductUUIDSearch} req The API payload object (UFBProductUUIDSearch)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProductUUIDSearch
	* @property {String}  UUID Unknown type
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	GetUFBProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/UFB/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBProductByUUID'))
			})
	},

	/**
	* RPC Method: GetUFBProductNotes
	* Description: Gets notes from a UFB Product
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/ufb/${req.UUID}/note
	* @param    {GetUFBNotesByProductUUID} req The API payload object (GetUFBNotesByProductUUID)
	* @return   {UFBNotes} The API response object (UFBNotes)
	*
	* @typedef  {Object}  GetUFBNotesByProductUUID
	* @property {SearchOptions[]} SearchOptions
	* @property {Number}  SPID
	* @property {String}  UUID
	*
	* @typedef  {Object}  UFBNotes
	* @property {PageInfo} PageInfo
	* @property {String}  UUID
	* @property {UFBNote[]} Notes
	*
	*/
	GetUFBProductNotes({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/ufb/${paramUUID}/note`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBProductNotes ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBProductNotes ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBProductNotes ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBProductNotes UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBProductNotes'))
			})
	},

	/**
	* RPC Method: GetUFBProductsPaginated
	* Description: Searches for multiple UFBProducts using a search request
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/UFB
	* @param    {UFBProductSearchRequest} req The API payload object (UFBProductSearchRequest)
	* @return   {UFBProductPageResponse} The API response object (UFBProductPageResponse)
	*
	* @typedef  {Object}  UFBProductSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  CustomerUUID   (optional) Description
	*
	* @typedef  {Object}  UFBProductPageResponse
	* @property {PageInfo} PageInfo    Pagination information
	* @property {UFBProduct[]} UFBProducts The list of matching plans
	*
	*/
	GetUFBProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/UFB`, { params: req,
			headers: {
				'x-auth-token': JWT,
			},
			}).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetUFBProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetUFBProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetUFBProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetUFBProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetUFBProductsPaginated'))
			})
	},

	/**
	* RPC Method: PurgeLFCProductByUUID
	* Description: Purges a LFCProduct object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/lfc_product/${req.UUID}/purge
	* @param    {LFCProductUUIDSearch} req The API payload object (LFCProductUUIDSearch)
	* @return   {LFCProduct} The API response object (LFCProduct)
	*
	* @typedef  {Object}  LFCProductUUIDSearch
	* @property {String}  UUID
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  LFCProduct
	* @property {String}  UUID                    UUID is the unique ID for this plan
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {ENUM}    LFC                     LFC is the Local Fiber Company
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {String}  Name                    Human readable name of the plan
	* @property {String}  LFCPlanName             LFC PlanName for the plan
	* @property {String}  LFCProductOfferID       LFC ProductOfferID for the plan
	* @property {String}  LFCProductOfferSpecID   LFC ProductOfferSpecID for the plan
	* @property {String}  LFCProductOfferCompatID LFC ProductOfferCompatID for the plan
	* @property {String}  LFCProfileID            LFC ProfileID for the plan
	* @property {Boolean} PrimaryOffer            Is the offer a primary offer
	* @property {String}  MarketSegment           Market segment for the plan
	* @property {String}  UNITagMode              Is the service tagged?
	* @property {String}  CircuitIDInsertion      Does the service have circuit ID insertion?
	*
	*/
	PurgeLFCProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/${req.SPID}/lfc_product/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveLFCProduct', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeLFCProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeLFCProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeLFCProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeLFCProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeLFCProductByUUID'))
			})
	},

	/**
	* RPC Method: PurgeUFBOrderByUUID
	* Description: Purges a UFB Order
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/UFB_order/${req.UUID}/purge
	* @param    {UFBProductUUIDSearch} req The API payload object (UFBProductUUIDSearch)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProductUUIDSearch
	* @property {String}  UUID Unknown type
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	PurgeUFBOrderByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v3/${req.SPID}/UFB_order/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeUFBOrderByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeUFBOrderByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeUFBOrderByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeUFBOrderByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeUFBOrderByUUID'))
			})
	},

	/**
	* RPC Method: PurgeUFBProductByUUID
	* Description: Purges a UFB Product
	* HTTP Method: DELETE
	* API Path: /api/v3/${req.SPID}/UFB/${req.UUID}/purge
	* @param    {UFBProductUUIDSearch} req The API payload object (UFBProductUUIDSearch)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProductUUIDSearch
	* @property {String}  UUID Unknown type
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	PurgeUFBProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.delete(`/v3/${req.SPID}/UFB/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeUFBProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeUFBProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeUFBProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeUFBProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeUFBProductByUUID'))
			})
	},

	/**
	* RPC Method: UpdateLFCProduct
	* Description: Update a single LFCProduct object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/lfc_product/${req.UUID}
	* @param    {LFCProduct} req The API payload object (LFCProduct)
	* @return   {LFCProduct} The API response object (LFCProduct)
	*
	* @typedef  {Object}  LFCProduct
	* @property {String}  UUID                    UUID is the unique ID for this plan
	* @property {Number}  SPID                    SPID is the service provider ID that the product belongs to
	* @property {ENUM}    LFC                     LFC is the Local Fiber Company
	* @property {Number}  Cents                   Cents is the cost of this product in cents
	* @property {String}  Name                    Human readable name of the plan
	* @property {String}  LFCPlanName             LFC PlanName for the plan
	* @property {String}  LFCProductOfferID       LFC ProductOfferID for the plan
	* @property {String}  LFCProductOfferSpecID   LFC ProductOfferSpecID for the plan
	* @property {String}  LFCProductOfferCompatID LFC ProductOfferCompatID for the plan
	* @property {String}  LFCProfileID            LFC ProfileID for the plan
	* @property {Boolean} PrimaryOffer            Is the offer a primary offer
	* @property {String}  MarketSegment           Market segment for the plan
	* @property {String}  UNITagMode              Is the service tagged?
	* @property {String}  CircuitIDInsertion      Does the service have circuit ID insertion?
	*
	*/
	UpdateLFCProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/${req.SPID}/lfc_product/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveLFCProduct', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateLFCProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateLFCProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateLFCProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateLFCProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateLFCProduct'))
			})
	},

	/**
	* RPC Method: UpdateUFBOrder
	* Description: Update a single UFB order object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/UFB_order/${req.UUID}
	* @param    {UFBProduct} req The API payload object (UFBProduct)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	UpdateUFBOrder({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v3/${req.SPID}/UFB_order/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateUFBOrder ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateUFBOrder ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateUFBOrder ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateUFBOrder UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateUFBOrder'))
			})
	},

	/**
	* RPC Method: UpdateUFBProduct
	* Description: Update a single UFBProduct object
	* HTTP Method: PUT
	* API Path: /api/v3/${req.SPID}/UFB/${req.UUID}
	* @param    {UFBProduct} req The API payload object (UFBProduct)
	* @return   {UFBProduct} The API response object (UFBProduct)
	*
	* @typedef  {Object}  UFBProduct
	* @property {String}  UUID
	* @property {Number}  SPID                                    Default Service Provider ID
	* @property {Number}  CreatedAtNanos                          When the object was first created
	* @property {Number}  UpdatedAtNanos                          When the object was last updated
	* @property {Number}  DeletedAtNanos                          When the object was deleted
	* @property {String}  Address                                 The address for the service to be delivered at
	* @property {String}  AddressID                               The Address ID for the service address
	* @property {String}  CustomerUUID                            Customer
	* @property {ENUM}    ServiceType                             Managed or unmanaged
	* @property {String}  LANIPRange                              LAN IP Range
	* @property {String}  VRFDetails                              VRF Details
	* @property {String}  AdditionalDetails                       Additional Details
	* @property {Boolean} DHCPScope                               DHCP Scope
	* @property {String}  SiteContactName                         Site Contact Name
	* @property {String}  SiteContactPhone                        Site Contact Phone
	* @property {String}  NotificationName                        Notification User
	* @property {String}  NotificationPhoneNumber
	* @property {String}  NotificationEmail
	* @property {String}  ProductAvailabilityUUID
	* @property {Boolean} IsManual
	* @property {String}  IPHelperDetails
	* @property {String}  State
	* @property {String}  Substate
	* @property {String}  WANIP
	* @property {Number}  RFSDate
	* @property {ENUM}    OrderType
	* @property {Number}  SVID
	* @property {Number}  CVID
	* @property {ENUM}    LFC
	* @property {String}  PrequalUUID
	* @property {String}  LFCOrderID
	* @property {String}  OrderExistingONTID
	* @property {String}  OrderExistingProductID
	* @property {String}  SiteVisitScopeStartDateTime
	* @property {String}  SiteVisitInstallStartDateTime
	* @property {String}  SiteVisitInstalCSEStartDateTime
	* @property {String}  SiteVisitCSEStartDateTime
	* @property {String}  SiteVisitChangeONTStartDateTime
	* @property {String}  SiteVisitReplaceONTStartDateTime
	* @property {String}  SiteVisitScopeEndDateTime
	* @property {String}  SiteVisitInstallEndDateTime
	* @property {String}  SiteVisitInstalCSEEndDateTime
	* @property {String}  SiteVisitCSEEndDateTime
	* @property {String}  SiteVisitChangeONTEndDateTime
	* @property {String}  SiteVisitReplaceONTEndDateTime
	* @property {String}  ActiveASSURANCE_L1_SLA_LEVEL
	* @property {String}  ActiveASSURANCE_L2_SLA_LEVEL
	* @property {String}  ActiveATA_PORT_NUMBER
	* @property {String}  ActiveBANDWIDTH_PROFILE
	* @property {String}  ActiveBillingAccount
	* @property {String}  ActiveBITSTREAM_UID_1
	* @property {String}  ActiveBITSTREAM_UID_2
	* @property {String}  ActiveBITSTREAM_UID_3
	* @property {String}  ActiveBITSTREAM_UID_4
	* @property {String}  ActiveCIRCUIT_ID_INSERTION
	* @property {String}  ActiveCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDATA_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveDATA_VLAN_CVID
	* @property {String}  ActiveDATA_VLAN_SVID
	* @property {String}  ActiveDISCONNECT_RFS_DATE_TIME
	* @property {String}  ActiveDOWNSTREAM_HP
	* @property {String}  ActiveDOWNSTREAM_LP_CIR
	* @property {String}  ActiveDOWNSTREAM_LP_EIR
	* @property {String}  ActiveIntactBandwidthProfile
	* @property {String}  ActiveIntactChargeType
	* @property {String}  ActiveIntactProductOfferID
	* @property {String}  ActiveIntactQuantity
	* @property {String}  ActiveIntactUnitCost
	* @property {String}  ActiveIntactValue
	* @property {String}  ActiveITContactEmail
	* @property {String}  ActiveITContactName
	* @property {String}  ActiveITContactOrganisation
	* @property {String}  ActiveITContactPhone
	* @property {String}  ActiveMULTICLASS
	* @property {String}  ActiveONT_ID
	* @property {String}  ActiveORDER_DATE
	* @property {String}  ActiveOrderContactEmail
	* @property {String}  ActiveOrderContactName
	* @property {String}  ActiveOrderContactOrganisation
	* @property {String}  ActiveOrderContactPhone
	* @property {String}  ActiveOrderId
	* @property {String}  ActiveOrderItemId
	* @property {String}  ActivePlaceDescription
	* @property {String}  ActivePlaceID
	* @property {String}  ActiveProductBandwidthProfile
	* @property {String}  ActiveProductChargeType
	* @property {String}  ActiveProductID
	* @property {String}  ActiveProductName
	* @property {String}  ActiveProductProductOfferID
	* @property {String}  ActiveProductProductOfferName
	* @property {String}  ActiveProductQuantity
	* @property {String}  ActiveProductUnitCost
	* @property {String}  ActiveProductValue
	* @property {String}  ActiveSiteContactEmail
	* @property {String}  ActiveSiteContactName
	* @property {String}  ActiveSiteContactOrganisation
	* @property {String}  ActiveSiteContactPhone
	* @property {String}  ActiveState
	* @property {String}  ActiveUNI_BITSTREAM_UID_1
	* @property {String}  ActiveUNI_BITSTREAM_UID_2
	* @property {String}  ActiveUNI_BITSTREAM_UID_3
	* @property {String}  ActiveUNI_BITSTREAM_UID_4
	* @property {String}  ActiveUNI_PORT_NUMBER
	* @property {String}  ActiveUNI_PORT_TYPE
	* @property {String}  ActiveUNI_TAGGING
	* @property {String}  ActiveUNIVLAN
	* @property {String}  ActiveUPSTREAM_HP
	* @property {String}  ActiveUPSTREAM_LP_CIR
	* @property {String}  ActiveUPSTREAM_LP_EIR
	* @property {String}  ActiveVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  ActiveSERVICE_GIVEN_DATE
	* @property {String}  ActiveONTSerial
	* @property {String}  DesiredDateTime
	* @property {String}  ServiceProfile
	* @property {String}  StaticIPv4
	* @property {String}  StaticIPv6
	* @property {String}  TransferCustomerAccountID
	* @property {String}  TransferCustomerInstanceID
	* @property {String}  TransferCustomerName
	* @property {String}  FeasabilityDATA_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityVOICE_HANDOVER_CONNECTION_ID
	* @property {String}  FeasabilityAVAILABILITY_STATUS
	* @property {String}  FeasabilityBUILD_REQUIRED
	* @property {String}  FeasabilityCONSENT_REQUIRED
	* @property {String}  FeasabilityINSTALL_TYPE
	* @property {String}  FeasabilityDEMAND_POINT_TYPE
	* @property {String}  FeasabilityDESIGN_REQUIRED
	*
	*/
	UpdateUFBProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined
		if (!req) { req = {} }
		// Make API request
		return axios.put(`/v3/${req.SPID}/UFB/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateUFBProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateUFBProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateUFBProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateUFBProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateUFBProduct'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};
