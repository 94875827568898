// import all_package_list from '@/assets/themes/${Theme_Name}/data/broadband-package-list'

const all_package_list = []

const state = {

}

const getters = {
    broadband_package_list: () => all_package_list
}

const mutations = {

}

const actions = {
    getPackage() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(all_package_list[0])
            }, 500)
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}
