import { fb_remote_config } from '@/utils/firebase.js';

export default {
    state: {
        is_hide_prepay_topup: true,
        is_hide_prepay_balance_if_zero: true,
    },

    getters: {
        is_hide_prepay_topup: (state) => state.is_hide_prepay_topup,
        is_hide_prepay_balance_if_zero: (state) => state.is_hide_prepay_balance_if_zero,
    },

    actions: {
        get_is_hide_prepay_topup({commit}) {
            fb_remote_config.fetchAndActivate().then(() => {
                const is_hide_prepay_topup = fb_remote_config.getValue("hide_prepay_topup").asBoolean()

                commit('SET_IS_HIDE_PREPAY_TOPUP', is_hide_prepay_topup)

                return is_hide_prepay_topup
            })
        },

        get_is_hide_prepay_balance_if_zero({commit}) {
            fb_remote_config.fetchAndActivate().then(() => {
                const is_hide_prepay_balance_if_zero = fb_remote_config.getValue("hide_prepay_balance_if_zero").asBoolean()

                commit('SET_IS_HIDE_PREPAY_BALANCE_IF_ZERO', is_hide_prepay_balance_if_zero)
            })
        },
    },

    mutations: {
        SET_IS_HIDE_PREPAY_TOPUP(state, is_hide_prepay_topup) {
            state.is_hide_prepay_topup = is_hide_prepay_topup
        },

        SET_IS_HIDE_PREPAY_BALANCE_IF_ZERO(state, is_hide_prepay_balance_if_zero) {
            state.is_hide_prepay_balance_if_zero = is_hide_prepay_balance_if_zero
        },
    },
};
