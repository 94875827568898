<template>
    <div class="app-loader" :class="[fixed_class]">
        <MountingPortal mountTo="#app-loader-layer" append>
            <div class="app-loader__layer"/>
        </MountingPortal>

		<app-animation v-if="animation_src && !animation_error" :src="animation_src" :stateMachines="animation_state_machines" @error="onAnimationError"/>
		<app-animation v-else-if="is_preloader_animated && !animation_error" :src="'/assets/animations/preloader.riv'" :stateMachines="AnimationsStateMachines.preloader" @error="onAnimationError"/>
        <app-svg v-else/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appSvg from './components/app-svg';
import appAnimation from '@/components/app-animation';

const fixed_list = {
    desktop: true,
    tablet: true,
    mobile: true,
}

export default {
    components: {
        appSvg,
		appAnimation,
    },

    props: {
        fixedOn:                    { type: String,  default: '' }, // desktop | tablet | mobile
        animation_src:              { type: String,  default: '' },
        animation_state_machines:   { type: String               },
    },

    data() {
        return {
            animation_error: false,
        }
    },

    computed: {
        ...mapGetters(['getAppEnvParam', 'AnimationsStateMachines',]),

        fixed_class() {
            return this.fixedOn && this.fixedOn in fixed_list ? `fixed-on-${ this.fixedOn }` : false
        },

        is_preloader_animated() {
            return ['true', 'yes', 1].includes(this.getAppEnvParam('IS_PRELOADER_ANIMATED').toLowerCase())
        },
    },

    methods: {
        onAnimationError() {
            this.animation_error = true;
        },
    },
}
</script>

<style lang="scss">
@mixin fixed-on() {
    svg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
    }
}

.app-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $loader-bg-color;
    border-radius: inherit;
    margin: 0;
    padding: 20px;
    z-index: $z-index-loader;

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        z-index: $z-index-loader;
    }

    svg {
        content: "";
        width: 96px;
        height: 96px;
        max-width: 95%;
        max-height: 95%;
        object-fit: contain;

        circle {
            fill: $loader-color;
        }
    }

    &.fixed-on-desktop {
        @include fixed-on
    }

    &~* {
        .app-loader {
            display: none;
        }
    }
}
</style>