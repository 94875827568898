import axiosIntents from '@/utils/axios-intents'

import i18n from '@/i18n'

const state = {

}

const getters = {

}

const mutations = {

}

const pinging = {
    requests: {},
    delay_limit: 10000,
}

const getLogAnalyticsPurchaseParams = (addon = {}, is_price_in_cents = true) => {
    const price = is_price_in_cents ? +(addon.cost / 100).toFixed(2) : +addon.cost

    const  parameters = {
        currency: "NZD",
        value: price,
        items: [{
            item_id: addon.Availabilities?.[0]?.UUID || '',
            item_name: addon.Name,
            quantity: 1,
            price: price,
        }]
    }

    return parameters
}

const actions = {
    // This is for Prepay Addon
    buyAddon({getters, commit, dispatch}, { addon, Subscribe = true, replaceSubscriptions = [], isReplaceNow = false, isOnboarding = false, PaymentMethodID, PaymentMethodType, isBasePlan = false } = {}) {
        if (!addon) return Promise.reject('No Plan')

        const addon_available_uuid = addon && addon.Availabilities && addon.Availabilities.length
            ? addon.Availabilities[0].UUID
            : null

        const customer_current_product_uuid = getters.customer_current_product_uuid

        let request_key = ''

        const onDashboardBuyAddonRequest = () => {
            return dispatch('getCurrentPrepayProduct').then(prepay_product => {
                request_key = `${prepay_product.UUID }|${ addon_available_uuid }`

                return axiosIntents.post(`/customer/:uuid/prepay_addon/${ prepay_product.UUID }/${ addon_available_uuid }`, {
                    Subscribe,
                    PAYG: addon.is_payg,
                    AuthorizeOnly: false,
                    ...(isBasePlan && {isBasePlan})

                    // replaceSubscriptions,
                    // isReplaceNow,
                })
            })
        }

        const onOnboardingBuyAddonRequest = () => {
            request_key = `${customer_current_product_uuid }|${ addon_available_uuid }`

            const AuthorizeOnly = addon?.is_fully_paid ? true : false

            return axiosIntents.post(
                `/customer/:uuid/prepay_addon_initial/${ customer_current_product_uuid }/${ addon_available_uuid }`,
                {
                    AuthorizeOnly,
                    Subscribe,
                    PAYG: addon.is_payg,
                    PaymentMethodGateway: PaymentMethodType == 'cards' ? 'stripe' : '',
                    ...(PaymentMethodID && {PaymentMethodID}),
                    ...(PaymentMethodType && {PaymentMethodType}),
                }
            ).catch((error) => {
                return Promise.reject({...error, is_prepay_activation_error: true})
            })
        }

        const buyAddonRequest = isOnboarding ? onOnboardingBuyAddonRequest : onDashboardBuyAddonRequest

        return addon_available_uuid
            ? buyAddonRequest()
            .then(({result}) => {
                if (result?.Status == 'pending_payment') {
                    if (!(request_key in pinging.requests)) {
                        pinging.requests[request_key] = new Date()
                    }

                    if (isOnboarding) {
                        if (!result.ClientSecret) {
                            return Promise.reject(new Error('lorem ipsum'))
                        }

                        if (addon?.is_fully_paid) {
                            delete pinging.requests[request_key]

                            dispatch('logAnalyticsEventPurchase', { parameters: getLogAnalyticsPurchaseParams(addon) })

                            return Promise.resolve({ Status: 'ok' })
                        }

                        return dispatch('stripePaymentConfirmation', { client_secret: result.ClientSecret }).then(confirmation_result => {
                            delete pinging.requests[request_key]

                            if (confirmation_result.status == 200 || confirmation_result?.paymentIntent?.status == 'succeeded') {
                                dispatch('logAnalyticsEventPurchase', { parameters: getLogAnalyticsPurchaseParams(addon) })
                                
                                return Promise.resolve({ Status: 'ok' })
                            } else {
                                return Promise.reject(new Error('lorem ipsum'))
                            }
                        })
                        .catch(error => {
                            console.log('stripePaymentConfirmation error', error)

                            delete pinging.requests[request_key]
                            return Promise.reject(new Error('lorem ipsum'))
                        })
                    } else {
                        if (result.ClientSecret) {

                            delete pinging.requests[request_key]
                            return Promise.resolve(result)
                        }

                        if (new Date() - pinging.requests[request_key] < pinging.delay_limit ) {
                            return dispatch('buyAddon', { addon, Subscribe, replaceSubscriptions, isReplaceNow, isOnboarding, PaymentMethodID, PaymentMethodType, isBasePlan })
                        } else {
                            delete pinging.requests[request_key]
                            return Promise.reject(new Error('lorem ipsum'))
                        }
                    }
                } else {
                    dispatch('logAnalyticsEventPurchase', { parameters: getLogAnalyticsPurchaseParams(addon) })

                    if ('PrepayProduct' in result && 'AddOns' in result.PrepayProduct) {
                        // commit('SET_CUSTOMER_CURRENT_PREPAYED_PRODUCT_BALANCE', getters.customer_current_prepayed_plan_balance - addon.cost)

                        commit('savePrepayProduct', result.PrepayProduct)

                        delete pinging.requests[request_key]
                        return Promise.resolve(result)
                    } else {
                        delete pinging.requests[request_key]
                        return Promise.resolve(result)
                    }
                }
            })
            .catch(error => {
                console.log('buyAddonRequest error', error)

                return Promise.reject(error)
            })
            .finally(() => {
                delete pinging.requests[request_key]
            })
            : Promise.reject(new Error(i18n.t('addon_available_product_not_found')))
    },

    // This is for Postpay product
    // Provisions the selected product onto the customer's postpay plan.
    PurchasePostpayProduct({ getters, dispatch }, { product, Subscribe = true, isBasePlan = false } = {}) {
        const AddonAvailabilityUUID = product && product.Availabilities && product.Availabilities.length
            ? product.Availabilities[0].UUID
            : null

        if (!AddonAvailabilityUUID) {
            return Promise.reject(new Error(i18n.t('addon_available_product_not_found')))
        }

        const AddonCatalogEntryUUID = product.Availabilities[0].ProductCatalogEntryUUID
        const CustomerUUID = getters.current_account_uid
        const ProductInstanceUUID = getters.customer_current_product_uuid

        return axiosIntents.post('/postpay/addon', {
            AddonAvailabilityUUID,
            AddonCatalogEntryUUID,
            CustomerUUID,
            ProductInstanceUUID,
            Subscribe,
            ...(isBasePlan && {isBasePlan})
        }).then(result => {
            const client_secret = result.data?.ClientSecret

            if (client_secret) {
                return dispatch('stripePaymentConfirmation', { client_secret }).then(()=> {
                    dispatch('logAnalyticsEventPurchase', { parameters: getLogAnalyticsPurchaseParams(product, false) })

                    return Promise.resolve(result) 
                })
            }

            dispatch('logAnalyticsEventPurchase', { parameters: getLogAnalyticsPurchaseParams(product, false) })

            return Promise.resolve(result)
        })
    },

    UnsubscribePrepayAddon({getters}, PrepayAddonUUID) {
        const ProductInstanceUUID = getters.customer_current_product_uuid

        return axiosIntents.delete(`/customer/:uuid/prepay_addon/${ProductInstanceUUID}/${PrepayAddonUUID}`)
    },

    ToggleAutoRenewAddon({getters}, { MobileAddonUUID, MobileUUID, is_turn_on } = {}) {
        const payload = {
            MobileAddonUUID,
            MobileUUID,
            CustomerUUID: getters.current_account_uid,
            AutoRenew: is_turn_on,
        }

        return axiosIntents.post('/update/change-addon-auto-renew', payload)
    },

    // ChangePlan use new addon to replace old addon.
    changePlan({getters}, { NewAddonAutoRenew = false, ChangeNow = false, NewAddonAvailabilityUUID, OldAddonUUID } = {}) {
        const payload = {
            NewAddonAutoRenew,
            ChangeNow,
            NewAddonAvailabilityUUID,
            OldAddonUUID,
            CustomerUUID: getters.current_account_uid,
            MobileUUID: getters.customer_current_product_uuid,
        }

        return axiosIntents.post('/eshop/customer/change-plan', payload)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
