const CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID

const state = {
    GoogleAuth: null,
    google_initialized: null,
}

const getters = {
}

const mutations = {
    saveGoogleAuth(state, GoogleAuth) {
        state.GoogleAuth = GoogleAuth
        state.google_initialized = null
    },
    googleInitialized(state, promise) {
        state.google_initialized = promise
    },

    saveMe(state, me) {
        state.me = me
    },
    resetMe(state) {
        state.me = null
    },
}

const actions = {
    googleInit({state, commit}, config) {
        if (state.google_initialized) {
            return state.google_initialized
        } else {
            if (state.GoogleAuth) {
                return Promise.resolve(state.GoogleAuth)
            } else {
                let google_initialized = new Promise((resolve, reject) => {
                    let script = document.createElement('script')
                        script.src = 'https://apis.google.com/js/platform.js'
                        script.onreadystatechange = script.onload = () => {
                            if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                                window.gapi.load('auth2', () => {
                                    window.gapi.auth2
                                        .init({
                                            clientId: CLIENT_ID,
                                            scope: 'profile email',
                                            prompt: 'select_account'
                                        })
                                        .then(() => {
                                            const GoogleAuth = window.gapi.auth2.getAuthInstance()

                                            commit('saveGoogleAuth', GoogleAuth)
                                            resolve(state.GoogleAuth)
                                        })
                                        .catch(error => {
                                            commit('saveGoogleAuth', null)
                                            reject(error)
                                        })
                                })
                            }
                        }

                    document.getElementsByTagName('head')[0].appendChild(script)
                })

                commit('googleInitialized', google_initialized)

                return google_initialized
            }
        }
    },

    googleSignIn({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('googleInit').then(GoogleAuth => {
                GoogleAuth.signIn()
                    .then(GoogleUser => resolve(GoogleUser))
                    .catch(error => reject(error))
            }).catch(error => reject(error))
        })
    },

    googleSignOut({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('googleInit').then(GoogleAuth => {
                GoogleAuth.signOut()
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            }).catch(error => reject(error))
        })
    },

    googleGetLoginStatus({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('googleInit').then(GoogleAuth => {
                resolve(GoogleAuth.isSignedIn.get())
            }).catch(error => reject(error))
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}