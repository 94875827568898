const state = {
    deviceAgent: window.navigator.userAgent.toLowerCase()
};

const getters = {
    is_mobile_device: state => state.deviceAgent.indexOf('mobi') != -1,
    is_iphone_device: state => state.deviceAgent.indexOf('iphone') != -1,
    is_ios_device: state => /ipad|iphone|ipod/.test(state.deviceAgent) || window.navigator.maxTouchPoints && window.navigator.maxTouchPoints > 2 && /MacIntel/.test(window.navigator.platform),
    is_android_device: state => state.deviceAgent.indexOf('android') != -1,
};

const actions = {};

const mutations = {};

export default {
    state,
    getters,
    mutations,
	actions,
};
