import { db } from '@/utils/firebase.js'

import i18n from '@/i18n';



const state = {
    payment_history: [],
}

const getters = {
    payment_history: (state, getters) => getters.is_processing_autologin
        ? []
        : state.payment_history || [],
}

const mutations = {
    savePaymentHistory(state, payment_history) {
        state.payment_history = payment_history
    },

    resetPaymentHistory(state) {
        state.payment_history = []
    },
}

const actions = {
    getPaymentHistory({getters, commit}) {
        const uuid = getters.current_account_uid

        if (uuid) {
            return db.doc(`/customers/${ uuid }`).get().then(customer => {
                return customer.ref.collection('/transactions').get().then((transactions) => {
                    
                    let payment_history = []
    
                    const month_regexp = /[^\d]/g
                    
                    transactions.forEach((transaction) => {
                        const monthes = transaction.data()
    
                        for (const key in monthes) {
                            if (monthes[key] && monthes[key].length) {
                                payment_history.push({
                                    year: +transaction.id || transaction.id,
                                    month: +key.replace(month_regexp, '') || key,
                                    total: monthes[key].reduce((total, action) => total + action.Amount, 0),
                                    actions: monthes[key].map(
                                        action => ({...action, Description: action.Description.replace('Topup', i18n.t('top_up'))})
                                    ),
                                })
                            }
                        }
                    })
    
                    commit('savePaymentHistory', payment_history)
    
                    return Promise.resolve(getters.payment_history)
                }).catch((error) => Promise.reject(error))
            }).catch((error) => {
                console.error(`ERROR /customers/${ uuid }/transactions`, error)
                console.dir(error)
    
                return Promise.reject(error)
            })
        } else {
            return Promise.resolve([])
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}