import axios from '@/utils/axios-intents'

import StoreCache from '@/utils/StoreCache'

const cached = {
    ufb_plans: {
        uuids: {},
        lifetime: 5 * 60 * 60,
    }
}

import { db } from '@/utils/firebase'

const unsubscribes = {}
const unsubscribeAll = () => {
    for (const uuid in unsubscribes) {
        unsubscribes[ uuid ]()
        delete unsubscribes[ uuid ]
    }
}

const state = {
    ufb_products: [],
    ufb_product_uuid: null,
}

const getters = {
    ufb_product: state => state.ufb_products.find(ufb_product => ufb_product.UUID == state.ufb_product_uuid),
    ufb_products: state => state.ufb_products || [],
}

const mutations = {
    saveUFBProducts(state, ufb_products) {
        state.ufb_products = ufb_products || []
    },

    selectFirstUFBProduct(state) {
        if (state.ufb_products.length) {
            if (!(state.ufb_product_uuid && state.ufb_products.findIndex(ufb_product => ufb_product.UUID == state.ufb_product_uuid) > -1)) {
                state.ufb_product_uuid = state.ufb_products[0].UUID
            }
        } else {
            state.ufb_product = null
            state.ufb_product_uuid = null
        }
    },

    selectUFBProduct(state, UUID) {
        state.ufb_product_uuid = UUID
    },

    resetUFBProduct() {
        state.ufb_products = []
        state.ufb_product_uuid = null

        unsubscribeAll()
    },
}

const actions = {
    ufbSearchAddress({}, payload) {
        return axios.get('/ufb/searchAddress', { params: payload })
            .then(({data}) => Promise.resolve(data))
    },

    ufbServiceAvailability({getters}, Location) {
        const payload = {
            SPID: getters.app_spid,
            LocationProvider: Location.LocationProvider,
            LocationProviderIDX: Location.LocationProviderIDX,
        }

        return axios.get('/ufb/service_availability', { params: payload }).then(({data}) => {
            const service = data?.Services?.find(service => service?.AccessType == 'ufb'
                && service.ServiceabilityType
                && (service.ServiceabilityType == 'serviceable' || service.ServiceabilityType == 'install-required')
            )

            if (service) {
                service.ServiceAvailabilityUUID = data.UUID
            }

            return service
                ? Promise.resolve(service)
                : Promise.reject(new Error('No Fibre services available'))
        })
    },

    ufbPlans({getters}, ServiceAvailabilityUUID) {
        if (ServiceAvailabilityUUID in cached.ufb_plans.uuids && !cached.ufb_plans.uuids[ServiceAvailabilityUUID].expired()) {
            return Promise.resolve(cached.ufb_plans.uuids[ServiceAvailabilityUUID].cachedData())
        } else {
            const payload = {
                SPID: getters.app_spid,
                ServiceAvailabilityUUID,
            }

            return axios.post('/ufb/plans', payload).then(({data}) => {
                const plans = data?.UFBPlans

                if (Array.isArray(plans)) {
                    if (!(ServiceAvailabilityUUID in cached.ufb_plans.uuids)) {
                        cached.ufb_plans.uuids[ServiceAvailabilityUUID] = new StoreCache(cached.ufb_plans.lifetime)
                    }
                    cached.ufb_plans.uuids[ServiceAvailabilityUUID].fix(plans)
                }

                return Promise.resolve(plans || [])
            })
        }
    },

    ufbProductavailability({}, PlanUUID) {
        return axios.get(`/ufb/ufbproduct/metaproduct/mapping/productavailability/${ PlanUUID }`)
            .then(({data}) => Promise.resolve(data))
    },

    ufbCheckout({getters}, { location, service, product, with_wifi_router }) {
        const auth_user_profile_creds = getters.auth_user_profile_creds

        const payload = {
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            Address: {
                City: location.City,
                Country: location.Country,
                Postcode: location.PostCode,
                Lines: location.Lines,
                LocationProvider: location.LocationProvider,
                LocationProviderIDX: location.LocationProviderIDX,
                PrequalUUID: service.ServiceAvailabilityUUID,
                Email: auth_user_profile_creds.email,
                Name: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
                Phone: auth_user_profile_creds.phone,
            },
            ExternalReference: {
                ServiceType: "Unmanaged",
                OrderType: "ConnectPrimary",
                ServiceAvailabilityUUID: service.ServiceAvailabilityUUID,
                // "RSFDate": {{RSFDate}},
                SiteContactName: [auth_user_profile_creds.first_name, auth_user_profile_creds.last_name].join(' ').trim(),
                SiteContactPhone: auth_user_profile_creds.phone,
                SiteContactEmail: auth_user_profile_creds.email,
            },
            Items: [{
                ItemUUID: product.ProductCatalogEntryUUID,
                ProductAvailabilityUUID: product.ProductCatalogAvailabilityUUID,
                // "Quantity": 1,
                SPID: getters.app_spid,
                "Type": "TYPE_VIRTUAL",
                // "UUID": null,
                "VirtualType": "VT_PLAN",
                // "Attributes": {
                //     "BillingReference": "postpay",
                //     "AutoRenew": true
                // }
            }],
        }

        if (with_wifi_router) {
            payload.Items.push({
                ItemUUID: product.ProductCatalogEntryUUID,
                ProductAvailabilityUUID: product.ProductCatalogAvailabilityUUID,
                // "Quantity": 1,
                SPID: getters.app_spid,
                "Type": "TYPE_PRODUCT",
                // "UUID": null,
                "VirtualType": "VT_NONE"
            })
        }

        return axios.post('/ufb/checkout', payload)
            .then(({data}) => Promise.resolve(data))
    },

    ufbSubscribeProducts({getters, commit}) {
        const uuid = getters.current_account_uid

        if (uuid) {
            if (!(uuid in unsubscribes)) {
                unsubscribeAll()

                return new Promise(resolve => {
                    unsubscribes[uuid] = db.collection(`/customers/${ uuid }/ufb_products`).onSnapshot(collection => {
                        const ufb_products = []
    
                        collection.forEach((doc) => {
                            const ufb_product = doc.data()
    
                            if (!ufb_product.DeletedAtNanos) {
                                ufb_products.push(ufb_product)
                            }
                        })
    
                        commit('saveUFBProducts', ufb_products)
                        commit('selectFirstUFBProduct')

                        resolve(getters.ufb_products)
                    })
                })
            }
        } else {
            return Promise.resolve([])
        }
    },

    ufbUnsubscribeProducts() {
        unsubscribeAll()
    },

    ufbBookOrderAppointment({getters}, payload) {
        if (!payload.SPID) {
            payload.SPID = getters.app_spid
        }

        return axios.post(`/ufb/order/${ payload.UUID }/book`, payload)
            .then(({data}) => Promise.resolve(data))
    },
}

export default {
	state,
	getters,
	mutations,
	actions,
}