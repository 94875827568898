import axiosIntents from '@/utils/axios-intents'

export default {
    state: {},

    getters: {},

    actions: {
        UpdateMVNECustomer({}, { FirstName, LastName, ExternalRef5, Email } = {}) {
            return axiosIntents.put('/customer/:uuid', { FirstName, LastName, ExternalRef5, Email })
                .catch(error => {
                    console.log('UpdateMVNECustomer error', error)
                })
        },

        SendEmailVerification({}, { resend = false } = {}) {
            return axiosIntents.get(`/customer/:uuid/email_verification/${resend ? 'resend' : 'send'}`)
                .catch(error => {
                    console.log('SendEmailVerification Error:', error)

                    return Promise.resolve(error)
                })
        },

        ConfirmEmailVerification({ getters }, { Secret } = {}) {
            if (!Secret) return Promise.reject('No Code')
            
            const CustomerUUID = getters.current_account_uid

            return axiosIntents.get(`/customer/:uuid/email_verification/confirm`, { params: { Secret, CustomerUUID } })
                .catch(error => {
                    console.log('ConfirmEmailVerification Error:', error)

                    return Promise.reject(error)
                })
        },

        IdentifyCustomerWithToken({ getters }, { identity_token } = {}) {
            console.log('IdentifyCustomerWithToken', identity_token)

            if(!identity_token) Promise.reject('No identity_token')

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({identity_token})

                    // reject('Identification error')
                }, 1200);
            })
        },
    },

    mutations: {},
}