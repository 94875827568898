import StoreCache from '@/utils/StoreCache.js'

import i18n from '@/i18n'

const BONUSES = {
    bonuses_amount: 450,
    bonuses_list: [
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12000',
            category: 'your-rewards',
            group: 'internet',
            title: '20 extra GB',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 150,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12001',
            category: 'your-rewards',
            group: 'internet',
            title: '5 extra GB',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 50,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12002',
            category: 'your-rewards',
            group: 'mobile-services',
            title: '10 mins to all nets',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 200,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12003',
            category: 'your-rewards',
            group: 'mobile-services',
            title: '10 sms',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 100,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12004',
            category: 'your-rewards',
            group: 'entertainment',
            title: 'Play our games',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 550,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12005',
            category: 'your-rewards',
            group: 'entertainment',
            title: 'Take part in ...',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 220,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12006',
            category: 'your-rewards',
            group: 'shopping',
            title: 'Cool accessories',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 111,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12007',
            category: 'other-gifts',
            group: 'gifts',
            title: 'Lorem',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 222,
        },
        {
            uuid: 'f22aaab6-a5d6-4c7b-82b0-75d650a12008',
            category: 'other-gifts',
            group: 'gifts',
            title: 'Lorem Ipsum',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi',
            cost: 333,
        },

    ]
}

const DEFAULT_LIFETIME = 1 * 60 * 1000 // 1 minute

const GROUPS = [
    { id: 'internet',         icon: 'language',     title: i18n.t('internet')        },
    { id: 'mobile-services',  icon: 'phone-iphone', title: i18n.t('mobile_services') },
    { id: 'entertainment',    icon: 'shapes',       title: i18n.t('entertainment')   },
    { id: 'shopping',         icon: 'bag',          title: i18n.t('shopping')        },
    { id: 'gifts',            icon: 'gift-box',     title: i18n.t('gifts')           },
]

export default {
    state: {
        bonuses_amount: new StoreCache(DEFAULT_LIFETIME),
        bonuses_list: {},
        bonuses: {},
        groups: GROUPS,
    },

    getters: {
        is_display_bonuses: (state, getters) => getters.getAppEnvParam('BONUSES_DISPLAY') && ['true', 'yes', 1].includes(getters.getAppEnvParam('BONUSES_DISPLAY').toLowerCase()),
    },

    actions: {
        GetBonusesAmount({state, commit}) {
            const amount = state.bonuses_amount
            if (!amount.expired()) {
                return Promise.resolve(amount.cachedData())
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const amount = BONUSES.bonuses_amount

                    commit('SAVE_BONUSES_AMOUNT', amount)
                    resolve(amount)
                }, 500)
            })
        },

        GetBonusesListByCategory({state, commit}, category) {
            const list = state.bonuses_list[category]

            if (list && !list.expired()) {
                return Promise.resolve(list.cachedData())
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const list = BONUSES.bonuses_list.filter( bonus => bonus.category == category )

                    commit('SAVE_BONUSES_LIST_BY_CATEGORY', { category, list })
                    resolve(list)
                }, 1000)
            })
        },

        GetBonusByUUID({state, commit}, uuid) {
            const bonus = state.bonuses[uuid]

            if (bonus && !bonus.expired()) {
                return Promise.resolve(bonus.cachedData())
            }

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const bonus = {
                        ...BONUSES.bonuses_list.find( bonus => bonus.uuid == uuid ),
                        value: '10 mins',
                        validity: '30 days',
                        full_description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
                    }

                    commit('SAVE_BONUS', bonus)
                    resolve(bonus)
                }, 1000)
            })

        }
    },

    mutations: {
        SAVE_BONUSES_AMOUNT(state, amount) {
            state.bonuses_amount.fix(amount)
        },

        DELETE_BONUSES_AMOUNT(state) {
            state.bonuses_amount.expireNow()
        },

        SAVE_BONUS (state, bonus ) {
            if (!state.bonuses[bonus.uuid]) {
                state.bonuses[bonus.uuid] = new StoreCache(DEFAULT_LIFETIME)
            }

            state.bonuses[bonus.uuid].fix(bonus)
        },

        DELETE_BONUS_BY_UUID(state, uuid ) {
            delete state.bonuses[uuid]
        },

        SAVE_BONUSES_LIST_BY_CATEGORY(state, { category, list } ) {
            if (!state.bonuses_list[category]) {
                state.bonuses_list[category] = new StoreCache(DEFAULT_LIFETIME)
            }

            state.bonuses_list[category].fix(list)
        },

        DELETE_BONUSES_LIST_BY_CATEGORY(state, category ) {
            delete state.bonuses_list[category]
        },
    }
}