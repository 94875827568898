import axiosIntents from '@/utils/axios-intents'
import downloadBlob from '@/utils/downloadBlob.js'
import i18n from '@/i18n';

export default {
    state: {
        receipts: [],
        is_loading_receipts: false,
        is_downloading_receipt_pdf: false,
        receipts_loading_error: false,
    },

    getters: {
        receipts: state =>  state.is_loading_receipts ? [] : state.receipts,
        is_loading_receipts: state =>  state.is_loading_receipts,
        is_downloading_receipt_pdf: state =>  state.is_downloading_receipt_pdf,
        receipts_loading_error: state =>  state.receipts_loading_error,
    },

    actions: {
        getReceipts({getters, commit}) {
            if (getters.is_loading_receipts || !getters.is_app_environment_receipts_allowed) return;

            commit('SET_RECEIPTS_LOADING_ERROR', false)

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(i18n.t('no_uuid'))

            commit('SET_IS_LOADING_RECEIPTS', true)

            return axiosIntents.get(`/customer/${ uuid }/receipt`)
                .then(result => {
                    const receipts = result?.data?.Receipts
                    
                    if (!receipts || receipts.length == 0) {
                        commit('DELETE_RECEIPTS')
                    } else {
                        commit('SET_RECEIPTS', receipts)
                    }

                    return receipts
                })
                .catch(error => {
                    console.log('getReceipts error', error)

                    commit('SET_RECEIPTS_LOADING_ERROR', true)
                    commit('DELETE_RECEIPTS')

                    return Promise.resolve([])
                })
                .finally(()=> {
                    commit('SET_IS_LOADING_RECEIPTS', false)
                })
        },

        downloadReceiptPdf({getters, commit}, receipt_uuid) {
            if (getters.is_downloading_receipt_pdf) return;

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(new Error(i18n.t('no_uuid')))
            if (!receipt_uuid) return Promise.reject(new Error(i18n.t('no_receipt_uuid')))
            
            commit('SET_IS_DOWNLOADING_RECEIPT_PDF', true)

            return axiosIntents.get(`/customer/${ uuid }/receipt/${receipt_uuid}/pdf`, {responseType: 'blob'})
                .then(({ result: blob }) => {
                    return downloadBlob(`${receipt_uuid}.pdf`, blob)
                })
                .catch(error => {
                    console.log('downloadReceiptPdf error', error)

                    return Promise.reject(error)
                })
                .finally(()=> {
                    commit('SET_IS_DOWNLOADING_RECEIPT_PDF', false)
                })
        },
    },

    mutations: {
        SET_IS_LOADING_RECEIPTS(state, is_loading_receipts) {
            state.is_loading_receipts = is_loading_receipts
        },

        SET_IS_DOWNLOADING_RECEIPT_PDF(state, is_downloading_receipt_pdf) {
            state.is_downloading_receipt_pdf = is_downloading_receipt_pdf
        },

        SET_RECEIPTS_LOADING_ERROR(state, receipts_loading_error) {
            state.receipts_loading_error = receipts_loading_error
        },

        SET_RECEIPTS(state, receipts) {
            state.receipts = receipts
        },

        DELETE_RECEIPTS(state) {
            state.receipts = []
        },
    },
}
