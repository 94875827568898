import axios from 'axios';

import i18n from '@/i18n'

import { MEGA_BASE_2 } from '@/consts'

const API_V2 = 'v2';

const USAGE_TYPES = {
    'Data MB': { title: i18n.t('data'),  icon: 'data-usage',  type: 'Data MB',  is_integer: false,   unit: i18n.t('unit.MB') },
    // Voice:     { title: i18n.t('voice'), icon: 'phone-usage', type: 'Voice',    is_integer: true,   unit: i18n.t('unit.mins') },
    // SMS:       { title: i18n.t('SMS'),   icon: 'sms-usage',   type: 'SMS',      is_integer: true,   unit: i18n.t('unit.SMS') },
    // MMS:       { title: i18n.t('MMS'),   icon: 'mms-usage',   type: 'MMS',      is_integer: true,   unit: i18n.t('unit.MMS') },
};

const USAGES = {
	'Data MB': {
	    history: {},
	    id: 'Data MB',
	    credit: 'CreditDataMB',
	    icon: 'data-usage',
	    total: 'TotalDataMB',
	    title: i18n.t('data'),
	    type: 'Data MB',
	    unit: i18n.t('unit.MB'),
	    kind: 'data',
	    is_integer: false,
	},
	/* Voice: {
	    history: {},
	    id: 'Voice',
	    credit: 'CreditVoiceMinutes',
	    icon: 'phone-usage',
	    total: 'TotalVoiceMinutes',
	    title: i18n.t('voice'),
	    type: 'Voice',
	    unit: i18n.t('unit.mins'),
	    kind: 'mins',
	    is_integer: true,
	},
	SMS: {
	    history: {},
	    id: 'SMS',
	    credit: 'CreditSMS',
	    icon: 'sms-usage',
	    total: 'TotalSMS',
	    title: i18n.t('SMS'),
	    type: 'SMS',
	    unit: i18n.t('unit.SMS'),
	    kind: 'txts',
	    is_integer: true,
	},
	MMS: {
	    history: {},
	    id: 'MMS',
	    credit: 'CreditMMS',
	    icon: 'mms-usage',
	    total: 'TotalMMS',
	    title: i18n.t('MMS'),
	    type: 'MMS',
	    unit: i18n.t('unit.MMS'),
	    kind: 'pxts',
	    is_integer: true,
	}, */
};

const DEFAULT_BUCKET = Array.from({ length: 30 }).fill(0);

const state = {
    usage: {},
    is_getting_usage: false,
};

const getters = {
    usage_types: (state) => USAGE_TYPES,
    usage: (state) => state.usage,
    is_getting_usage: (state) => state.is_getting_usage,
};

const mutations = {
    SAVE_USAGES(state, usages) {
        state.usage = usages;
    },

    SET_IS_GETTING_USAGES(state, is_getting_usage) {
        state.is_getting_usage = is_getting_usage;
    },
};

const actions = {
    async getUsageByTypeAndRange({ getters, commit, dispatch }, { period, ProductType }) {
	    commit('SAVE_USAGES', {});
	    commit('SET_IS_GETTING_USAGES', true);

        const date_now = Date.now();

	    const body = {
	    	CustomerUUID: getters.current_account_uid,
	    	ProductInstanceUUID: getters.customer_current_mobile_product_uuid,
	    	ProductType,
	    	Range: {
	    		FirstNanos: (date_now - period * 24 * 60 * 60 * 1000) * 1000000,
	    		FinalNanos: date_now * 1000000,
	    	  },
	    	IntervalNanos: 24 * 60 * 60 * 1000 * 1000000,
	    };

        const stats_promises = Object.values(USAGES)
            .map(({ kind }) => kind)
            .map(kind => axios.post(`${API_V2}/:spid/usage/${kind}/histogram`, body));

        const rslt = await Promise.allSettled(stats_promises);
        const usages_keys = Object.keys(USAGES);
        const usage_date = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate() + 1);
        const usage_dates = Array.from({ length: 30 }, (_) => new Date(usage_date.setDate(usage_date.getDate() - 1)));

        const usages_with_stats = rslt.reduce((acc, curr, index) => {
            const { status } = curr;

            let bucket = DEFAULT_BUCKET;

            if (status === 'fulfilled' && curr.value.result.Buckets) {
                if (acc[usages_keys[index]]['kind'] === 'data') {
                    bucket = curr.value.result.Buckets.map(val => +(val / MEGA_BASE_2).toFixed(2)).reverse();
                } else if (acc[usages_keys[index]]['kind'] === 'mins') {
                    bucket = curr.value.result.Buckets.map(val => +(val / 60).toFixed(0)).reverse();
                } else {
                    bucket = [...curr.value.result.Buckets].reverse();
                }
            }

            bucket.forEach((val, i) => {
                acc[usages_keys[index]]['history'][usage_dates[i].toDateString()] = {
                    date: usage_dates[i],
                    usage: val,
                    timestamp: usage_dates[i].getTime(),
                };
            });

            return acc
            
        }, { ...USAGES });


	    commit('SET_IS_GETTING_USAGES', false);
        commit('SAVE_USAGES', usages_with_stats);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
