import i18n from '@/i18n';

const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY

const state = {
    GoogleMaps: null,
    google_maps_initialized: null,
}

const getters = {
    GoogleMaps: state => state.GoogleMaps,
}

const mutations = {
    saveGoogleMaps(state, GoogleMaps) {
        state.GoogleMaps = GoogleMaps
        state.google_maps_initialized = null
    },
    googleMapsInitialized(state, promise) {
        state.google_maps_initialized = promise
    },
}

const actions = {
    googleMapsInit({state, commit}, config) {
        if (state.google_maps_initialized) {
            return state.google_maps_initialized
        } else {
            if (state.GoogleMaps) {
                return Promise.resolve(state.GoogleMaps)
            } else {
                let google_maps_initialized = new Promise((resolve, reject) => {
                    let script = document.createElement('script')

                        window.initMap = () => {
                            if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                                if (window.google.maps) {
                                    commit('saveGoogleMaps', window.google.maps)
                                    resolve(getters.GoogleMaps)
                                } else {
                                    reject(new Error(i18n.t('error_while_loading_google_maps')))
                                }
                            }
                        }

                        script.src = `https://maps.googleapis.com/maps/api/js?key=${ config && 'map_api_key' in config ? config.map_api_key : GOOGLE_MAPS_API_KEY }&callback=initMap&libraries=places`

                    document.getElementsByTagName('head')[0].appendChild(script)
                })

                commit('googleMapsInitialized', google_maps_initialized)

                return google_maps_initialized
            }
        }
    },
}

export default {
	state,
	getters,
	mutations,
	actions,
}